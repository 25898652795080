import axios from 'axios'
import customAxios, { autoCancelToken } from '@/axios/config'
import { downloadFile } from '@/utils/common-utils'

export default {
  namespaced: true,
  state: {
    listings: null,
    pagination: null,
  },
  mutations: {
    updateListings(state, value) {
      state.listings = value
    },
    updatePagination(state, pagiInfo) {
      state.pagination = pagiInfo
    },
  },
  actions: {
    async getChannelListings(_store, params) {
      console.log(params)
      return axios.get(`/api/listings/channel-listings`, { params })
    },
    getListings(context, data) {
      context.commit('updateLoading', true, { root: true })
      const params = {}
      params.page = data.page
      params.active = data.active
      params.search_term = data.search_term
      params.channels = data.channels
      if (data.cityFilter) {
        params.city_name = data.cityFilter
      }
      if (data.dups) {
        params.dups = data.dups
      }
      if (data.showUnits) {
        params.show_units = data.showUnits
      }
      if (data.multi) {
        params.multi = data.multi
      }
      if (data.region) {
        params.region = data.region
      }
      if (data.onlyPool) {
        params.only_pool = data.onlyPool
      }
      if (data.get_extra_fields) {
        params.get_extra_fields = true
      }
      if (data.infoDefFilter) {
        params.missing_info_def = data.infoDefFilter
      }
      if (data.asExcel) {
        params.as_excel = data.asExcel
      }
      if (data.onlySetups) {
        params.only_setups = data.onlySetups
      }

      axios
        .get('/api/listings', {
          params,
          responseType: data.asExcel ? 'blob' : 'json',
        })
        .then(response => {
          if (data.asExcel) {
            context.commit('updateLoading', false, { root: true })
            downloadFile(response.data, 'listings-table.xlsx')
          } else {
            context.commit('updateLoading', false, { root: true })
            context.commit(
              'updateListings',
              response.data.payload.data.properties
            )
            context.commit(
              'updatePagination',
              response.data.payload.data.pagi_info
            )
          }
        })
        .catch(() => {
          // TODO need to be fixed by the right error code from the server
          // router.push('/login')
          context.commit('updateLoading', false, { root: true })
        })
    },

    async getListingBudget(store, { listingId } = {}) {
      return axios.get(`/api/listings/${listingId}/budget`).catch(console.error)
    },
    async updateListingBudget(store, { listingId, budget } = {}) {
      return axios
        .post(`/api/listings/${listingId}/budget`, budget)
        .catch(console.error)
    },
    async updateListing(store, { id, payload, channelManagerId }) {
      return await customAxios.post(`/api/listings/${id}/update`, {
        ...payload,
        channel_manager_id: channelManagerId,
      })
    },
    async activateListing(store, { id, is_active }) {
      return customAxios.post(`/api/listings/${id}/activate`, { id, is_active })
    },
    async validateListing(_store, { id, payload } = {}) {
      return await customAxios.post(`/api/listings/${id}/validate`, payload)
    },
    async publishListing(store, { id, channelManagerId, accountId }) {
      return await customAxios.post(`/api/channel-managers/${id}/publish`, {
        channel_manager_id: channelManagerId,
        account_id: accountId,
      })
    },
    async duplicate(store, { id, payload } = {}) {
      return await customAxios
        .post(`/api/listings/${id}/duplicate`, payload)
        .catch(console.error)
    },
    async connectListing(
      store,
      { id, channelListing, channelManagerId, accountId }
    ) {
      return await customAxios.post(`/api/channel-managers/${id}/connect`, {
        external_listing_id: channelListing?.external_listing_id,
        channel_manager_id: channelManagerId,
        account_id: accountId,
      })
    },
    async getListingSetupExpenses(store, { listingId } = {}) {
      return axios
        .get(`/api/listings/${listingId}/setup-expenses`)
        .catch(console.error)
    },
    async getAllListingsWithBankInfo() {
      return axios.get(`/api/listings/all-with-bank-info`).catch(console.error)
    },
    async getListingRates(state, id) {
      const { data } = await axios
        .get(`/api/listings/${id}/rates`)
        .catch(console.error)
      return data
    },
    async getListingLogs(_state, params) {
      const { data } = await axios.get(`/api/listing-logs`, { params })
      return data
    },
    async getAllListingsWithMissingBankInfo(store) {
      return store
        .dispatch('getAllListingsWithBankInfo')
        .then(res =>
          res.data.filter(
            l =>
              !l.bank_account ||
              !l.bank_account.account_number ||
              !l.owner_bank_account ||
              !l.owner_bank_account.account_name ||
              !l.owner_bank_account.account_number ||
              !l.owner_bank_account.routing_number ||
              (l.referrer_percent > 0 &&
                (!l.referrer_bank_account.account_name ||
                  !l.referrer_bank_account.account_number ||
                  !l.referrer_bank_account.routing_number))
          )
        )
    },
    async getInvestmentInfo(_store, { listingId, year }) {
      return axios
        .get(`/api/listings/${listingId}/investment-info`, { params: { year } })
        .catch(console.error)
    },
    async getListingStatistics(_store, ids) {
      const { data } = await axios.get(`/api/listings/statistics`, {
        params: { listing_ids: ids },
      })
      return data
    },
    async createListing(store, payload) {
      return customAxios.post(`/api/listings`, payload)
    },
    async getChannelManagerHtml() {
      return axios.get(`/api/listings/channel-manager-html`)
    },
    async getChannelManagers() {
      return axios.get('/api/app-integrations/channel-managers')
    },
    async getCombinedListings(_store, params) {
      return axios.get(`/api/channel-managers/combined-listings`, {
        params,
        cancelToken: autoCancelToken('CombinedListings'),
      })
    },
    async getListingErrors(_store) {
      return customAxios.get(`/api/listings/errors`)
    },
    async syncFromCM(store, { channelManagerId, accountId }) {
      return customAxios.get(`/api/channel-managers/sync-listings-from-cm`, {
        params: { channel_manager_id: channelManagerId, account_id: accountId },
      })
    },
    async disconnectListing(store, { id, channelManagerId }) {
      return await customAxios.post(`/api/channel-managers/${id}/disconnect`, {
        channel_manager_id: channelManagerId,
      })
    },
    async bulkCreateListings(_store, { ids, channelManagerId }) {
      return customAxios.post(`/api/channel-managers/bulk-create-listings`, {
        channel_listing_ids: ids,
        channel_manager_id: channelManagerId,
      })
    },
    async bulkConnectListings(_store, { ids, channelManagerId }) {
      return customAxios.post(`/api/channel-managers/bulk-connect-listings`, {
        channel_listing_ids: ids,
        channel_manager_id: channelManagerId,
      })
    },
    async bulkPublishListings(_store, { ids, channelManagerId }) {
      return customAxios.post(`/api/channel-managers/bulk-publish-listings`, {
        listings_ids: ids,
        channel_manager_id: channelManagerId,
      })
    },
    async bulkUpdateListings(_store, { params, channelManagerId }) {
      return customAxios.post(`/api/channel-managers/bulk-update-listings`, {
        ...params,
        channel_manager_id: channelManagerId,
      })
    },
  },
}
