<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formData.name"
              label="Rate Plan Name"
              :rules="[v => !!v || 'Name is required']"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formData.code"
              label="Rate Plan Code"
              :rules="[v => !!v || 'Code is required']"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="formData.description"
              label="Description"
              rows="3"
              auto-grow
              clearable
              hint="Enter a description for this rate plan"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="formData.pricing_offset"
              label="Pricing Offset (%)"
              type="number"
              :rules="[
                v => v !== null || 'Offset is required',
                v =>
                  (v >= -100 && v <= 100) ||
                  'Offset must be between -100 and 100',
              ]"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="formData.meal_plan"
              :items="mealPlanOptions"
              label="Meal Plan"
              item-text="text"
              item-value="value"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              v-model="formData.is_property_specific"
              label="Property Specific"
            />
          </v-col>
          <!-- Temporarily disabled
          <v-col cols="12" sm="6">
            <v-switch v-model="formData.tax_included" label="Tax Included" />
          </v-col>
          -->

          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="formData.minimum_length_of_stay"
              label="Minimum Length of Stay"
              type="number"
              min="1"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              v-model="validFromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.valid_from_date"
                  label="Valid From"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="formData.valid_from_date"
                @input="validFromMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="validUntilMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.valid_until_date"
                  label="Valid Until"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="formData.valid_until_date"
                @input="validUntilMenu = false"
              />
            </v-menu>
          </v-col>
          <!-- Temporarily disabled
          <v-col cols="12" sm="6">
            <v-menu
              v-model="arrivalsFromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.arrivals_from_date"
                  label="Arrivals From"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="formData.arrivals_from_date"
                @input="arrivalsFromMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="arrivalsUntilMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.arrivals_until_date"
                  label="Arrivals Until"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="formData.arrivals_until_date"
                @input="arrivalsUntilMenu = false"
              />
            </v-menu>
          </v-col>
          -->
          <!-- Temporarily disabled
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="formData.min_days_before_arrival"
              label="Min Days Before Arrival"
              type="number"
              min="0"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="formData.max_days_before_arrival"
              label="Max Days Before Arrival"
              type="number"
              min="0"
            />
          </v-col>
          -->

          <v-col cols="12">
            <property-picker
              v-if="formData.is_property_specific"
              :after-change="applicableListingsChanged"
              :value="formData.listing_ids"
              label="Applicable Listings"
              :allow-multiple="true"
              :clearable="true"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="grey darken-1" text @click="closeModal"> Cancel </v-btn>
      <v-btn color="primary" text :loading="loading" @click="save">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PropertyPicker from 'components/property-picker.vue'

export default {
  name: 'RatePlanModal',
  components: {
    PropertyPicker,
  },

  props: {
    ratePlan: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      validFromMenu: false,
      validUntilMenu: false,
      // Temporarily disabled
      // arrivalsFromMenu: false,
      // arrivalsUntilMenu: false,
      mealPlanOptions: [
        { text: 'Room Only (No Meals)', value: 'default' },
        { text: 'Bed & Breakfast', value: 'breakfast' },
        { text: 'Half Board (Breakfast & Dinner)', value: 'half-board' },
        { text: 'Full Board (All Meals)', value: 'full-board' },
        { text: 'All Inclusive', value: 'all-inclusive' },
      ],
      formData: {
        name: '',
        code: '',
        description: '',
        pricing_offset: 0,
        meal_plan: 'default',
        // Temporarily disabled
        // tax_included: false,
        valid_from_date: null,
        valid_until_date: null,
        // arrivals_from_date: null,
        // arrivals_until_date: null,
        minimum_length_of_stay: 1,
        // max_days_before_arrival: null,
        // min_days_before_arrival: null,
        is_property_specific: false,
        listing_ids: [],
      },
    }
  },

  computed: {
    ...mapState('ratePlan', ['availableListings']),

    title() {
      return this.ratePlan ? 'Edit Rate Plan' : 'New Rate Plan'
    },
  },

  methods: {
    ...mapActions('ratePlan', [
      'createRatePlan',
      'updateRatePlan',
      'getAvailableListings',
    ]),

    closeModal() {
      if (this.loading) {
        return
      }
      this.$store.commit('hideModal')
    },

    applicableListingsChanged(value) {
      this.formData.listing_ids = value
    },

    async save() {
      this.loading = true
      try {
        const payload = {
          rate_plan: this.formData,
        }

        if (this.ratePlan) {
          await this.updateRatePlan({
            id: this.ratePlan.id,
            ...payload,
          })
        } else {
          await this.createRatePlan(payload)
        }
      } catch (error) {
        console.error('Error saving rate plan:', error)
      } finally {
        this.loading = false
        this.closeModal()
      }
    },
  },

  async created() {
    await this.getAvailableListings()

    if (this.ratePlan) {
      this.formData = {
        ...this.ratePlan,
        listing_ids: this.ratePlan.listings.map(l => l.id),
      }
    }
  },
}
</script>
