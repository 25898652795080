import customAxios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    defaultFactors: {},
    pricingStrategies: [],
  },
  mutations: {
    updateDefaultFactors(state, payload) {
      state.defaultFactors = { ...payload }
    },
    updatePricingStrategies(state, payload) {
      state.pricingStrategies = payload
    },
  },
  actions: {
    async getPricingStrategies({ commit }, params) {
      const { data } = await customAxios.get('api/pricing-strategies', {
        params: {
          include_listings: params?.include_listings,
          include_default: params?.include_default,
        },
      })
      if (data.default) {
        commit('updateDefaultFactors', data.default)
      }
      commit('updatePricingStrategies', data.strategies)
      return data
    },

    async createPricingStrategy({ dispatch }, payload) {
      await customAxios
        .post(`/api/pricing-strategies`, payload)
        .catch(console.error)
      dispatch('getPricingStrategies', {
        include_listings: true,
      })
    },

    async updatePricingStrategy({ dispatch }, { id, ...payload }) {
      const strategyId = id || payload?.pricing_strategy?.id
      await customAxios
        .put(`/api/pricing-strategies/${strategyId}`, payload)
        .catch(console.error)
      dispatch('getPricingStrategies', {
        include_listings: true,
      })
    },

    async deletePricingStrategy({ dispatch }, id) {
      await customAxios
        .delete(`/api/pricing-strategies/${id}`)
        .catch(console.error)
      dispatch('getPricingStrategies', {
        include_listings: true,
      })
    },

    async applyPricingStrategy(_, id) {
      await customAxios
        .post(`/api/pricing-strategies/${id}/apply_strategy`)
        .catch(console.error)
    },
  },
}
