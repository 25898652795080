import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    businessModels: [],
  },
  mutations: {
    setBusinessModels(state, businessModels) {
      state.businessModels = businessModels
    },
  },
  actions: {
    async fetchBusinessModels({ commit }) {
      const response = await axios.get('/api/business-models')
      commit('setBusinessModels', response.data)
      return response.data
    },
    async createBusinessModel({ commit }, businessModel) {
      const response = await axios.post('/api/business-models', {
        business_model: businessModel,
      })
      return response.data
    },
    async updateBusinessModel({ commit }, { id, ...business_model }) {
      const response = await axios.put(`/api/business-models/${id}`, {
        business_model,
      })
      return response.data
    },
    async deleteBusinessModel({ commit }, id) {
      await axios.delete(`/api/business-models/${id}`)
    },
    async setDefaultBusinessModel({ commit }, id) {
      const response = await axios.post(`/api/business-models/${id}/default`)
      return response.data
    },
    async resetBusinessModel({ commit }, { id, since }) {
      const response = await axios.post(`/api/business-models/${id}/reset`, {
        since,
      })
      return response.data
    },
  },
}
