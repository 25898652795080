import VueRouter from 'vue-router'
import Vue from 'vue'
import last from 'lodash/fp/last'
Vue.use(VueRouter)

// Import critical components that should be loaded immediately
import RootLayout from 'components/layouts/root-layout'
import Login from 'components/login'
import ResetPasswordPage from 'components/reset-password-page'
import Error from 'components/error'

// Remove all other component imports since we're using dynamic imports

const routes = [
  {
    path: '/storybook',
    component: () => import('components/storybook.vue'),
  },
  { path: '/', redirect: '/dashboard' },
  {
    path: '/dashboard',
    component: RootLayout,
    children: [
      {
        name: 'SystemDashboard',
        path: 'system',
        component: () => import('components/system-dashboard'),
      },
      {
        name: 'Taskim',
        path: 'taskim/:boardId?',
        component: () => import('components/taskim/taskim'),
      },
      {
        name: 'Feed',
        path: 'feeds/:feedId?',
        component: () => import('components/feed.vue'),
      },
      {
        name: 'GuestBook',
        path: 'guest-book',
        component: () => import('components/guests/guest-book.vue'),
      },
      {
        name: 'ChannelManager',
        path: 'cm/:paramsTab?',
        component: () => import('components/channel-management/cm-dash.vue'),
      },
      {
        name: 'Project',
        path: 'projects/:projectId/:paramsTab?',
        component: () => import('components/projects/project'),
      },
      {
        name: 'Expense',
        path: 'expenses/:expenseId',
        component: () => import('components/expenses/expense'),
      },
      {
        name: 'Automate',
        path: 'automate/:paramsTab?',
        component: () => import('components/automations-dash'),
      },
      {
        name: 'Estimates',
        path: 'estimates/:paramsTab?/:estimateId?',
        component: () => import('components/estimates/estimates-page'),
      },
      {
        name: 'Listings',
        path: 'listings',
        component: () => import('components/dashboard-page'),
      },
      {
        path: 'sales',
        component: () => import('components/sales-panel'),
      },
      {
        path: 'listings-search',
        component: () => import('components/listings-search'),
      },
      {
        path: 'sales-chat/:id?',
        component: () => import('components/base-chat'),
        name: 'guests-chat',
      },
      {
        path: 'investment-finder',
        component: () => import('components/investment-finder'),
      },
      {
        path: 'listings-setup/:paramsTab?',
        component: () => import('components/listing-setups'),
      },
      {
        name: 'manage-property',
        path: 'edit/:id/:paramsTab?',
        component: () => import('components/listing_management'),
      },
      {
        path: 'experience-management',
        component: () => import('components/experience-manager'),
      },
      {
        path: 'pricing/:id',
        component: () => import('components/pricing-page'),
      },
      {
        name: 'view-property',
        path: 'property/:id/:paramsTab?',
        component: () => import('components/listing/listing-page'),
      },
      {
        path: 'company-activity',
        component: () => import('components/activity'),
      },
      {
        path: 'catalog',
        component: () =>
          import(
            'components/listing-tasks-catalog/listing-task-catalog-items-table'
          ),
      },
      {
        path: 'marketing/:paramsTab?',
        component: () => import('components/marketing'),
      },
      {
        path: 'guests/:id',
        component: () => import('components/guest'),
      },
      {
        path: 'ai-config/:paramsTab?',
        component: () => import('components/ai/ai-manager.vue'),
      },
      {
        path: 'management/:paramsTab?',
        component: () => import('components/management-page'),
        name: 'setting-page',
        children: [
          {
            name: 'integration-layout',
            path: '/',
            component: () => import('components/integrations-layout.vue'),
            children: [
              {
                path: '/',
                component: () => import('components/integrations.vue'),
              },
              {
                path: 'integration/:name',
                name: 'integration-page',
                component: () =>
                  import('components/marketplace/integration-page.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'inventory/:paramsTab?',
        component: () => import('components/inventory/inventory-page'),
      },
      {
        path: '/dashboard/listing-setup/:id/:paramsTab?',
        component: () => import('components/listing-setup-page'),
      },
      {
        name: 'payables',
        path: 'payables/:paramsTab?',
        component: () => import('components/payables'),
      },
      {
        name: 'bank-feeds',
        path: 'bank-feeds/:paramsTab?',
        component: () => import('components/bank-feeds'),
      },
      {
        name: 'ledger-wallet',
        path: 'ledger-wallet/:paramsTab?',
        component: () => import('components/accounting/ledger-wallet.vue'),
      },
      {
        name: 'financials',
        path: 'financials/:paramsTab?',
        component: () => import('components/financials'),
      },
      {
        path: 'pricing-panel/:paramsTab?',
        component: () => import('components/pricings'),
      },
      {
        name: 'service-calls',
        path: 'service-calls/:paramsTab?',
        component: () => import('components/listing-tasks-dash'),
        children: [
          {
            name: 'area-report',
            path: '/',
            component: () =>
              import('components/listing-report/listing-report-layout'),
            children: [
              {
                path: '/',
                name: 'region-report',
                component: () =>
                  import('components/listing-report/region-report-page'),
              },
              {
                path: 'listing/:id',
                name: 'listing-report',
                component: () =>
                  import('components/listing-report/listing-report-single'),
              },
            ],
          },
        ],
      },
      {
        path: 'performance/:paramsTab?',
        component: () => import('components/performance'),
      },
      {
        path: 'dev-tools/:paramsTab?',
        component: () => import('components/dev-tools'),
      },
      {
        path: 'listing-task/:id',
        component: () =>
          import(
            'components/listing-tasks/single-listing-task/listing-task-layout'
          ),
        name: 'lt-page',
      },
      {
        path: 'reservations-manage',
        component: () => import('components/reservation-manager'),
      },
      {
        path: 'reservation/:id',
        component: () => import('components/reservation/reservation-page'),
      },
      {
        path: 'field-management',
        component: () => import('components/inventory/requests/requests-table'),
      },
      { path: 'error', component: Error },
      {
        path: 'user-profile/:paramsTab?',
        component: () => import('components/user/user-layout'),
      },
      {
        path: 'operations-settings',
        name: 'operations-settings',
        component: () => import('components/operations/operations-settings'),
      },
      {
        path: 'iot',
        component: () => import('components/iot/iot-dashboard'),
      },
      {
        path: 'store/:paramsTab?',
        component: () => import('components/store/store-view'),
      },
      {
        path: 'hotel-dashboard',
        component: () =>
          import('components/hotel-dashboard/hotel-dashboard-page'),
      },
      {
        path: 'multi-calendar',
        name: 'multi-calendar',
        component: () => import('components/calendar/multi-cal.vue'),
      },
      {
        path: 'multi-calendar_v1',
        name: 'multi-calendar_v1',
        component: () => import('components/calendar/multi-calendar'),
      },
      {
        path: 'multi-calendar_v2',
        name: 'multi-calendar_v2',
        component: () => import('components/calendar/multi-cal.vue'),
      },
      {
        path: 'portfolio/:paramsTab?',
        name: 'portfolio',
        component: () => import('components/portfolio/portfolio-page.vue'),
      },
      {
        path: 'guest-experience/:paramsTab?/:conversationId?',
        name: 'sales',
        component: () => import('components/crm/guest-experience'),
      },
      {
        path: 'guest-experience/:paramsTab?',
        name: 'crm',
        component: () => import('components/crm/guest-experience'),
      },
      {
        path: 'reviews-dashboard/:paramsTab?',
        name: 'review-crm',
        component: () => import('components/crm/review-crm.vue'),
      },
      {
        path: 'guest-mgmt/:paramsTab?',
        name: 'guest-exp',
        component: () => import('components/guests/guests-mgmt'),
      },
      {
        path: 'auto-tasks',
        name: 'auto-tasks',
        component: () => import('components/auto-tasks'),
      },
      {
        path: 'auto-messages',
        name: 'auto-messages',
        component: () => import('components/auto-messages'),
      },
      {
        path: 'agent-reservations',
        name: 'agent-reservations',
        component: () => import('components/agents/agent-reservations.vue'),
      },
      { path: '/', redirect: '/dashboard/listings' },
      {
        path: 'management/ledger-accounts/:id',
        name: 'ledger-transactions',
        component: () =>
          import('./components/accounting/ledger-transactions.vue'),
      },
      {
        path: 'multi-cal',
        name: 'dashboard-multi-cal',
        component: () => import('components/calendar/multi-cal.vue'),
      },
      {
        name: 'invoices',
        path: 'invoices',
        component: () => import('components/invoices/invoice-page'),
      },
    ],
  },
  { path: '/login', component: Login },
  { path: '/reset-password', component: ResetPasswordPage },
  {
    path: '/tourist-lt',
    component: () => import('components/layouts/listing-task-tracker-layout'),
    children: [
      {
        path: '/tourist-lt/:id',
        component: () =>
          import('components/listing-tasks/listing-task-tracker'),
      },
    ],
  },
  {
    path: '/task-report/:id',
    component: () => import('components/listing-tasks/listing-task-report'),
  },
  {
    path: '/management/tenant',
    component: () => import('components/create-tenant-page'),
  },
]
let pendingNavigation = null

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior({ matched: matched1 }, { matched: matched2 }) {
    if (last(matched1).path !== last(matched2).path) {
      return { x: 0, y: 0 }
    }
  },
})
router.beforeEach((to, from, next) => {
  pendingNavigation = to
  next()
})
router.afterEach((to, from, next) => {
  pendingNavigation = null
})

router.onError(error => {
  console.log(error)
  if (error.message && error.message.includes('Loading chunk')) {
    // Get the destination route that failed
    const destination = pendingNavigation

    if (destination) {
      window.location.href = destination.fullPath
    }
    // Reload the page
  }
})
