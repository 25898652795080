import axios from '@/axios/config'

// Helper to check if data is stale (older than 5 minutes)
const isDataStale = timestamp => {
  if (!timestamp) return true
  const CACHE_TIME = 5 * 60 * 1000 // 5 minutes
  return new Date() - new Date(timestamp) > CACHE_TIME
}

export default {
  namespaced: true,

  state: {
    isLoading: false,
    loadingStates: {
      reservations: false,
      operations: false,
      listings: false,
      financial: false,
      reviews: false,
      guestExperience: false,
      store: false,
      aiOverview: false,
      revenue: false,
      bankFeeds: false,
      expenses: false,
      directBooking: false,
      advancedDeposits: false,
    },
    hasError: false,
    errorMessage: null,
    reservationStats: null,
    operationsStats: null,
    listingsStats: null,
    financialStats: null,
    reviewsStats: null,
    guestExperienceStats: null,
    storeStats: null,
    aiStats: null,
    revenueStats: null,
    bankFeedsStats: null,
    expensesStats: null,
    directBookingStats: null,
    advancedDepositsStats: null,
    componentTimeRanges: {
      reservations: '7D',
      operations: '7D',
      listings: '30D',
      financial: '30D',
      reviews: '30D',
      guestExperience: '24H',
      store: '7D',
      aiOverview: '24H',
      revenue: 'THIS_MONTH',
      bankFeeds: '30D',
      expenses: 'THIS_MONTH',
      directBooking: '30D',
      advancedDeposits: 'ALL_TIME',
    },
    lastFetched: {
      reservations: null,
      operations: null,
      listings: null,
      financial: null,
      reviews: null,
      guestExperience: null,
      store: null,
      aiOverview: null,
      revenue: null,
      bankFeeds: null,
      expenses: null,
      directBooking: null,
      advancedDeposits: null,
    },
  },

  getters: {
    isLoading: state => state.isLoading,
    isComponentLoading: state => component =>
      state.loadingStates[component] || false,
    hasError: state => state.hasError,
    errorMessage: state => state.errorMessage,
    reservationStats: state => state.reservationStats,
    operationsStats: state => state.operationsStats,
    listingsStats: state => state.listingsStats,
    financialStats: state => state.financialStats,
    reviewsStats: state => state.reviewsStats,
    guestExperienceStats: state => state.guestExperienceStats,
    storeStats: state => state.storeStats,
    aiStats: state => state.aiStats,
    revenueStats: state => state.revenueStats,
    bankFeedsStats: state => state.bankFeedsStats,
    expensesStats: state => state.expensesStats,
    directBookingStats: state => state.directBookingStats,
    advancedDepositsStats: state => state.advancedDepositsStats,
    componentTimeRange: state => component =>
      state.componentTimeRanges[component],
    isDataStale: state => component => {
      return isDataStale(state.lastFetched[component])
    },
  },

  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },

    setComponentLoading(state, { component, isLoading }) {
      state.loadingStates[component] = isLoading
    },

    setError(state, { hasError, message }) {
      state.hasError = hasError
      state.errorMessage = message
    },

    setReservationStats(state, stats) {
      state.reservationStats = stats
    },

    setOperationsStats(state, stats) {
      state.operationsStats = stats
    },

    setListingsStats(state, stats) {
      state.listingsStats = stats
    },

    setFinancialStats(state, stats) {
      state.financialStats = stats
    },

    setReviewsStats(state, stats) {
      state.reviewsStats = stats
    },

    setGuestExperienceStats(state, stats) {
      state.guestExperienceStats = stats
    },

    setStoreStats(state, stats) {
      state.storeStats = stats
    },

    setAiStats(state, stats) {
      state.aiStats = stats
    },

    setRevenueStats(state, stats) {
      state.revenueStats = stats
    },

    setBankFeedsStats(state, stats) {
      state.bankFeedsStats = stats
    },

    setExpensesStats(state, stats) {
      state.expensesStats = stats
    },

    setDirectBookingStats(state, stats) {
      state.directBookingStats = stats
    },

    setAdvancedDepositsStats(state, stats) {
      state.advancedDepositsStats = stats
    },

    setComponentTimeRange(state, { component, timeRange }) {
      state.componentTimeRanges[component] = timeRange
    },

    setLastFetched(state, { component, timestamp }) {
      state.lastFetched[component] = timestamp
    },
  },

  actions: {
    async fetchDashboardStats(
      { commit, state, dispatch },
      {
        timeRange,
        statsType,
        includeListings = true,
        includeFinancial = true,
        includeReviews = true,
        forceRefresh = false,
        syncTimeRanges = false,
      } = {}
    ) {
      try {
        commit('setError', { hasError: false, message: null })

        // Set default time range if not provided
        if (!timeRange) {
          timeRange = statsType ? state.componentTimeRanges[statsType] : '7D'
        }

        // Independent time ranges - don't sync with other components
        // Time range synchronization is now disabled by default

        // Update the current component's time range
        if (statsType) {
          commit('setComponentTimeRange', { component: statsType, timeRange })
        }

        // Check if we need to refresh the data (either forced or stale)
        const shouldRefresh = component =>
          forceRefresh ||
          !state[`${component}Stats`] ||
          isDataStale(state.lastFetched[component])

        // Fetch data based on statsType or fetch all if not specified
        const fetchPromises = []

        // Update the global loading state only if no specific component is provided
        if (!statsType) {
          commit('setLoading', true)
        }

        if (!statsType || statsType === 'reservations') {
          if (shouldRefresh('reservations')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'reservations',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(
                  `/api/system_overview/reservations?time_range=${timeRange}`
                )
                .then(response => {
                  commit(
                    'setReservationStats',
                    response.data.reservation_stats || response.data
                  )
                  commit('setLastFetched', {
                    component: 'reservations',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'reservations',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (!statsType || statsType === 'operations') {
          if (shouldRefresh('operations')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'operations',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/operations?time_range=${timeRange}`)
                .then(response => {
                  commit(
                    'setOperationsStats',
                    response.data.operations_stats || response.data
                  )
                  commit('setLastFetched', {
                    component: 'operations',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'operations',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (includeListings && (!statsType || statsType === 'listings')) {
          if (shouldRefresh('listings')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'listings',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/listings?time_range=${timeRange}`)
                .then(response => {
                  commit(
                    'setListingsStats',
                    response.data.listing_stats || response.data
                  )
                  commit('setLastFetched', {
                    component: 'listings',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'listings',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (includeFinancial && (!statsType || statsType === 'financial')) {
          if (shouldRefresh('financial')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'financial',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/financial?time_range=${timeRange}`)
                .then(response => {
                  commit('setFinancialStats', response.data)
                  commit('setLastFetched', {
                    component: 'financial',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'financial',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (includeReviews && (!statsType || statsType === 'reviews')) {
          if (shouldRefresh('reviews')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'reviews',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/reviews?time_range=${timeRange}`)
                .then(response => {
                  commit('setReviewsStats', response.data)
                  commit('setLastFetched', {
                    component: 'reviews',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'reviews',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (!statsType || statsType === 'guestExperience') {
          if (shouldRefresh('guestExperience')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'guestExperience',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(
                  `/api/system_overview/guest_experience?time_range=${timeRange}`
                )
                .then(response => {
                  commit('setGuestExperienceStats', response.data)
                  commit('setLastFetched', {
                    component: 'guestExperience',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'guestExperience',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (!statsType || statsType === 'store') {
          if (shouldRefresh('store')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'store',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/store?time_range=${timeRange}`)
                .then(response => {
                  commit('setStoreStats', response.data)
                  commit('setLastFetched', {
                    component: 'store',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'store',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (!statsType || statsType === 'aiOverview') {
          if (shouldRefresh('aiOverview')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'aiOverview',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/ai_overview?time_range=${timeRange}`)
                .then(response => {
                  commit('setAiStats', response.data)
                  commit('setLastFetched', {
                    component: 'aiOverview',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'aiOverview',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (!statsType || statsType === 'revenue') {
          if (shouldRefresh('revenue')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'revenue',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get('/api/system_overview/revenue', {
                  params: { time_range: timeRange },
                })
                .then(response => {
                  commit('setRevenueStats', response.data.revenue_stats)
                  commit('setLastFetched', {
                    component: 'revenue',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'revenue',
                    isLoading: false,
                  })
                })
            )
          }
        }

        // Add bank feeds component
        if (!statsType || statsType === 'bankFeeds') {
          if (shouldRefresh('bankFeeds')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'bankFeeds',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/bank_feeds?time_range=${timeRange}`)
                .then(response => {
                  commit('setBankFeedsStats', response.data)
                  commit('setLastFetched', {
                    component: 'bankFeeds',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'bankFeeds',
                    isLoading: false,
                  })
                })
            )
          }
        }

        // Add expenses component
        if (!statsType || statsType === 'expenses') {
          if (shouldRefresh('expenses')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'expenses',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(`/api/system_overview/expenses?time_range=${timeRange}`)
                .then(response => {
                  const stats = response.data.expenses_stats
                  commit('setExpensesStats', stats)
                  commit('setLastFetched', {
                    component: 'expenses',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'expenses',
                    isLoading: false,
                  })
                })
            )
          }
        }

        // Add direct booking component
        if (!statsType || statsType === 'directBooking') {
          if (shouldRefresh('directBooking')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'directBooking',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(
                  `/api/system_overview/direct_booking?time_range=${timeRange}`
                )
                .then(response => {
                  const stats = response.data.direct_booking_stats
                  commit('setDirectBookingStats', stats)
                  commit('setLastFetched', {
                    component: 'directBooking',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'directBooking',
                    isLoading: false,
                  })
                })
            )
          }
        }

        if (!statsType || statsType === 'advancedDeposits') {
          if (shouldRefresh('advancedDeposits')) {
            // Set component-specific loading state
            commit('setComponentLoading', {
              component: 'advancedDeposits',
              isLoading: true,
            })

            fetchPromises.push(
              axios
                .get(
                  `/api/system_overview/advanced_deposits?time_range=${timeRange}`
                )
                .then(response => {
                  commit('setAdvancedDepositsStats', response.data)
                  commit('setLastFetched', {
                    component: 'advancedDeposits',
                    timestamp: new Date(),
                  })
                })
                .finally(() => {
                  // Reset component-specific loading state
                  commit('setComponentLoading', {
                    component: 'advancedDeposits',
                    isLoading: false,
                  })
                })
            )
          }
        }

        // Wait for all fetch promises to resolve
        await Promise.all(fetchPromises)

        // Reset the global loading state
        if (!statsType) {
          commit('setLoading', false)
        }

        return true
      } catch (error) {
        console.error('Error fetching dashboard stats:', error)
        commit('setError', {
          hasError: true,
          message: error.message || 'Unknown error',
        })
        commit('setLoading', false)
        return false
      }
    },

    // This method is now a no-op as we want independent time ranges
    async syncComponentTimeRanges(
      { commit, dispatch },
      { timeRange, currentComponent }
    ) {
      // Time range synchronization is now disabled
      // Each component maintains its own independent time range
      return
    },

    async fetchAllDashboardStats(
      { dispatch },
      { forceRefresh = false, timeRanges = {} } = {}
    ) {
      try {
        const dashboardTypes = [
          'reservations',
          'operations',
          'listings',
          'financial',
          'reviews',
          'guestExperience',
          'store',
          'aiOverview',
          'revenue',
          'bankFeeds',
          'expenses',
          'directBooking',
          'advancedDeposits',
        ]

        const fetchPromises = dashboardTypes.map(type => {
          return dispatch('fetchDashboardStats', {
            statsType: type,
            timeRange: timeRanges[type], // Use provided time range if available
            forceRefresh,
          })
        })

        await Promise.all(fetchPromises)
        return true
      } catch (error) {
        console.error('Error fetching all dashboard stats:', error)
        return false
      }
    },

    clearDashboardData({ commit }) {
      commit('setReservationStats', null)
      commit('setOperationsStats', null)
      commit('setListingsStats', null)
      commit('setFinancialStats', null)
      commit('setReviewsStats', null)
      commit('setGuestExperienceStats', null)
      commit('setStoreStats', null)
      commit('setAiStats', null)
      commit('setRevenueStats', null)
      commit('setBankFeedsStats', null)
      commit('setExpensesStats', null)
      commit('setDirectBookingStats', null)
      commit('setAdvancedDepositsStats', null)

      // Reset last fetched timestamps
      const components = [
        'reservations',
        'operations',
        'listings',
        'financial',
        'reviews',
        'guestExperience',
        'store',
        'aiOverview',
        'revenue',
        'bankFeeds',
        'expenses',
        'directBooking',
        'advancedDeposits',
      ]

      components.forEach(component => {
        commit('setLastFetched', { component, timestamp: null })
      })
    },
  },
}
