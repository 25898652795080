<template>
  <div id="notifications-tip" class="text-center">
    <v-menu v-model="showMenu" :close-on-click="true" offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          small
          color="white"
          class="grey--text"
          v-on="on"
          @click="showMenu = true"
        >
          <v-icon v-if="!unreadNotifications.length">mdi-bell-outline</v-icon>
          <v-badge
            v-else
            :content="unreadNotifications.length"
            overlap
            top
            color="warning"
            class="text-center"
          >
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list class="menu-list">
        <v-list-item class="menu-list-item">
          <v-list-item-content>
            <v-list-item-title class="text-h6"
              >Notifications
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="unreadNotifications.length">
            <v-btn
              color="info"
              class="text-capitalize"
              small
              text
              @click="markAllNotificationsAsRead"
              >Read all
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <div
          v-if="notifications !== null && !notifications.length"
          class="pa-10 text-center text-body-1"
        >
          You have not received any notifications
        </div>

        <virtual-scroller
          v-else
          :items="notifications"
          height="calc(100% - 60px)"
          item-height="68px"
          :has-more="hasMoreNotifications"
          :get-more="getMoreUserNotifications"
        >
          <template #item="{ item }">
            <v-list-item
              :key="item.id"
              :class="[
                'menu-list-item',
                'mx-2',
                { 'grey lighten-4': item.read_at },
              ]"
              :href="
                isMobile && item.link ? relativeLink(item.link) : item.link
              "
              :target="isMobile ? '' : '_blank'"
              @click="markNotificationAsRead(item.id)"
            >
              <v-list-item-avatar class="ml-0 mr-2">
                <user-avatar
                  v-if="item.actor_id"
                  :name="(usersMap[item.actor_id] || {}).name"
                  :image="(usersMap[item.actor_id] || {}).picture"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-1 small-text bolder"
                  >{{ item.notify_type }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption secondary--text">
                  {{ timeAgo(item.created_at) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!item.read_at">
                <v-btn
                  icon
                  @click.prevent.stop="markNotificationAsRead(item.id)"
                >
                  <v-icon color="grey">highlight_off</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </virtual-scroller>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UserAvatar from 'components/common/user-avatar'
import VirtualScroller from 'components/common/virtual-scroller'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'NotificationsTip',
  components: { VirtualScroller, UserAvatar },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  data() {
    return {
      showMenu: false,
    }
  },
  mounted() {
    if (this.currentUserId) {
      this.subscribeToNotificationsChannel(this.currentUserId)
    }
  },
  beforeDestroy() {
    this.unsubscribeFromNotificationsChannel()
  },
  computed: {
    ...mapGetters([
      'notifications',
      'notificationsPagination',
      'currentUserId',
    ]),
    ...mapGetters('users', ['usersMap']),
    unreadNotifications() {
      return this.notifications
        ? this.notifications.filter(n => !n['read_at'])
        : []
    },
    hasMoreNotifications() {
      return this.notifications.length < this.notificationsPagination.count
    },
  },
  methods: {
    ...mapActions([
      'markNotificationAsRead',
      'markAllNotificationsAsRead',
      'getMoreUserNotifications',
      'subscribeToNotificationsChannel',
      'unsubscribeFromNotificationsChannel',
    ]),
    relativeLink(link) {
      const url = new URL(link)
      return url.pathname
    },
  },
  watch: {
    currentUserId(newVal, oldVal) {
      if (newVal && !oldVal) this.subscribeToNotificationsChannel(newVal)
    },
  },
}
</script>

<style scoped>
.menu-list {
  width: min(650px, 80vw);
  height: 374px;
}

.menu-list-item {
  height: 60px;
}
</style>
