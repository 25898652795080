const menuItemsHost = [
  {
    text: 'CRM',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'property-manager',
      'coordinator',
    ],
    module: 'comm',
    abilities: ['crm-management'],
    abilityOrRole: true,
    icon: 'mdi-account-outline',
    value: '/dashboard/guest-experience',
  },
  {
    text: 'Multi calendar',
    allowed_roles: ['admin', 'property-manager', 'coordinator', 'field-pm'],
    icon: 'mdi-calendar-multiselect',
    value: '/dashboard/multi-calendar',
  },
  {
    text: 'Listings',
    icon: 'mdi-home-variant-outline',
    value: '/dashboard/listings',
    allowed_roles: [
      'admin',
      'investor',
      'property-manager',
      'coordinator',
      'field-pm',
    ],
  },
  {
    text: 'Experience',
    allowed_roles: ['admin', 'property-manager', 'coordinator', 'field-pm'],
    module: 'guest-exp',
    icon: 'mdi-emoticon-outline',
    value: '/dashboard/guest-mgmt',
  },
  {
    text: 'Field Ops',
    allowed_roles: [
      'admin',
      'communication-manager',
      'communication-agent',
      'sales-manager',
      'sales-agent',
      'cleaning-supervisor',
      'cleaning-manager',
      'property-manager',
      'coordinator',
      'hotel-reception',
      'accountant',
      'contractor',
      'cleaner',
      'field-pm',
      'pools-manager',
      'repairs-manager',
      'landscaping-manager',
    ],
    module: 'ops',
    icon: 'mdi-account-hard-hat-outline',
    value: '/dashboard/service-calls',
    demo_only: true,
  },
  {
    text: 'Automations',
    icon: '$operations',
    submenu: [
      {
        text: 'Automated Messages',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'property-manager',
          'coordinator',
        ],
        icon: 'mdi-robot',
        value: '/dashboard/auto-messages',
      },
      {
        text: 'Automated Tasks',
        allowed_roles: ['admin', 'property-manager'],
        abilities: ['auto-rules-manager'],
        icon: 'mdi-cog-outline',
        value: '/dashboard/auto-tasks',
      },
    ],
  },
  {
    text: 'Marketing',
    icon: '$marketing',
    submenu: [
      {
        text: 'Channels',
        allowed_roles: ['admin'],
        icon: 'mdi-access-point-network',
        value: '/dashboard/cm',
        module: 'channel-manager',
      },
      {
        text: 'Reviews',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'property-manager',
          'coordinator',
        ],
        module: 'comm',
        icon: 'mdi-star-outline',
        value: '/dashboard/reviews-dashboard',
      },
      {
        text: 'Pricing',
        icon: 'mdi-currency-usd',
        value: '/dashboard/pricing-panel',
        allowed_roles: ['admin'],
        abilities: ['pricing-manager'],
        module: 'pricing',
      },
    ],
  },
  {
    text: 'Configuration',
    icon: '$configuration',
    submenu: [
      {
        text: 'Settings',
        allowed_roles: ['admin', 'property-manager', 'coordinator'],
        icon: '$settings_fill',
        value: '/dashboard/management',
      },
      {
        text: 'AI',
        allowed_roles: ['admin'],
        abilities: ['ai-config'],
        module: 'ai',
        icon: '$ai',
        value: '/dashboard/ai-config',
      },
    ],
  },
]

export default menuItemsHost
