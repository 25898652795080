<template>
  <form-dialog
    title="Alter OTA Reservation"
    :disable-submit="!reservation.listing_id"
    max-width="500"
    @submit="submit"
    @open="getRates"
  >
    <template #activator="{ on }">
      <v-btn
        depressed
        icon
        :class="showBtnBorder ? 'btn-border' : ''"
        v-on="on"
      >
        <v-icon :color="color || 'info'" small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-row>
      <v-col v-if="!isInvestor" cols="12">
        <v-autocomplete
          v-model="reservation.listing_id"
          label="Listing"
          :items="listingsItems"
          dense
          hide-details
          item-text="nickname"
          item-value="id"
          outlined
          @change="getRates"
        />
        <div
          v-if="!reservation.is_editable"
          class="text-caption warning--text py-4"
        >
          {{
            `* Moving this reservation to another listing won't release those dates
          in ${reservation.source}`
          }}
        </div>
      </v-col>
      <v-col cols="12">
        <confirmation-modal
          ref="confirmDateChangeModal"
          :text="
            $t(
              'Some dates are unavailable, are you sure you want to change the reservation dates?'
            )
          "
          title="Date change confirmation"
          :actions="[
            {
              text: 'Cancel',
              color: 'error',
              onClick: setResDateRange,
            },
            {
              text: 'Confirm',
              color: 'success',
              onClick: () => {},
            },
          ]"
        />
        <date-picker
          v-model="range"
          :range="true"
          class="mt-3"
          :hide-details="true"
          :allowed-dates="allowedDates"
          :event-color="eventColors"
          label="Dates"
        />
        <v-checkbox
          v-if="reservation.source.toLowerCase() === 'manual'"
          v-model="reCalc"
          :disabled="!reservation.is_editable"
          dense
          class="mt-0"
          label="Recalculate price(including taxes & fees)"
        />
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="status"
          outlined
          dense
          :disabled="!reservation.is_editable"
          :items="['confirmed', 'inquiry', 'canceled']"
          :label="$t('Status')"
        />
        <div v-if="status === 'canceled'" class="mt-2">
          <v-switch
            v-model="useManualCanceledIncome"
            label="Override automatic cancellation policy"
            persistent-hint
            dense
            class="mb-2"
          />
          <v-text-field
            v-model="canceledIncome"
            type="number"
            min="0"
            step="0.01"
            label="Amount Received"
            hint="This overrides the automatic cancellation policy calculation"
            persistent-hint
            outlined
            dense
            prefix="$"
            :disabled="!useManualCanceledIncome"
          />
          <v-radio-group
            v-if="reservation.source === 'HomeAway'"
            v-model="canceledBy"
            row
            dense
            class="mt-0"
            label="Canceled by"
          >
            <v-radio value="host" label="Host" />
            <v-radio value="guest" label="Guest" />
          </v-radio-group>
        </div>
        <div v-if="!reservation.is_editable" class="container text-caption">
          <div class="font-weight-medium">* Cancellation note</div>
          <div>
            Currently, our system does not support direct cancellation of
            reservations originating from
            <b>Airbnb</b> and <b>Booking.com</b>.
          </div>
          <expander>
            <template #title>
              <div
                class="info--text text-decoration-underline font-weight-medium pointer"
              >
                Alternative Options for cancelling/altering reservations
              </div>
            </template>
            <div>
              <ol>
                <li>
                  <strong>Changing Reservation Dates:</strong>
                  <ul>
                    <li>
                      <b>How:</b> Modify the existing reservation dates to your
                      preferred timeframe.
                    </li>
                    <li>
                      <b>Effect:</b> This adjustment opens up the original
                      reservation dates on all channels except the one the
                      reservation was made through.
                    </li>
                  </ul>
                </li>
              </ol>
              <div class="mt-3 font-weight-medium">
                In cases of mid-stay checkouts or any other cancellation
                requests, the best option is to:
              </div>
              <ul>
                <li>
                  <b>Shorten the Reservation:</b> Adjust the reservation end
                  date to today's date.
                </li>
                <li>
                  <b>Convert to a One-Night Reservation:</b> Modify the
                  reservation to be only for one night.
                </li>
              </ul>
            </div>
          </expander>
        </div>
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import DatePicker from 'components/form-fields/date-picker'
import CommonFunctions from 'components/mixins/common_functions'
import { mapGetters } from 'vuex'
import equal from 'lodash/fp/isEqual'
import isEmpty from 'lodash/fp/isEmpty'
import isNumber from 'lodash/fp/isNumber'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Expander from 'components/common/expander.vue'

export default {
  name: 'ReservationEditModal',
  components: { Expander, DatePicker, FormDialog, ConfirmationModal },
  mixins: [CommonFunctions, FormattersMixin, PermissionsMixin],
  props: ['listing', 'reservation', 'color', 'showBtnBorder'],
  data() {
    return {
      reCalc: false,
      currentRates: {},
      canceledIncome: this.reservation.canceled_income || 0,
      useManualCanceledIncome: isNumber(this.reservation.canceled_income),
      canceledBy: 'host',
      status: this.reservation.status,
      range: [
        this.parseDate(this.reservation.check_in),
        this.parseDate(this.reservation.check_out),
      ],
    }
  },
  async mounted() {
    this.currentRates = await this.$store.dispatch(
      'listings/getListingRates',
      this.reservation.listing_id
    )
  },
  watch: {
    isListingChanged: function () {
      if (this.isBlocked(this.range)) {
        this.$refs.confirmDateChangeModal.open()
      }
    },
    range: function (newRange, oldRang) {
      const oldStrRange = oldRang.join()
      const newStrRange = newRange.join()
      if (
        newStrRange !== oldStrRange &&
        newStrRange !== this.initialResRange.join()
      ) {
        if (this.isBlocked(newRange)) {
          this.$refs.confirmDateChangeModal.open()
        }
      }
    },
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    isOwnerVacation() {
      return this.reservation.source === 'Owner'
    },
    listingsItems() {
      if (this.listing.is_multi_unit) {
        return this.listingsPicker.filter(
          l =>
            l.is_multi_unit ||
            !l.container_multi_id ||
            l.container_multi_id === this.listing.id
        )
      }
      return this.listingsPicker
    },
    initialResRange() {
      return [
        this.parseDate(this.reservation.check_in),
        this.parseDate(this.reservation.check_out),
      ]
    },
    isListingChanged() {
      return this.reservation.listing_id !== this.listing.id
    },
  },
  methods: {
    async getRates(lId) {
      if (!this.reservation.listing_id) return
      this.currentRates = await this.$store.dispatch(
        'listings/getListingRates',
        this.reservation.listing_id
      )
      if (
        lId &&
        lId !== this.reservation.listing_id &&
        this.isBlocked(this.range)
      ) {
        this.$refs.confirmDateChangeModal.open()
      }
    },
    allowedDates(date) {
      if (
        this.reservation.source === 'manual' &&
        this.$moment(date).isBefore(this.$moment())
      ) {
        return true
      }
      if (isEmpty(this.currentRates)) {
        return true
      }
      const dayBefore = this.$moment(date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      return (
        (this.currentRates[date] &&
          this.currentRates[date].status === 'available') ||
        this.$moment(date).isBetween(
          this.parseDate(this.reservation.check_in),
          this.parseDate(this.reservation.check_out),
          undefined,
          '[]'
        ) ||
        (this.currentRates[dayBefore] &&
          this.currentRates[date] &&
          this.currentRates[date].status !== 'available' &&
          this.currentRates[dayBefore].status === 'available' &&
          this.reservation.listing_id === this.listing.id)
      )
    },
    eventColors(date) {
      if (
        this.currentRates[date] &&
        this.currentRates[date].status === 'available'
      ) {
        return 'green lighten-1'
      }
      return ''
    },
    async submit() {
      const payload = {}
      payload.listing_id = this.reservation.listing_id
      if (
        !equal(this.range, [
          this.parseDate(this.reservation.check_in),
          this.parseDate(this.reservation.check_out),
        ])
      ) {
        payload.check_in = this.range[0]
        payload.check_out = this.range[1]
      }
      if (this.reservation.status !== this.status) {
        payload.status = this.status
      }

      payload.recalculate_price = this.reCalc

      if (this.status === 'canceled') {
        if (this.useManualCanceledIncome) {
          payload.canceled_income = parseFloat(this.canceledIncome) || 0
        }

        if (this.reservation.source === 'HomeAway') {
          payload.canceled_by = this.canceledBy
        }
      }

      this.$store.commit('updateLoading', true)
      const response = await this.$store.dispatch(
        'reservation/alterReservation',
        {
          id: this.reservation.id,
          ...payload,
        }
      )
      this.$store.commit('updateLoading', false)
      if (response) {
        this.$emit('change')
      }
    },
    isBlocked(range) {
      const dateRange = this.generateDateRangeArray(range[0], range[1])
      const existingRange = this.generateDateRangeArray(
        this.initialResRange[0],
        this.initialResRange[1]
      )
      const isAnyDateUnavailable = dateRange.some(
        date =>
          this.currentRates[date].status !== 'available' &&
          (this.isListingChanged || !existingRange.includes(date))
      )
      return isAnyDateUnavailable
    },
    setResDateRange() {
      this.range = [
        this.parseDate(this.reservation.check_in),
        this.parseDate(this.reservation.check_out),
      ]
    },
  },
}
</script>

<style scoped>
.btn-border {
  border: solid 1px #dfe1e6;
}
</style>
