<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="fullscreen"
    :max-width="maxWidth"
    persistent
  >
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-title class="justify-space-between">
        <div>{{ title }}</div>
        <v-icon @click="closeDialog">close</v-icon>
      </v-card-title>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <slot />
        </v-card-text>
        <v-card-actions class="justify-end">
          <slot name="actions" />
          <v-btn
            v-if="!$slots.actions"
            color="primary"
            outlined
            @click="closeDialog"
            >Cancel
          </v-btn>
          <v-btn
            v-if="!$slots.actions"
            color="primary"
            type="submit"
            :disabled="disableSubmit"
            elevation="0"
            >{{ submitText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    resetOnClose: {
      type: Boolean,
      default: true,
    },
    hardReset: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      default: 400,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Save',
    },
  },
  data() {
    return {
      dialog: false,
      inputsValues: {},
    }
  },
  mounted() {
    this.$emit('update:form', this.$refs.form)
  },
  watch: {
    value(value) {
      this.dialog = value
    },
    dialog(value) {
      this.$emit('input', value)
      if (value) {
        this.$emit('open')
        this.$nextTick(() => this.saveCurrentValues())
      } else {
        this.$emit('close')
        if (this.resetOnClose) {
          this.$nextTick(() => this.resetToSavedValues())
        }
      }
      this.$nextTick(() => this.$refs.form.resetValidation())
    },
  },
  methods: {
    closeDialog() {
      if (this.hardReset) {
        this.$refs.form.reset()
      }
      this.$emit('close')
      this.dialog = false
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
        this.closeDialog()
      }
    },
    saveCurrentValues() {
      this.$refs.form.inputs.forEach(input => {
        this.inputsValues[input._uid] = input.value
      })
    },
    resetToSavedValues() {
      if (this.hardReset) {
        this.$refs.form.reset()
      } else {
        this.$refs.form.inputs.forEach(input => {
          if (input._uid in this.inputsValues) {
            input.$emit('input', this.inputsValues[input._uid])
          }
        })
      }
    },
  },
}
</script>

<style scoped></style>
