<template>
  <v-dialog
    v-model="localShow"
    max-width="1000"
    content-class="ai-thought-dialog"
    persistent
    @click:outside.stop
  >
    <v-card class="thought-card">
      <v-card-title
        class="text-h6 white--text purple"
        style="position: relative"
      >
        <v-icon class="mr-6" color="white">mdi-brain</v-icon>
        AI Chain of Thought
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="closeDialog">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-4">
        <div class="thought-steps">
          <div
            v-for="(step, index) in formattedSteps"
            :key="index"
            class="thought-step"
          >
            <div class="step-badge">{{ index + 1 }}</div>
            <div class="step-content">
              <div class="step-title">{{ step.title }}</div>
              <div class="step-description">{{ step.description }}</div>
            </div>
          </div>
        </div>
        <div class="text-center grey--text text--darken-1 mt-4 text-caption">
          Generated visualization of AI reasoning process
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AiChainOfThoughtDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    thoughtData: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    localShow: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
    formattedSteps() {
      if (!this.thoughtData) return []

      const stepsData = this.thoughtData.steps_output
      if (!Array.isArray(stepsData) || stepsData.length === 0) return []

      if (typeof stepsData[0] === 'string') {
        return stepsData.map(step => {
          const split = step.split('-')
          return {
            title: split[0].trim(),
            description: split[1]?.trim() || '',
          }
        })
      }

      const steps = stepsData.map(step => {
        const entry = Object.entries(step)[0]
        return {
          title: entry[0],
          description: entry[1],
        }
      })

      return steps
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.ai-thought-dialog {
  z-index: 999 !important;
}

.thought-card {
  border-radius: 8px;
  overflow: hidden;
}

.thought-steps {
  max-height: 70vh;
  width: 100%;
  overflow-y: auto;
  padding: 8px;
}

.thought-step {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
}

.step-badge {
  background-color: #673ab7;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 16px;
  flex-shrink: 0;
}

.step-content {
  border-left: 2px solid #e0e0e0;
  padding-left: 16px;
  flex-grow: 1;
}

.step-title {
  font-weight: bold;
  color: #673ab7;
  margin-bottom: 8px;
}

.step-description {
  color: #616161;
  font-size: 14px;
  line-height: 1.5;
}
</style>
