<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        {{ strategy ? 'Edit Pricing Strategy' : 'New Pricing Strategy' }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="strategyData.name"
                  label="Strategy Name"
                  :rules="[v => !!v || 'Name is required']"
                  required
                  outlined
                  dense
                />
              </v-col>

              <v-col cols="12">
                <property-picker
                  :not-top-header="true"
                  :after-change="assignedListingsChanged"
                  :value="assignedListings"
                  label="Assigned listings"
                  :allow-multiple="true"
                  :clearable="true"
                  :hide-listing-pics="true"
                />
              </v-col>

              <v-col cols="12">
                <v-layout v-show="false" wrap class="mt-2 mx-1">
                  <v-flex class="d-flex my-2 font-weight-medium" xs12>
                    <div>Month seasonality</div>
                    <br />
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.January"
                      label="Jan"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.February"
                      label="Feb"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.March"
                      label="Mar"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.April"
                      label="Apr"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.May"
                      label="May"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.June"
                      label="Jun"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.July"
                      label="Jul"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.August"
                      label="Aug"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.September"
                      label="Sep"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.October"
                      label="Oct"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.November"
                      label="Nov"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.seasonality.December"
                      label="Dec"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap mt-2 mx-1>
                  <v-flex class="d-flex my-2 font-weight-medium" xs12>
                    <div>Day of the week</div>
                    <br />
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Sunday"
                      label="Sun"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Monday"
                      label="Mon"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Tuesday"
                      label="Tue"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Wednesday"
                      label="Wed"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Thursday"
                      label="Thu"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Friday"
                      label="Fri"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-text-field
                      v-model="strategyData.factors.day_of_week.Saturday"
                      label="Sat"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap mx-1>
                  <v-flex class="d-flex my-2 font-weight-medium" xs12>
                    <div>
                      Month Occupancy (take effect on the next 10-240 days)
                    </div>
                    <br />
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.month_occupancy['0-0.2']"
                      label="0%-20%"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.month_occupancy['0.2-0.4']"
                      label="20%-40%"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.month_occupancy['0.4-0.6']"
                      label="40%-60%"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.month_occupancy['0.6-0.8']"
                      label="60%-80%"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.month_occupancy['0.8-1']"
                      label="80%-100%"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap mx-1>
                  <v-flex class="d-flex my-2 font-weight-medium" xs12>
                    <div>Time until booking</div>
                    <br />
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['0-2']"
                      label="Next 3 days"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['2-6']"
                      label="3-7 days"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['6-13']"
                      label="7-14 days"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['13-20']"
                      label="14-21 days"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['20-30']"
                      label="21-30 days"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['30-60']"
                      label="1-2 months"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="strategyData.factors.time_until_booking['60-90']"
                      label="2-3 months"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="
                        strategyData.factors.time_until_booking['90-150']
                      "
                      label="3-5 months"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="
                        strategyData.factors.time_until_booking['250-1500']
                      "
                      label="8 months ahead"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
            <v-layout wrap class="mt-1 mb-2 align-center">
              <v-flex class="d-flex" xs12 mx-1>
                <v-switch
                  v-model="strategyData.factors.enable_group_pricing"
                  :class="
                    strategyData.factors.enable_group_pricing
                      ? 'cyan-icon xs3'
                      : 'grey-text xs3'
                  "
                  :label="
                    'Group pricing is: ' +
                    (strategyData.factors.enable_group_pricing ? 'ON' : 'OFF')
                  "
                >
                </v-switch>
                <span class="d-flex inline-flex ms-6">
                  <v-switch
                    v-model="strategyData.factors.scramble_prices"
                    label="Auto Scramble prices"
                    class="me-2"
                  >
                  </v-switch>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon small v-on="on">info</v-icon>
                    </template>
                    <div>
                      Will automatically increase/decrease by 1% daily change
                    </div>
                  </v-tooltip>
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap mx-1>
              <v-flex xs12>
                <v-switch
                  v-model="strategyData.factors.min_days_enable"
                  :class="
                    strategyData.factors.min_days_enable
                      ? 'cyan-icon xs3'
                      : 'grey-text xs3'
                  "
                  :label="
                    'Pricing control over min nights: ' +
                    (strategyData.factors.min_days_enable ? 'ON' : 'OFF')
                  "
                >
                </v-switch>
                <div v-if="strategyData.factors.min_days_enable">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="strategyData.factors.min_nights_weekend"
                        label="Weekend min nights"
                        type="number"
                        outlined
                        dense
                        :rules="[max(365), min(1)]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="strategyData.factors.min_nights_weekday"
                        label="Weekday min nights"
                        type="number"
                        outlined
                        dense
                        :rules="[max(365), min(1)]"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5" class="d-flex align-center me-4">
                      <v-switch
                        v-model="strategyData.factors.dynamic_nights_1_night"
                        label="Enable 1 nights gap reservation fill"
                      >
                      </v-switch>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon small v-on="on">info</v-icon>
                        </template>
                        <div>
                          Will change min nights to 1 night in case only 1 night
                          is available
                        </div>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="5" class="d-flex align-center">
                      <v-switch
                        v-model="strategyData.factors.dynamic_nights_2_night"
                        label="Enable 2 nights gap reservation fill"
                      >
                      </v-switch>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon small v-on="on">info</v-icon>
                        </template>
                        <div>
                          Will change min nights to 2 nights in case only 1
                          night is available
                        </div>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="closeModal">Cancel</v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="handleSubmit"
        >
          {{ strategy ? 'Update' : 'Create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash/fp'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import PropertyPicker from 'components/property-picker'

export default {
  name: 'PricingStrategyModal',
  components: { PropertyPicker },
  mixins: [FormRulesMixin],

  props: {
    strategy: {
      type: Object,
      default: null,
    },
    defaultFactors: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      assignedListings: [],
      strategyData: {
        name: '',
        active: true,
        factors: {
          ...cloneDeep(this.defaultFactors),
          base_price: 0,
          min_price_weekend: 0,
          min_price_weekday: 0,
          max_price: 0,
          enable_multi_unit_pricing: false, // keep for consistency
          enable_group_pricing: false,
          scramble_prices: false,
          min_days_enable: false,
          min_nights_weekend: 1,
          min_nights_weekday: 1,
          dynamic_nights_1_night: false,
          dynamic_nights_2_night: false,
          enable_emergency_mode: false, // keep for consistency
          enable_trapped_between_weekends: false, // keep for consistency
        },
      },
    }
  },

  computed: {},

  watch: {
    strategy: {
      immediate: true,
      handler(strategy) {
        if (strategy) {
          this.strategyData = {
            ...this.strategyData,
            ...cloneDeep(strategy.factors),
            name: strategy.name,
            active: strategy.active,
          }
          this.valid = true
          this.assignedListings = [...strategy.listings]
        }
      },
    },
  },

  methods: {
    ...mapActions('pricingStrategy', [
      'createPricingStrategy',
      'updatePricingStrategy',
    ]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        const payload = {
          ...this.strategyData,
          listing_ids: this.assignedListings,
        }

        if (this.strategy) {
          await this.updatePricingStrategy({
            id: this.strategy.id,
            ...payload,
          })
        } else {
          await this.createPricingStrategy(payload)
        }
        this.$emit('saved')
      } catch (error) {
        console.error('Failed to save pricing strategy:', error)
      } finally {
        this.loading = false
        this.closeModal()
      }
    },

    closeModal() {
      if (this.loading) {
        return
      }

      this.$refs.form.reset()
      this.$store.commit('hideModal')
    },

    assignedListingsChanged(value) {
      this.assignedListings = value
    },
  },
}
</script>
