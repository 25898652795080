<template>
  <v-sheet :width="scale">
    <v-card
      elevation="0"
      :width="scale"
      :height="scale"
      class="rounded fill-height align-center justify-center d-flex upload-box"
      @mouseover="hoverPic"
      @mouseout="outPic"
      @click="pickFile"
    >
      <v-overlay v-show="picHovered" absolute
        ><v-icon>upload</v-icon></v-overlay
      >
      <v-overlay v-show="!picHovered && !value" :opacity="0" absolute>
        <v-icon color="secondary">upload</v-icon>
      </v-overlay>
      <img
        v-if="value"
        width="100%"
        height="100%"
        class="rounded"
        :src="value"
      />
      <v-icon v-else color="secondary" size="36">mdi-image</v-icon>
      <v-file-input
        ref="file"
        v-model="files"
        type="file"
        style="display: none"
        accept="image/png,image/jpeg,image/jpg,image/webp"
        @change="readURL"
      />
    </v-card>
    <v-progress-linear
      v-if="filesLoading"
      v-model="imageProgress"
      class="my-2 rounded"
      width="100"
      height="20"
    >
      <template #default="{ value: v }">
        <strong>{{ Math.ceil(v) }}%</strong>
      </template>
    </v-progress-linear>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import { uploadToCloudinary } from '@/utils/uploadToCloudinary'

export default {
  name: 'ImageField',
  props: {
    scale: {
      type: Number,
      default: 100,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],
      picHovered: false,
      filesLoading: false,
      imageProgress: 0,
    }
  },
  mounted() {
    console.log('ImageField mounted, value:', this.value)
  },
  methods: {
    hoverPic() {
      this.picHovered = true
    },
    outPic() {
      this.picHovered = false
    },
    readURL() {
      if (!this.files || !this.files.size) {
        this.$emit('on-change', null)
        return
      }

      this.filesLoading = true

      // Use the shared uploadToCloudinary utility instead of direct implementation
      uploadToCloudinary(
        [this.files],
        {
          onUploadingChange: value => {
            this.filesLoading = value
          },
          onUploadProgress: value => {
            this.imageProgress = value
          },
        },
        { folder: 'users' }
      )
        .then(urls => {
          const url = urls[0]
          this.$emit('update:value', url)
          this.$emit('on-change', url)
          console.log('Upload success:', url)
        })
        .catch(err => {
          console.error('Image upload error:', err)
          this.$emit('on-change', null)
        })
        .finally(() => {
          this.files = []
        })
    },
    pickFile() {
      if (this.filesLoading) return
      this.$refs.file.$refs.input.click()
    },
  },
}
</script>

<style scoped>
.upload-box {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: #f5f5f5;
}
</style>
