<template>
  <v-dialog v-model="dialog" persistent max-width="660px" @close="closeModal">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card v-if="dialog">
      <v-form ref="form" @submit="submit">
        <v-card-title> Create new sub-task</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="description"
            label="Description"
            :rules="[required]"
            outlined
            rows="4"
          />
          <v-checkbox
            v-model="inspectionMode"
            dense
            class="checkbox-txt mt-0"
            label="Inspection Mode"
          />
          <div class="text-body-2 font-weight-medium pb-2">Before Media</div>
          <DvrMediaUpload
            :uploading.sync="isUploading"
            :value.sync="beforeImages"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel
          </v-btn>
          <v-btn small color="primary" :disabled="isUploading" type="submit"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import DvrMediaUpload from 'components/common/dvr-media-upload'

export default {
  name: 'LtSubtaskCreateModal',
  components: { DvrMediaUpload },
  mixins: [formRules],
  props: ['listingTaskId'],
  data() {
    return {
      dialog: false,
      isUploading: false,
      description: '',
      beforeImages: [],
      inspectionMode: false,
    }
  },
  methods: {
    async submit(e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        const { description, listingTaskId } = this
        await this.$store.dispatch('createListingSubTask', {
          description,
          listing_task_id: listingTaskId,
          before_images: this.beforeImages,
          inspection_mode: this.inspectionMode,
        })
        this.$emit('created')
        this.closeModal()
      }
    },
    closeModal() {
      this.$refs.form.reset()
      this.dialog = false
    },
  },
}
</script>

<style scoped></style>
