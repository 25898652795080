<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn color="info" outlined v-bind="attrs" :disabled="disabled" v-on="on"
        ><v-icon left>fas fa-ellipsis-v</v-icon> Actions</v-btn
      >
    </template>
    <v-list>
      <v-list-item @click="updateExpenses({ hidden: true })">
        <v-list-item-title>Exclude from finance</v-list-item-title>
      </v-list-item>
      <v-list-item @click="updateExpenses({ hidden: false })">
        <v-list-item-title>Include in finance</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="
          updateExpenses({ related_to_setup: true, related_cost_base: false })
        "
      >
        <v-list-item-title>Mark as setup action</v-list-item-title>
      </v-list-item>
      <v-list-item @click="updateExpenses({ related_to_setup: false })">
        <v-list-item-title> Unmark as setup action </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="
          updateExpenses({ related_cost_base: true, related_to_setup: false })
        "
      >
        <v-list-item-title>Mark as cost base</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="
          updateExpenses({
            manual_dismiss: true,
            manual_dismiss_reason: `marked as dismissed by ${currentUser.first_name}`,
          })
        "
      >
        <v-list-item-title>Manual Dismiss All</v-list-item-title>
      </v-list-item>
      <v-list-item @click="updateExpenses({ related_cost_base: false })">
        <v-list-item-title>Unmark as cost base</v-list-item-title>
      </v-list-item>
      <v-list-item @click="updateExpenses({ action: 'download_attachments' })">
        <v-list-item-title>Download attachments</v-list-item-title>
      </v-list-item>
      <expenses-edit-modal :is-setup="isSetup" @submit="updateExpenses" />
    </v-list>
  </v-menu>
</template>

<script>
import ExpensesEditModal from 'components/expenses/expenses-edit-modal'
import { mapGetters } from 'vuex'
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'ExpenseActions',
  components: { ExpensesEditModal },
  mixins: [CommonFunctions],
  computed: {
    ...mapGetters(['currentUser']),
    expenseIds() {
      return Array.isArray(this.expenseId) ? this.expenseId : [this.expenseId]
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isSetup: {
      type: Boolean,
      default: undefined,
    },
  },
  methods: {
    async updateExpenses(data) {
      await this.$emit('action', data)
    },
  },
}
</script>

<style scoped></style>
