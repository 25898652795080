export const GOOGLE_MAP_API_KEY = 'AIzaSyCzvYaJgsS0jhNhEjzREy_G9Quie3zc7A4'

export const DISABLED_STORE_ITEM_STATUSES = [
  'not_available',
  'in_bag',
  'purchased',
  'future',
]

export const PROJECT_STATUSES = [
  { key: 'ACTIVE', text: 'Active' },
  { key: 'DONE', text: 'Done' },
]

export const MAX_SC_TASKS_RESULT = 600

export const TASK_STATUSES = {
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  REJECTED: 'Rejected',
  OWNER_APPROVAL: 'Owner Approval',
  OWNER_APPROVED: 'Owner Approved',
  PENDING_APPROVAL: 'Pending Approval',
  INVESTIGATION: 'Investigation',
  DONE: 'Done',
  CANT_DO: "Can't do",
  CLOSED: 'Closed',
  PAUSED: 'Paused',
  IN_REVIEW: 'In Review',
}

export const formulaVariables = [
  {
    value: 'fare_accommodation',
    category: 'Financial',
    description: 'The nightly rate applied to the reservation',
  },
  {
    value: 'cleaning_fee',
    category: 'Financial',
    description: 'The cleaning charge collected from the guest',
  },
  {
    value: 'reported_channel_fee',
    category: 'Financial',
    description: 'The channel fee recorded after reservation completion',
  },
  {
    value: 'host_channel_fee',
    category: 'Financial',
    description: 'Fee charged by the booking platform',
  },
  {
    value: 'processing_fee',
    category: 'Financial',
    description: 'Transaction processing costs for MOR reservations',
  },
  {
    value: 'host_payout',
    category: 'Financial',
    description: 'Total amount received from the booking channel',
  },
  {
    value: 'price',
    category: 'Financial',
    description: 'Total amount received from the booking channel',
  },
  {
    value: 'total_payout',
    category: 'Financial',
    description: 'Total amount paid out for the reservation',
  },
  {
    value: 'client_price',
    category: 'Financial',
    description: 'Amount paid by guest including fees',
  },
  {
    value: 'net_income',
    category: 'Financial',
    description: 'Earnings after deducting fees and expenses',
  },
  {
    value: 'total_taxes',
    category: 'Tax',
    description: 'Total taxes reported on the reservation',
  },
  {
    value: 'commission_percent',
    category: 'Commission',
    description: 'Defined commission percentage',
  },
  {
    value: 'adr',
    category: 'Financial',
    description: 'Average daily rate',
  },
  {
    value: 'company_fees',
    category: 'Management',
    description: 'OTA fees that belong to PMC',
  },
  {
    value: 'management_commission',
    category: 'Management',
    description: 'Total fee charged by PMC on the reservation',
  },
  {
    value: 'guests_count',
    category: 'Financial',
    description: 'Number of guests on the reservation',
  },
  {
    value: 'nights_count',
    category: 'Financial',
    description: 'Number of nights on the reservation',
  },
]

export const TASK_SUB_STATUSES = {
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING_PAYMENT: 'Pending payment',
}

export const MANAGMENT_TABLE_TYPES = {
  LINK: 'LINK',
  CHECK: 'CHECK',
  ACTION: 'ACTION',
  COMPUTED: 'COMPUTED',
  IMAGE: 'IMAGE',
  CONFIRMED_ACTION: 'CONFIRMED_ACTION',
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const INTERNAL_RESERVATION_SOURCES = [
  'Reception',
  'grand',
  'phone',
  'TheWorthington',
  'TheGrand',
  'manual',
  'Manual',
  'Designedvr.com',
]

export const LISTING_TYPES = {
  HOTEL: 'hotel',
}

export const PRIORITIES_ORDER = {
  critical: 0,
  high: 1,
  med: 2,
  low: 3,
  none: 4,
}

export const STRIPE_WEBHOOKS = [
  'account.updated',
  'account.external_account.created',
  'account.external_account.deleted',
  'account.external_account.updated',
  'balance.available',
  'billing_portal.configuration.created',
  'billing_portal.configuration.updated',
  'billing_portal.session.created',
  'capability.updated',
  'cash_balance.funds_available',
  'charge.captured',
  'charge.expired',
  'charge.failed',
  'charge.pending',
  'charge.refunded',
  'charge.succeeded',
  'charge.updated',
  'charge.dispute.closed',
  'charge.dispute.created',
  'charge.dispute.funds_reinstated',
  'charge.dispute.funds_withdrawn',
  'charge.dispute.updated',
  'charge.refund.updated',
  'checkout.session.async_payment_failed',
  'checkout.session.async_payment_succeeded',
  'checkout.session.completed',
  'checkout.session.expired',
  'coupon.created',
  'coupon.deleted',
  'coupon.updated',
  'credit_note.created',
  'credit_note.updated',
  'credit_note.voided',
  'customer.created',
  'customer.deleted',
  'customer.updated',
  'customer.discount.created',
  'customer.discount.deleted',
  'customer.discount.updated',
  'customer.source.created',
  'customer.source.deleted',
  'customer.source.expiring',
  'customer.source.updated',
  'customer.subscription.created',
  'customer.subscription.deleted',
  'customer.subscription.pending_update_applied',
  'customer.subscription.pending_update_expired',
  'customer.subscription.trial_will_end',
  'customer.subscription.updated',
  'customer.tax_id.created',
  'customer.tax_id.deleted',
  'customer.tax_id.updated',
  'customer_cash_balance_transaction.created',
  'file.created',
  'financial_connections.account.created',
  'financial_connections.account.deactivated',
  'financial_connections.account.disconnected',
  'financial_connections.account.reactivated',
  'financial_connections.account.refreshed_balance',
  'identity.verification_session.canceled',
  'identity.verification_session.created',
  'identity.verification_session.processing',
  'identity.verification_session.redacted',
  'identity.verification_session.requires_input',
  'identity.verification_session.verified',
  'invoice.created',
  'invoice.deleted',
  'invoice.finalization_failed',
  'invoice.finalized',
  'invoice.marked_uncollectible',
  'invoice.paid',
  'invoice.payment_action_required',
  'invoice.payment_failed',
  'invoice.payment_succeeded',
  'invoice.sent',
  'invoice.upcoming',
  'invoice.updated',
  'invoice.voided',
  'invoiceitem.created',
  'invoiceitem.deleted',
  'invoiceitem.updated',
  'issuing_authorization.created',
  'issuing_authorization.request',
  'issuing_authorization.updated',
  'issuing_card.created',
  'issuing_card.updated',
  'issuing_cardholder.created',
  'issuing_cardholder.updated',
  'issuing_dispute.closed',
  'issuing_dispute.created',
  'issuing_dispute.funds_reinstated',
  'issuing_dispute.submitted',
  'issuing_dispute.updated',
  'issuing_transaction.created',
  'issuing_transaction.updated',
  'mandate.updated',
  'order.created',
  'payment_intent.amount_capturable_updated',
  'payment_intent.canceled',
  'payment_intent.created',
  'payment_intent.partially_funded',
  'payment_intent.payment_failed',
  'payment_intent.processing',
  'payment_intent.requires_action',
  'payment_intent.succeeded',
  'payment_link.created',
  'payment_link.updated',
  'payment_method.attached',
  'payment_method.automatically_updated',
  'payment_method.detached',
  'payment_method.updated',
  'payout.canceled',
  'payout.created',
  'payout.failed',
  'payout.paid',
  'payout.updated',
  'person.created',
  'person.deleted',
  'person.updated',
  'plan.created',
  'plan.deleted',
  'plan.updated',
  'price.created',
  'price.deleted',
  'price.updated',
  'product.created',
  'product.deleted',
  'product.updated',
  'promotion_code.created',
  'promotion_code.updated',
  'quote.accepted',
  'quote.canceled',
  'quote.created',
  'quote.finalized',
  'radar.early_fraud_warning.created',
  'radar.early_fraud_warning.updated',
  'recipient.created',
  'recipient.deleted',
  'recipient.updated',
  'reporting.report_run.failed',
  'reporting.report_run.succeeded',
  'reporting.report_type.updated',
  'review.closed',
  'review.opened',
  'setup_intent.canceled',
  'setup_intent.created',
  'setup_intent.requires_action',
  'setup_intent.setup_failed',
  'setup_intent.succeeded',
  'sigma.scheduled_query_run.created',
  'sku.created',
  'sku.deleted',
  'sku.updated',
  'source.canceled',
  'source.chargeable',
  'source.failed',
  'source.mandate_notification',
  'source.refund_attributes_required',
  'source.transaction.created',
  'source.transaction.updated',
  'subscription_schedule.aborted',
  'subscription_schedule.canceled',
  'subscription_schedule.completed',
  'subscription_schedule.created',
  'subscription_schedule.expiring',
  'subscription_schedule.released',
  'subscription_schedule.updated',
  'tax_rate.created',
  'tax_rate.updated',
  'terminal.reader.action_failed',
  'terminal.reader.action_succeeded',
  'test_helpers.test_clock.advancing',
  'test_helpers.test_clock.created',
  'test_helpers.test_clock.deleted',
  'test_helpers.test_clock.internal_failure',
  'test_helpers.test_clock.ready',
  'topup.canceled',
  'topup.created',
  'topup.failed',
  'topup.reversed',
  'topup.succeeded',
  'transfer.created',
  'transfer.reversed',
  'transfer.updated',
]

export const GOOGLE_MAPS_URL = 'https://maps.google.com/maps'

export const BOOM_AMENITIES = [
  'Air conditioning',
  'BBQ grill',
  'Balcony',
  'Billiards / Pool Tables',
  'Carbon monoxide detector',
  'Coffee maker',
  'Dishwasher',
  'Dryer',
  'Fireplace',
  'Game room',
  'Hair dryer',
  'Hot Tub',
  'Iron',
  'Kitchen',
  'Microwave',
  'Outdoor pool',
  'Oven',
  'Ping-pong table',
  'Private pool',
  'Refrigerator',
  'Smoke detector',
  'Stove',
  'Swimming pool',
  'TV',
  'Theater room',
  'Toaster',
  'Washer',
  'outdoor fireplace',
  'sofabed',
]

export const NET_INCOME_FORMULAS = [
  'fare_accommodation + cleaning_fee - reported_channel_fee',
  'fare_accommodation - cleaning_fee - host_channel_fee - processing_fee',
  'total_payout - cleaning_fee - reported_channel_fee - processing_fee',
  'client_price - cleaning_fee - host_channel_fee - processing_fee',
  'fare_accommodation - host_channel_fee - processing_fee',
  'total_payout - cleaning_fee - reported_channel_fee',
  'total_payout - reported_channel_fee - processing_fee',
  'fare_accommodation - reported_channel_fee',
  'total_payout - cleaning_fee - host_channel_fee - processing_fee',
  'fare_accommodation',
  'host_payout',
]

export const OWNER_REVENUE_FORMULAS = [
  'net_income - management_commission',
  'fare_accommodation - management_commission',
  'fare_accommodation - management_commission - host_channel_fee',
  'fare_accommodation - management_commission - host_channel_fee - processing_fee',
  'net_income - management_commission - ((host_channel_fee / (fare_accommodation + cleaning_fee)) * fare_accommodation)',
]

export const MANAGEMENT_COMMISSION_FORMULAS = [
  '(fare_accommodation + cleaning_fee) * commission_percent',
  '(fare_accommodation + total_taxes) * commission_percent',
  'fare_accommodation * commission_percent',
  'net_income * commission_percent',
  'host_payout * commission_percent',
  'total_payout * commission_percent',
]
export const OWNERS_INCOME_FORMULAS = [
  { value: 'client_profit', text: 'Client Profit' },
  { value: 'client_price', text: 'Client Price' },
  { value: 'fare_accommodation', text: 'Fare Accommodation' },
  { value: 'net_income', text: 'Net Income' },
  { value: 'price_total', text: 'Price' },
]
export const INCOME_VAT_FORMULA = [
  'host_payout',
  'fare_accommodation',
  'fare_accommodation + cleaning_fee',
]
export const MANAGEMENT_TOTAL_FORMULA = [
  'cleaning_fee + management_commission + pmc_fees',
  'cleaning_fee',
  'pmc_fees',
  'management_commission',
  'management_commission + pmc_fees',
  'management_commission + cleaning_fee',
  'cleaning_fee + pmc_fees',
]

export const BOOM_LANGUAGES = [
  { lang: 'en', country: 'us', label: 'English' },
  { lang: 'en-GB', country: 'gb', label: 'British English' },
  { lang: 'es', country: 'es', label: 'Spanish' },
  { lang: 'he', country: 'il', label: 'Hebrew' },
  { lang: 'ru', country: 'ru', label: 'Russian' },
  { lang: 'el', country: 'gr', label: 'Greek' },
  { lang: 'fr', country: 'fr', label: 'French' },
  { lang: 'pt', country: 'pt', label: 'Portuguese' },
  { lang: 'it', country: 'it', label: 'Italian' },
  { lang: 'ja', country: 'jp', label: 'Japanese' },
  { lang: 'ar', country: 'sa', label: 'Arabic' },
  { lang: 'mt', country: 'mt', label: 'Maltese' },
  { lang: 'sv', country: 'se', label: 'Swedish' },
]

export const MARBELLA_DEMO_LISTING_ID = 7841

export const BED_CAPACITY = {
  'queen size': 2,
  mattress: 1,
  'king size': 2,
  'extra bed': 1,
  'bunk bed': 2,
  'sofa bed': 2,
  'double sofa bed': 2,
  'double bed': 2,
  'single bed': 1,
  'Bunk beds (sgl on top, dbl on bottom)': 3,
  'Armchair that can turn into a single bed': 1,
  'Pair of twin beds': 2,
  'day bed': 1,
  Wallbed: 2,
  'Pull-Out Bed': 1,
  'Canopy / Poster Bed': 2,
  'Murphy Bed': 2,
  'Rollaway Beds': 1,
  'Tribunk bed': 3,
  'French bed': 1,
  'Extra large double': 2,
  'large double': 2,
  'Floor mattress': 1,
  'Air mattress': 1,
  'futon mat': 1,
  'Double futon mat': 2,
  'baby bed': 1,
}

export const MESSAGE_CHANNELS = [
  'Airbnb',
  'PlumGuide',
  'Marriott',
  'HomeAway',
  'TripAdvisor',
  'Booking.com',
  'Expedia',
  'Agoda',
  'Travel Station',
  'Agents',
  'Direct',
  'Owner',
  'manual',
  'Zoopla',
  'Hopper',
]

export const REVIEW_CHANNELS = [
  { text: 'Airbnb', value: 'Airbnb' },
  { text: 'Booking.com', value: 'Booking.com' },
  { text: 'Survey', value: 'survey' },
]

export const CHEKIN_SDK_CDN = {
  STAGING: 'https://cdn.chekin.com/housings-sdk/dev/index.umd.js',
  PRODUCTION: 'https://cdn.chekin.com/housings-sdk/latest/index.umd.js',
}

export const STATUS_COLORS = {
  inquiry: {
    background: '#EA87001A',
    text: '#EA8700',
  },
  confirmed: {
    background: '#00A86B1A',
    text: '#00A86B',
  },
  canceled: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  reserved: {
    background: '#FFC1071A',
    text: '#FFC107',
  },
  declined: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  expired: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  closed: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  'agent-confirmed': {
    background: '#00A86B1A',
    text: '#00A86B',
  },
}

export const CONVERSATION_SALES_STATUSES = [
  'COMPLETE',
  'LOST',
  'WON',
  'AWAITING PAYMENT',
  'ENQUIRY PAUSED',
  'SENT FOR APPROVAL',
  '3RD - EXPIRED/CLOSED TEXT',
  'EMAIL/WHATSAPP OFFER SENT',
  'ENQUIRY',
]

export const RESERVATION_STATUSES = [
  'confirmed',
  'inquiry',
  'reserved',
  'agent-confirmed',
  'canceled',
]

export const LANGUAGES = [
  { lang: 'en', country: 'us', label: 'Default' },
  { lang: 'es', country: 'es', label: 'Spanish' },
  { lang: 'he', country: 'il', label: 'Hebrew' },
  { lang: 'ru', country: 'ru', label: 'Russian' },
  { lang: 'el', country: 'gr', label: 'Greek' },
  { lang: 'fr', country: 'fr', label: 'French' },
  { lang: 'pt', country: 'pt', label: 'Portuguese' },
  { lang: 'it', country: 'it', label: 'Italian' },
  { lang: 'ja', country: 'jp', label: 'Japanese' },
  { lang: 'ar', country: 'sa', label: 'Arabic' },
  { lang: 'de', country: 'de', label: 'German' },
  { lang: 'nl', country: 'nl', label: 'Dutch' },
  { lang: 'pl', country: 'pl', label: 'Polish' },
  { lang: 'tr', country: 'tr', label: 'Turkish' },
  { lang: 'ko', country: 'kr', label: 'Korean' },
  { lang: 'hi', country: 'in', label: 'Hindi' },
  { lang: 'zh', country: 'cn', label: 'Chinese (Simplified)' },
  { lang: 'zh-TW', country: 'tw', label: 'Chinese (Traditional)' },
  { lang: 'vi', country: 'vn', label: 'Vietnamese' },
  { lang: 'th', country: 'th', label: 'Thai' },
  { lang: 'id', country: 'id', label: 'Indonesian' },
  { lang: 'sv', country: 'se', label: 'Swedish' },
  { lang: 'da', country: 'dk', label: 'Danish' },
  { lang: 'fi', country: 'fi', label: 'Finnish' },
  { lang: 'no', country: 'no', label: 'Norwegian' },
  { lang: 'cs', country: 'cz', label: 'Czech' },
  { lang: 'hu', country: 'hu', label: 'Hungarian' },
  { lang: 'ro', country: 'ro', label: 'Romanian' },
  { lang: 'uk', country: 'ua', label: 'Ukrainian' },
  { lang: 'bg', country: 'bg', label: 'Bulgarian' },
  { lang: 'sk', country: 'sk', label: 'Slovak' },
  { lang: 'hr', country: 'hr', label: 'Croatian' },
  { lang: 'sr', country: 'rs', label: 'Serbian' },
  { lang: 'sl', country: 'si', label: 'Slovenian' },
  { lang: 'et', country: 'ee', label: 'Estonian' },
  { lang: 'lv', country: 'lv', label: 'Latvian' },
  { lang: 'lt', country: 'lt', label: 'Lithuanian' },
  { lang: 'mk', country: 'mk', label: 'Macedonian' },
  { lang: 'sq', country: 'al', label: 'Albanian' },
  { lang: 'bs', country: 'ba', label: 'Bosnian' },
  { lang: 'ka', country: 'ge', label: 'Georgian' },
  { lang: 'hy', country: 'am', label: 'Armenian' },
  { lang: 'az', country: 'az', label: 'Azerbaijani' },
  { lang: 'kk', country: 'kz', label: 'Kazakh' },
  { lang: 'uz', country: 'uz', label: 'Uzbek' },
  { lang: 'mn', country: 'mn', label: 'Mongolian' },
  { lang: 'bn', country: 'bd', label: 'Bengali' },
  { lang: 'ta', country: 'lk', label: 'Tamil' },
  { lang: 'te', country: 'in', label: 'Telugu' },
  { lang: 'mr', country: 'in', label: 'Marathi' },
  { lang: 'gu', country: 'in', label: 'Gujarati' },
  { lang: 'kn', country: 'in', label: 'Kannada' },
  { lang: 'ml', country: 'in', label: 'Malayalam' },
  { lang: 'si', country: 'lk', label: 'Sinhala' },
  { lang: 'my', country: 'mm', label: 'Burmese' },
  { lang: 'km', country: 'kh', label: 'Khmer' },
  { lang: 'lo', country: 'la', label: 'Lao' },
  { lang: 'ms', country: 'my', label: 'Malay' },
  { lang: 'fil', country: 'ph', label: 'Filipino' },
  { lang: 'fa', country: 'ir', label: 'Persian' },
  { lang: 'ur', country: 'pk', label: 'Urdu' },
  { lang: 'am', country: 'et', label: 'Amharic' },
  { lang: 'sw', country: 'tz', label: 'Swahili' },
  { lang: 'af', country: 'za', label: 'Afrikaans' },
  { lang: 'is', country: 'is', label: 'Icelandic' },
  { lang: 'mt', country: 'mt', label: 'Maltese' },
  { lang: 'cy', country: 'gb-wls', label: 'Welsh' },
  { lang: 'ga', country: 'ie', label: 'Irish' },
  { lang: 'gd', country: 'gb-sct', label: 'Scottish Gaelic' },
  { lang: 'ca', country: 'es', label: 'Catalan' },
]

export const COUNTRIES = [
  { code_2: 'AD', label: 'Andorra' },
  { code_2: 'AE', label: 'United Arab Emirates' },
  { code_2: 'AF', label: 'Afghanistan' },
  { code_2: 'AG', label: 'Antigua and Barbuda' },
  { code_2: 'AI', label: 'Anguilla' },
  { code_2: 'AL', label: 'Albania' },
  { code_2: 'AM', label: 'Armenia' },
  { code_2: 'AO', label: 'Angola' },
  { code_2: 'AQ', label: 'Antarctica' },
  { code_2: 'AR', label: 'Argentina' },
  { code_2: 'AS', label: 'American Samoa' },
  { code_2: 'AT', label: 'Austria' },
  { code_2: 'AU', label: 'Australia' },
  { code_2: 'AW', label: 'Aruba' },
  { code_2: 'AX', label: 'Åland Islands' },
  { code_2: 'AZ', label: 'Azerbaijan' },
  { code_2: 'BA', label: 'Bosnia and Herzegovina' },
  { code_2: 'BB', label: 'Barbados' },
  { code_2: 'BD', label: 'Bangladesh' },
  { code_2: 'BE', label: 'Belgium' },
  { code_2: 'BF', label: 'Burkina Faso' },
  { code_2: 'BG', label: 'Bulgaria' },
  { code_2: 'BH', label: 'Bahrain' },
  { code_2: 'BI', label: 'Burundi' },
  { code_2: 'BJ', label: 'Benin' },
  { code_2: 'BL', label: 'Saint Barthélemy' },
  { code_2: 'BM', label: 'Bermuda' },
  { code_2: 'BN', label: 'Brunei Darussalam' },
  { code_2: 'BO', label: 'Bolivia (Plurinational State of)' },
  { code_2: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { code_2: 'BR', label: 'Brazil' },
  { code_2: 'BS', label: 'Bahamas' },
  { code_2: 'BT', label: 'Bhutan' },
  { code_2: 'BV', label: 'Bouvet Island' },
  { code_2: 'BW', label: 'Botswana' },
  { code_2: 'BY', label: 'Belarus' },
  { code_2: 'BZ', label: 'Belize' },
  { code_2: 'CA', label: 'Canada' },
  { code_2: 'CC', label: 'Cocos (Keeling) Islands' },
  { code_2: 'CD', label: 'Congo (Democratic Republic of the)' },
  { code_2: 'CF', label: 'Central African Republic' },
  { code_2: 'CG', label: 'Congo' },
  { code_2: 'CH', label: 'Switzerland' },
  { code_2: 'CI', label: "Côte d'Ivoire" },
  { code_2: 'CK', label: 'Cook Islands' },
  { code_2: 'CL', label: 'Chile' },
  { code_2: 'CM', label: 'Cameroon' },
  { code_2: 'CN', label: 'China' },
  { code_2: 'CO', label: 'Colombia' },
  { code_2: 'CR', label: 'Costa Rica' },
  { code_2: 'CU', label: 'Cuba' },
  { code_2: 'CV', label: 'Cabo Verde' },
  { code_2: 'CW', label: 'Curaçao' },
  { code_2: 'CX', label: 'Christmas Island' },
  { code_2: 'CY', label: 'Cyprus' },
  { code_2: 'CZ', label: 'Czechia' },
  { code_2: 'DE', label: 'Germany' },
  { code_2: 'DJ', label: 'Djibouti' },
  { code_2: 'DK', label: 'Denmark' },
  { code_2: 'DM', label: 'Dominica' },
  { code_2: 'DO', label: 'Dominican Republic' },
  { code_2: 'DZ', label: 'Algeria' },
  { code_2: 'EC', label: 'Ecuador' },
  { code_2: 'EE', label: 'Estonia' },
  { code_2: 'EG', label: 'Egypt' },
  { code_2: 'EH', label: 'Western Sahara' },
  { code_2: 'ER', label: 'Eritrea' },
  { code_2: 'ES', label: 'Spain' },
  { code_2: 'ET', label: 'Ethiopia' },
  { code_2: 'FI', label: 'Finland' },
  { code_2: 'FJ', label: 'Fiji' },
  { code_2: 'FK', label: 'Falkland Islands (Malvinas)' },
  { code_2: 'FM', label: 'Micronesia (Federated States of)' },
  { code_2: 'FO', label: 'Faroe Islands' },
  { code_2: 'FR', label: 'France' },
  { code_2: 'GA', label: 'Gabon' },
  {
    code_2: 'GB',
    label: 'United Kingdom of Great Britain and Northern Ireland',
  },
  { code_2: 'GD', label: 'Grenada' },
  { code_2: 'GE', label: 'Georgia' },
  { code_2: 'GF', label: 'French Guiana' },
  { code_2: 'GG', label: 'Guernsey' },
  { code_2: 'GH', label: 'Ghana' },
  { code_2: 'GI', label: 'Gibraltar' },
  { code_2: 'GL', label: 'Greenland' },
  { code_2: 'GM', label: 'Gambia' },
  { code_2: 'GN', label: 'Guinea' },
  { code_2: 'GP', label: 'Guadeloupe' },
  { code_2: 'GQ', label: 'Equatorial Guinea' },
  { code_2: 'GR', label: 'Greece' },
  { code_2: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { code_2: 'GT', label: 'Guatemala' },
  { code_2: 'GU', label: 'Guam' },
  { code_2: 'GW', label: 'Guinea-Bissau' },
  { code_2: 'GY', label: 'Guyana' },
  { code_2: 'HK', label: 'Hong Kong' },
  { code_2: 'HM', label: 'Heard Island and McDonald Islands' },
  { code_2: 'HN', label: 'Honduras' },
  { code_2: 'HR', label: 'Croatia' },
  { code_2: 'HT', label: 'Haiti' },
  { code_2: 'HU', label: 'Hungary' },
  { code_2: 'ID', label: 'Indonesia' },
  { code_2: 'IE', label: 'Ireland' },
  { code_2: 'IL', label: 'Israel' },
  { code_2: 'IM', label: 'Isle of Man' },
  { code_2: 'IN', label: 'India' },
  { code_2: 'IO', label: 'British Indian Ocean Territory' },
  { code_2: 'IQ', label: 'Iraq' },
  { code_2: 'IR', label: 'Iran (Islamic Republic of)' },
  { code_2: 'IS', label: 'Iceland' },
  { code_2: 'IT', label: 'Italy' },
  { code_2: 'JE', label: 'Jersey' },
  { code_2: 'JM', label: 'Jamaica' },
  { code_2: 'JO', label: 'Jordan' },
  { code_2: 'JP', label: 'Japan' },
  { code_2: 'KE', label: 'Kenya' },
  { code_2: 'KG', label: 'Kyrgyzstan' },
  { code_2: 'KH', label: 'Cambodia' },
  { code_2: 'KI', label: 'Kiribati' },
  { code_2: 'KM', label: 'Comoros' },
  { code_2: 'KN', label: 'Saint Kitts and Nevis' },
  { code_2: 'KP', label: "Korea (Democratic People's Republic of)" },
  { code_2: 'KR', label: 'Korea (Republic of)' },
  { code_2: 'KW', label: 'Kuwait' },
  { code_2: 'KY', label: 'Cayman Islands' },
  { code_2: 'KZ', label: 'Kazakhstan' },
  { code_2: 'LA', label: "Lao People's Democratic Republic" },
  { code_2: 'LB', label: 'Lebanon' },
  { code_2: 'LC', label: 'Saint Lucia' },
  { code_2: 'LI', label: 'Liechtenstein' },
  { code_2: 'LK', label: 'Sri Lanka' },
  { code_2: 'LR', label: 'Liberia' },
  { code_2: 'LS', label: 'Lesotho' },
  { code_2: 'LT', label: 'Lithuania' },
  { code_2: 'LU', label: 'Luxembourg' },
  { code_2: 'LV', label: 'Latvia' },
  { code_2: 'LY', label: 'Libya' },
  { code_2: 'MA', label: 'Morocco' },
  { code_2: 'MC', label: 'Monaco' },
  { code_2: 'MD', label: 'Moldova (Republic of)' },
  { code_2: 'ME', label: 'Montenegro' },
  { code_2: 'MF', label: 'Saint Martin (French part)' },
  { code_2: 'MG', label: 'Madagascar' },
  { code_2: 'MH', label: 'Marshall Islands' },
  { code_2: 'MK', label: 'North Macedonia' },
  { code_2: 'ML', label: 'Mali' },
  { code_2: 'MM', label: 'Myanmar' },
  { code_2: 'MN', label: 'Mongolia' },
  { code_2: 'MO', label: 'Macao' },
  { code_2: 'MP', label: 'Northern Mariana Islands' },
  { code_2: 'MQ', label: 'Martinique' },
  { code_2: 'MR', label: 'Mauritania' },
  { code_2: 'MS', label: 'Montserrat' },
  { code_2: 'MT', label: 'Malta' },
  { code_2: 'MU', label: 'Mauritius' },
  { code_2: 'MV', label: 'Maldives' },
  { code_2: 'MW', label: 'Malawi' },
  { code_2: 'MX', label: 'Mexico' },
  { code_2: 'MY', label: 'Malaysia' },
  { code_2: 'MZ', label: 'Mozambique' },
  { code_2: 'NA', label: 'Namibia' },
  { code_2: 'NC', label: 'New Caledonia' },
  { code_2: 'NE', label: 'Niger' },
  { code_2: 'NF', label: 'Norfolk Island' },
  { code_2: 'NG', label: 'Nigeria' },
  { code_2: 'NI', label: 'Nicaragua' },
  { code_2: 'NL', label: 'Netherlands' },
  { code_2: 'NO', label: 'Norway' },
  { code_2: 'NP', label: 'Nepal' },
  { code_2: 'NR', label: 'Nauru' },
  { code_2: 'NU', label: 'Niue' },
  { code_2: 'NZ', label: 'New Zealand' },
  { code_2: 'OM', label: 'Oman' },
  { code_2: 'PA', label: 'Panama' },
  { code_2: 'PE', label: 'Peru' },
  { code_2: 'PF', label: 'French Polynesia' },
  { code_2: 'PG', label: 'Papua New Guinea' },
  { code_2: 'PH', label: 'Philippines' },
  { code_2: 'PK', label: 'Pakistan' },
  { code_2: 'PL', label: 'Poland' },
  { code_2: 'PM', label: 'Saint Pierre and Miquelon' },
  { code_2: 'PN', label: 'Pitcairn' },
  { code_2: 'PR', label: 'Puerto Rico' },
  { code_2: 'PS', label: 'Palestine, State of' },
  { code_2: 'PT', label: 'Portugal' },
  { code_2: 'PW', label: 'Palau' },
  { code_2: 'PY', label: 'Paraguay' },
  { code_2: 'QA', label: 'Qatar' },
  { code_2: 'RE', label: 'Réunion' },
  { code_2: 'RO', label: 'Romania' },
  { code_2: 'RS', label: 'Serbia' },
  { code_2: 'RU', label: 'Russian Federation' },
  { code_2: 'RW', label: 'Rwanda' },
  { code_2: 'SA', label: 'Saudi Arabia' },
  { code_2: 'SB', label: 'Solomon Islands' },
  { code_2: 'SC', label: 'Seychelles' },
  { code_2: 'SD', label: 'Sudan' },
  { code_2: 'SE', label: 'Sweden' },
  { code_2: 'SG', label: 'Singapore' },
  { code_2: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code_2: 'SI', label: 'Slovenia' },
  { code_2: 'SJ', label: 'Svalbard and Jan Mayen' },
  { code_2: 'SK', label: 'Slovakia' },
  { code_2: 'SL', label: 'Sierra Leone' },
  { code_2: 'SM', label: 'San Marino' },
  { code_2: 'SN', label: 'Senegal' },
  { code_2: 'SO', label: 'Somalia' },
  { code_2: 'SR', label: 'Suriname' },
  { code_2: 'SS', label: 'South Sudan' },
  { code_2: 'ST', label: 'Sao Tome and Principe' },
  { code_2: 'SV', label: 'El Salvador' },
  { code_2: 'SX', label: 'Sint Maarten (Dutch part)' },
  { code_2: 'SY', label: 'Syrian Arab Republic' },
  { code_2: 'SZ', label: 'Eswatini' },
  { code_2: 'TC', label: 'Turks and Caicos Islands' },
  { code_2: 'TD', label: 'Chad' },
  { code_2: 'TF', label: 'French Southern Territories' },
  { code_2: 'TG', label: 'Togo' },
  { code_2: 'TH', label: 'Thailand' },
  { code_2: 'TJ', label: 'Tajikistan' },
  { code_2: 'TK', label: 'Tokelau' },
  { code_2: 'TL', label: 'Timor-Leste' },
  { code_2: 'TM', label: 'Turkmenistan' },
  { code_2: 'TN', label: 'Tunisia' },
  { code_2: 'TO', label: 'Tonga' },
  { code_2: 'TR', label: 'Turkey' },
  { code_2: 'TT', label: 'Trinidad and Tobago' },
  { code_2: 'TV', label: 'Tuvalu' },
  { code_2: 'TW', label: 'Taiwan, Province of China' },
  { code_2: 'TZ', label: 'Tanzania, United Republic of' },
  { code_2: 'UA', label: 'Ukraine' },
  { code_2: 'UG', label: 'Uganda' },
  { code_2: 'UM', label: 'United States Minor Outlying Islands' },
  { code_2: 'US', label: 'United States of America' },
  { code_2: 'UY', label: 'Uruguay' },
  { code_2: 'UZ', label: 'Uzbekistan' },
  { code_2: 'VA', label: 'Holy See' },
  { code_2: 'VC', label: 'Saint Vincent and the Grenadines' },
  { code_2: 'VE', label: 'Venezuela (Bolivarian Republic of)' },
  { code_2: 'VG', label: 'Virgin Islands (British)' },
  { code_2: 'VI', label: 'Virgin Islands (U.S.)' },
  { code_2: 'VN', label: 'Viet Nam' },
  { code_2: 'VU', label: 'Vanuatu' },
  { code_2: 'WF', label: 'Wallis and Futuna' },
  { code_2: 'WS', label: 'Samoa' },
  { code_2: 'YE', label: 'Yemen' },
  { code_2: 'YT', label: 'Mayotte' },
  { code_2: 'ZA', label: 'South Africa' },
  { code_2: 'ZM', label: 'Zambia' },
  { code_2: 'ZW', label: 'Zimbabwe' },
]

export const TEMPLATE_COLUMNS = [
  { label: 'External ID*', selected: true },
  { label: 'Nickname', selected: true },
  { label: 'Address', selected: true },
  { label: 'Title' },
  { label: 'Arrival instructions' },
  { label: 'Departure instructions' },
  { label: 'Services:wifi:Main wifi name' },
  { label: 'Services:wifi:Main wifi code' },
  { label: 'Services:door_codes:Main door guests #1' },
  { label: 'Check In Time' },
  { label: 'Check Out Time' },
  { label: 'Personal:Cleaning Supervisor:email' },
  { label: 'Cleaning Fee' },
  { label: 'Zip Code' },
  { label: 'Bedrooms' },
  { label: 'Bathrooms' },
  { label: 'OTA Type' },
  { label: 'Space' },
  { label: 'Standard Guests' },
  { label: 'Floor' },
  { label: 'Description:summary' },
  { label: 'Description:space' },
  { label: 'Description:access' },
  { label: 'Description:notes' },
  { label: 'Description:houseRules' },
  { label: 'Description:neighborhood' },
  { label: 'Description:transit' },
  { label: 'Description:interactionWithGuests' },
  { label: 'Description:directions' },
  { label: 'Personal:Pool:email' },
  { label: 'Personal:Hot Tub:email' },
  { label: 'Personal:VA:email' },
  { label: 'Personal:Maintenance:email' },
  { label: 'Personal:Landscape:email' },
  { label: 'Personal:Host:email' },
  { label: 'Personal:Region Manager:email' },
  { label: 'Personal:General Maintenance:email' },
  { label: 'Personal:Design Maintenance:email' },
  { label: 'Personal:Appliances:email' },
  { label: 'Personal:Electrician:email' },
  { label: 'Personal:Pest:email' },
  { label: 'Personal:Plumber:email' },
]

export const PERSONNEL_ROLES = [
  { text: 'Appliances', value: 'appliances' },
  { text: 'Cleaning Supervisor', value: 'cleaning_supervisor' },
  { text: 'Design Maintenance', value: 'design_maintenance' },
  { text: 'Electrician', value: 'electrician' },
  { text: 'General Maintenance', value: 'general_maintenance' },
  { text: 'Host', value: 'host' },
  { text: 'Hot Tub', value: 'hot_tub' },
  { text: 'Landscape', value: 'landscape' },
  { text: 'Maintenance', value: 'maintenance' },
  { text: 'Pest', value: 'pest' },
  { text: 'Plumber', value: 'plumber' },
  { text: 'Pool', value: 'pool' },
  { text: 'Region Manager', value: 'region_manager' },
  { text: 'VA', value: 'va' },
]

export const MESSAGE_TEMPLATE_PARAMETERS = [
  { text: 'Guest name', value: '{{guest_name}}' },
  { text: 'Company name', value: '{{company_name}}' },
  { text: 'Guest Portal Link', value: '{{portal_link}}' },
  { text: 'Internal Review Link', value: '{{review_link}}' },
  { text: 'Confirmation code', value: '{{confirmation_code}}' },
  { text: 'Check in', value: '{{check_in}}' },
  { text: 'Check in time', value: '{{check_in_time}}' },
  { text: 'Check in date', value: '{{check_in_date}}' },
  { text: 'Check out', value: '{{check_out}}' },
  { text: 'Check out time', value: '{{check_out_time}}' },
  { text: 'Check out date', value: '{{check_out_date}}' },
  { text: 'Listing nickname', value: '{{listing_nickname}}' },
  { text: 'Listing Postcode', value: '{{postcode}}' },
  { text: 'Listing Address', value: '{{address}}' },
  { text: 'Listing Title', value: '{{title}}' },
  { text: 'Door codes', value: '{{door_code}}' },
  { text: 'Wifi name', value: '{{wifi_name}}' },
  { text: 'Wifi password', value: '{{wifi_pass}}' },
  { text: 'Check-in Instructions', value: '{{checkin_instruct}}' },
  {
    text: 'Early Checkin Price (set availability rules)',
    value: '{{early_in_price}}',
  },
  {
    text: 'Late Checkout Price (set availability rules)',
    value: '{{late_out_price}}',
  },
  {
    text: 'Checkin a day early Price (set availability rules)',
    value: '{{add_in_price}}',
  },
  {
    text: 'Checkout a day later Price (set availability rules)',
    value: '{{add_out_price}}',
  },
  {
    text: 'Early Checkin Link (set availability rules)',
    value: '{{early_in_link}}',
  },
  {
    text: 'Late Checkout Link (set availability rules)',
    value: '{{late_out_link}}',
  },
  {
    text: 'Checkin a day early Link (set availability rules)',
    value: '{{add_in_link}}',
  },
  {
    text: 'Checkout a day later Link (set availability rules)',
    value: '{{add_out_link}}',
  },
  {
    text: 'Full price of booking',
    value: '{{client_price}}',
  },
]

const AI_REVIEW_TAGS_NEGATIVE = [
  "CS - Refund wasn't sufficient",
  'CS - Check in process',
  'CS - Check-in Process: Wrong codes provided',
  'CS - Guest Experience issue',
  'CS - Guest misinformed',
  'CS - Long Response Time',
  'CS - Not Applicable Solution',
  'CS - Parking Pass',
  'CS - Parking issue',
  'CS - Pre-Heating Pool',
  'CS - Refund Process',
  'CS - Return Process',
  'Channel - Inaccurate listing',
  'Cleaning - Bed Linens',
  'Cleaning - Dirty items',
  'Cleaning - Missing Items',
  'Cleaning - Stained Furnitures',
  'Cleaning - Cleanliness',
  'Cleaning - Delayed Check-in',
  'Cleaning - Dirty Appliances',
  'Cleaning - Dirty Floors',
  'Cleaning - Dirty Towels',
  'Cleaning - Garbage Issue',
  'Cleaning - Item for replacement / Item issue',
  'Cleaning - Mold Issue',
  'Cleaning - Privacy Issue',
  'Cleaning - Smell issue',
  'Design - Furniture Feedback',
  'Design - Insufficient Lighting',
  'Design - Low Quality Kitchen Items',
  'Design - Low Quality Towels',
  'Design - Old Furniture',
  'General - ADR Price',
  'General - Hostile Neighbors',
  'General - Issues raised not relevant',
  'IOT - IOT Devices',
  'None - False review',
  'PM - AC issue',
  'PM - Appliance Issue',
  'PM - Broken Shower',
  'PM - Bulb light issue',
  'PM - Cable/Wire related issues',
  'PM - Ceiling Damage',
  'PM - Check-in Process: Lock/Keypad',
  'PM - Damaged Item',
  'PM - Delayed Check-in',
  'PM - Door/ Window Issue',
  'PM - Electricity issue',
  'PM - Floor Needs fixing',
  'PM - Furniture issue (broken chair, bed, table)',
  'PM - Garbage Issue',
  'PM - Gas leak',
  'PM - Gas tanks empty',
  'PM - Health Hazard',
  'PM - Hot water related issues',
  'PM - Internet Issue',
  'PM - Landscaping',
  'PM - Lock/Keypad',
  'PM - Not enough Water Pressure',
  'PM - PM Bath/ Hot Tub/ jaccuzzi Issue',
  'PM - Pest control',
  'PM - Plumbing - Ceiling leak',
  'PM - Plumbing - Drainage Issue',
  'PM - Plumbing - Sink related issues',
  'PM - Plumbing shower leak',
  'PM - Plumbing',
  'PM - Pool Drain',
  'PM - Pool Fence/ Bar tool Issue',
  'PM - Pool Filter',
  'PM - Pool Floor',
  'PM - Pool heater',
  'PM - Pool not clean',
  'PM - Privacy Issue',
  'PM - Property maintenance issues',
  'PM - Router issue',
  'PM - Safety Hazard',
  'PM - Scheduled Services Canceled',
  'PM - Shattered Glass door/ window',
  'PM - Smoke detector Issue',
  'PM - Thermostat Issue',
  'PM - Toilet',
  'PM - Ventilation Issue',
  'PM - Water Damage',
  'Security - Camera Issues',
  'Security - Security/Rover Staff',
]

const AI_REVIEW_TAGS_POSITIVE = [
  'CS - Exceptional Customer Support',
  'CS - Friendly and Helpful Staff',
  'CS - Personalized Guest Experience',
  'CS - Fast and Efficient Communication',
  'CS - Proactive Problem Resolution',
  'CS - Thoughtful Welcome Gesture',
  'CS - Seamless Booking Experience',
  'CS - Hassle-Free Check-in',
  'CS - Check-in Process: Smooth and Easy',
  'CS - Accurate and Helpful Information',
  'CS - Quick Refund Processing',
  'CS - Clear and Transparent Policies',
  'CS - Responsive and Attentive Team',
  'Channel - Accurate and Detailed Listing',
  'Channel - Beautiful and As-Described Property',
  'General - Great Value for Price',
  'General - Safe and Quiet Neighborhood',
  'General - Hassle-Free Experience',
  'General - Well-Equipped Property',
  'General - Exceeded Expectations',
  'General - Smooth and Memorable Stay',
  'General - Family-Friendly Environment',
  'General - Perfect for Remote Work',
  'General - Pet-Friendly and Comfortable',
  'Cleaning - Spotless and Well-Sanitized Space',
  'Cleaning - Fresh and Comfortable Bed Linens',
  'Cleaning - Well-Stocked with Essentials',
  'Cleaning - Fresh Towels and Toiletries',
  'Cleaning - Pleasant Fragrance',
  'Cleaning - Quick and Thorough Housekeeping',
  'Cleaning - Pristine Bathrooms',
  'Cleaning - Kitchen Spotlessly Clean',
  'Cleaning - No Dust or Dirt',
  'Cleaning - Well-Maintained and Clean Furniture',
  'Cleaning - Eco-Friendly and Sustainable Cleaning Practices',
  'PM - Well-Functioning AC and Heating',
  'PM - Strong and Reliable WiFi',
  'PM - Comfortable and Well-Designed Furniture',
  'PM - Smart Home Features Worked Perfectly',
  'PM - Well-Stocked Kitchen with High-Quality Items',
  'PM - Spacious and Well-Laid-Out Property',
  'PM - Beautifully Decorated Interior',
  'PM - Strong Water Pressure and Hot Water Availability',
  'PM - Secure and Easy-to-Use Locks',
  'PM - Well-Maintained Swimming Pool and Spa',
  'PM - Peaceful and Quiet Environment',
  'PM - Effective Pest Control',
  'PM - Excellent Ventilation and Airflow',
  'PM - Plenty of Storage Space',
  'PM - Comfortable and Supportive Mattress',
  'PM - High-Quality Linens and Pillows',
  'Outdoor - Beautiful Landscaping and Garden',
  'Outdoor - Spacious and Well-Kept Balcony/Terrace',
  'Outdoor - Secure and Well-Lit Parking Area',
  'Outdoor - Quiet and Safe Location',
  'Outdoor - Great Views from the Property',
  'Outdoor - Close to Local Attractions and Amenities',
  'Outdoor - Safe and Well-Lit Neighborhood',
  'Outdoor - Kid-Friendly and Safe Outdoor Space',
  'Security - Secure and Well-Monitored Property',
  'Security - Friendly and Attentive Security Staff',
  'Security - Well-Placed and Functioning Security Cameras',
  'Security - Private and Peaceful Stay',
  'Security - Gated Community or Secure Entry',
  'Security - Safe and Well-Locked Windows/Doors',
  'Security - Trustworthy and Respectful Staff',
  'Extra - Thoughtful Welcome Basket or Snacks',
  'Extra - Complimentary Coffee and Tea',
  'Extra - Fast and Flexible Early Check-in / Late Check-out',
  'Extra - High-End Toiletries Provided',
  'Extra - Cozy and Warm Atmosphere',
  'Extra - Perfect for Special Occasions',
  'Extra - Hosts Went Above and Beyond',
  'Extra - Great for Business Travelers',
  'Extra - Entertainment Options (Netflix, Board Games, Books, etc.)',
  'Guest - Would Definitely Return',
  'Guest - Highly Recommended Stay',
  'Guest - Five-Star Experience',
  'Guest - Felt Like Home',
  'Guest - Unforgettable Vacation',
  'Guest - Perfect for Couples/Groups/Families',
  'Guest - Better Than Expected',
  'Guest - Best Stay Ever',
  'Guest - Flawless and Stress-Free Experience',
]

export const AI_REVIEW_TAGS = [
  ...AI_REVIEW_TAGS_NEGATIVE.map(tag => ({ text: tag, type: 'negative' })),
  ...AI_REVIEW_TAGS_POSITIVE.map(tag => ({ text: tag, type: 'positive' })),
]
