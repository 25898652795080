<template>
  <v-card class="pa-2 overflow-y-auto">
    <v-card-title class="d-flex">
      <div class="d-flex align-center justify-space-between flex-grow-1">
        <span> Reservation </span>
        <div class="d-flex align-center ml-auto">
          <v-btn
            v-if="
              reservation.reservation_guesty_id && !isInvestor && !isCleaner
            "
            :to="`/dashboard/reservation/${reservation.reservation_guesty_id}`"
            target="_blank"
            icon
          >
            <span>{{ reservation.listing_name }}</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <reservation-create-modal
            v-if="!isInvestor && manuallyEditable"
            :key="reservation.id"
            :is-owner-vacation="reservation.source === 'Owner'"
            class="ml-auto"
            :is-edit-mode="true"
            :edit-reservation="reservation"
            @change="getListing"
          />
          <reservation-edit-modal
            v-else-if="isInvestor && reservation.source === 'Owner'"
            :key="reservation.id"
            class="ml-auto"
            :reservation="reservation"
            :listing="{ id: reservation.listing_id }"
            @change="getListing"
          />
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="mb-3">
        <div
          class="text-h6 d-flex align-center font-weight-medium text-capitalize"
        >
          {{ displayGuestName(guestName) }}
          <v-chip
            label
            small
            :color="resStatusColor(reservation.status)"
            class="mx-3"
          >
            <span>{{ reservation.status }}</span>
          </v-chip>
        </div>
      </div>
      <div class="d-flex align-center justify-space-between flex-wrap">
        <info-box title="check-in" :value="checkInDatetime" />
        <info-box title="check-out" :value="checkOutDatetime" />
        <info-box
          title="confirmation code"
          :value="reservation.confirmation_code"
        />
        <info-box title="guests count" :value="reservation.guests_count" />
      </div>
      <div
        v-if="!isCleaner && !isCleaningManager && !isCleaningSupervisor"
        class="my-3"
      >
        <v-sheet
          class="pa-3 rounded grey rounded lighten-4 secondary--text font-weight-medium pointer"
          @click="detailsOpen = !detailsOpen"
        >
          <v-icon x-small>$right_arrow</v-icon>
          <span class="mx-2">Details</span>
        </v-sheet>
        <v-expand-transition>
          <div v-show="detailsOpen" class="py-2 px-4">
            <v-row class="d-flex align-baseline my-2 no-gutters">
              <v-col
                v-if="reservation.guest"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Guest name</v-col
              >
              <v-col
                v-if="reservation.guest"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
                >{{ displayGuestName(guestName) }}
              </v-col>
              <v-col
                v-if="reservation.guest && (isSmartBnb || isAdmin)"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Guest email</v-col
              >
              <v-col
                v-if="reservation.guest && (isSmartBnb || isAdmin)"
                cols="12"
                sm="6"
                class="py-1 font-weight-medium text-subtitle-1"
                >{{ reservation.primary_email }}</v-col
              >
              <v-col
                v-if="reservation.guest && (isSmartBnb || isAdmin)"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Guest phone</v-col
              >
              <v-col
                v-if="reservation.guest && (isSmartBnb || isAdmin)"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
                >{{ reservation.primary_phone }}</v-col
              >
            </v-row>
            <v-divider class="my-2" />
            <v-row class="d-flex align-baseline my-2 no-gutters">
              <v-col cols="12" sm="4" class="py-1 grey-text font-weight-medium"
                >Source</v-col
              >
              <v-col
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
                >{{ reservation.source }}</v-col
              >
              <v-col
                v-if="reservation.cleaning_listing_task_id && !isInvestor"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Cleaning Task</v-col
              >
              <v-col
                v-if="reservation.cleaning_listing_task_id && !isInvestor"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                <router-link
                  target="_blank"
                  :to="{
                    name: 'lt-page',
                    params: { id: reservation.cleaning_listing_task_id },
                  }"
                >
                  #{{ reservation.cleaning_listing_task_id }}
                </router-link></v-col
              >
            </v-row>
          </div>
        </v-expand-transition>
        <v-sheet
          v-if="!isCleaner && !isCleaningManager && !isCleaningSupervisor"
          class="pa-3 rounded grey rounded lighten-4 secondary--text font-weight-medium pointer"
          @click="financeOpen = !financeOpen"
        >
          <v-icon x-small>$right_arrow</v-icon>
          <span class="mx-2">Finance</span>
        </v-sheet>
        <v-expand-transition>
          <div v-show="financeOpen">
            <v-row>
              <v-col v-if="!isInvestor" cols="12" sm="6">
                <review-stats-box
                  color="success"
                  title="Total Reservation Payment"
                  :value="toMoney(reservation.host_payout)"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <review-stats-box
                  color="orange"
                  :title="
                    isInvestor && !showFinanceBreakdown
                      ? 'Total Price'
                      : 'Owner Revenue'
                  "
                  :value="
                    isInvestor && !showFinanceBreakdown
                      ? totalIncome
                      : toMoney(reservation.client_profit)
                  "
                />
              </v-col>
            </v-row>
            <v-row
              v-if="showFinanceBreakdown"
              class="d-flex align-baseline my-2 no-gutters"
            >
              <v-col
                v-if="reservation.fare_accommodation && !isInvestor"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Accommodation</v-col
              >
              <v-col
                v-if="reservation.fare_accommodation && !isInvestor"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
                >{{ toMoney(reservation.fare_accommodation) }}</v-col
              >
              <v-col
                v-if="(isSmartBnb || isAdmin) && reservation.cleaning_fee"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Cleaning</v-col
              >
              <v-col
                v-if="(isSmartBnb || isAdmin) && reservation.cleaning_fee"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                {{ toMoney(reservation.cleaning_fee) }}
              </v-col>
              <v-col
                v-if="reservation.total_taxes && !isInvestor"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Taxes</v-col
              >
              <v-col
                v-if="reservation.total_taxes && !isInvestor"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                {{ toMoney(reservation.total_taxes) }}
              </v-col>
              <v-col
                v-if="reservation.host_channel_fee && !isInvestor"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Channel fee</v-col
              >
              <v-col
                v-if="reservation.host_channel_fee && !isInvestor"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                {{ toMoney(reservation.host_channel_fee) }}
              </v-col>
              <v-col
                v-if="reservation.processing_fee && !isInvestor"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Processing fee</v-col
              >
              <v-col
                v-if="reservation.processing_fee && !isInvestor"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                {{ toMoney(reservation.processing_fee) }}
              </v-col>
              <v-col
                v-if="reservation.management_commission"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Management fee</v-col
              >
              <v-col
                v-if="reservation.management_commission"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                {{ toMoney(reservation.management_commission) }}
              </v-col>
              <v-col
                v-if="isSmartBnb"
                cols="12"
                sm="4"
                class="py-1 grey-text font-weight-medium"
                >Net income</v-col
              >
              <v-col
                v-if="isSmartBnb"
                cols="12"
                sm="6"
                class="py-1 text-capitalize font-weight-medium text-subtitle-1"
              >
                {{ toMoney(reservation.net_income) }}
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </div>
      <div
        v-if="reservation.source === 'Vacation' && (isAdmin || isInvestor)"
        class="text-end"
      >
        <v-btn class="ml-auto" color="warning" @click="cancelVaction"
          >Cancel Vacation</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonMixin from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import InfoBox from 'components/common/info-box.vue'
import ReviewStatsBox from 'components/reviews/review-stats-box.vue'
import ReservationEditModal from 'components/reservation/reservation-edit-modal.vue'
import ReservationCreateModal from 'components/reservation/reservation-create-modal.vue'
export default {
  name: 'ReservationCardModal',
  components: {
    ReservationCreateModal,
    ReservationEditModal,
    ReviewStatsBox,
    InfoBox,
  },
  mixins: [FormattersMixin, PermissionsMixin, CommonMixin],
  data() {
    return {
      detailsOpen: true,
      financeOpen: true,
    }
  },
  props: ['reservation', 'timezone'],
  computed: {
    appSettings() {
      return this.$store.state.app.configuration || {}
    },
    showFinanceBreakdown() {
      if (!this.isInvestor) return true

      if (this.appSettings.dashboard_settings) {
        return !this.appSettings.dashboard_settings.hide_reservation_breakdown
      }

      return true
    },
    incomeFormula() {
      const VALUES = {
        client_profit: 'client_profit',
        client_price: 'client_price',
        fare_accommodation: 'fare_accommodation',
        net_income: 'net_income',
        price_total: 'price',
      }

      const listing = this.$store.state.currentListing || {}
      const ownerIncomeFormula =
        listing.owners_income_formula ||
        (this.appSettings && this.appSettings.owners_income_formula)

      return ownerIncomeFormula
        ? this.reservation[VALUES[ownerIncomeFormula]]
        : this.reservation.fare_accommodation
    },
    totalIncome() {
      return this.incomeFormula
        ? this.toMoney(this.incomeFormula)
        : this.toMoney(0)
    },
    manuallyEditable() {
      return (
        this.reservation.reservation_type === 'long_term' ||
        (this.reservation.app_integration_name === 'boom' &&
          this.reservation.source === 'manual')
      )
    },
    guestName() {
      if (this.reservation.source === 'Owner') {
        return 'Owner'
      }
      if (this.reservation.guest) {
        return this.reservation.guest.full_name
      }
      return ''
    },
    checkInDatetime() {
      const time = this.reservation.planned_arrival
        ? this.reservation.planned_arrival
        : this.parseDateTZ(this.reservation.check_in, 'HH:mm', this.timezone)
      return `${this.parseDateTZ(this.reservation.check_in)} ${time}`
    },
    checkOutDatetime() {
      const time = this.reservation.planned_departure
        ? this.reservation.planned_departure
        : this.parseDateTZ(this.reservation.check_out, 'HH:mm', this.timezone)
      return `${this.parseDateTZ(this.reservation.check_out)} ${time}`
    },
  },
  methods: {
    getListing() {
      this.$store.dispatch('changeListing', {
        listingId: this.reservation.listing_id,
      })
    },
    cancelVaction() {
      const userConfirm = confirm(
        'Are you sure you want to cancel this vacation?'
      )
      if (userConfirm) {
        axios
          .delete(`/api/owner-vacation/${this.reservation.id}`)
          .then(() => {
            this.$store.dispatch('changeListing', {
              listingId: this.$store.state.currentListing.id,
            })
            this.dialog = false
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    avatarImg(guest) {
      if (guest && guest.picture) {
        return guest.picture
      } else {
        return '/no_image_available.jpg'
      }
    },
    markSuspicious(val) {
      this.$store.dispatch('reservation/updateReservation', {
        id: this.reservation.id,
        is_suspicious: val,
      })
    },
    close() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
