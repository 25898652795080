// global-polyfills.js
// This file provides polyfills for browsers that don't support certain features

// Ensure fetch is globally available
if (typeof window !== 'undefined' && !window.fetch) {
  // Import fetch polyfill from whatwg-fetch
  require('whatwg-fetch')
}

// Add any other global polyfills here

export default {
  // This is just a marker to show this file was loaded
  polyfillsLoaded: true,
}
