import customAxios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    ratePlans: [],
    availableListings: [],
  },
  mutations: {
    updateRatePlans(state, payload) {
      state.ratePlans = payload.data.rate_plans
    },
    updateAvailableListings(state, payload) {
      state.availableListings = payload
    },
  },
  actions: {
    async getRatePlans({ commit }, params) {
      const { data } = await customAxios
        .get('api/rate-plans', {
          params: {
            include_listings: params?.include_listings,
            listing_id: params?.listing_id,
          },
        })
        .catch(console.error)

      commit('updateRatePlans', data.payload)
      return data
    },

    async getAvailableListings({ commit }) {
      const { data } = await customAxios
        .get('api/listings-picker')
        .catch(console.error)

      commit('updateAvailableListings', data)
      return data
    },

    async createRatePlan({ dispatch }, payload) {
      await customAxios.post('api/rate-plans', payload).catch(console.error)

      await dispatch('getRatePlans', {
        include_listings: true,
      })
    },

    async updateRatePlan({ dispatch }, { id, ...payload }) {
      await customAxios
        .put('api/rate-plans/' + id, payload)
        .catch(console.error)

      await dispatch('getRatePlans', {
        include_listings: true,
      })
    },

    async deleteRatePlan({ dispatch }, payload) {
      const { id, listing_id } = payload
      await customAxios.delete('api/rate-plans/' + id).catch(console.error)

      await dispatch('getRatePlans', {
        include_listings: true,
        listing_id,
      })
    },
  },
}
