var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-click":"","offset-y":"","disabled":_vm.readonly},scopedSlots:_vm._u([(_vm.$scopedSlots.activator)?{key:"activator",fn:function(slotData){return [_vm._t("activator",null,null,slotData)]}}:{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"elevation":"0","text":"","small":_vm.isMobileOrIPad}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Actions'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"secondary","x-small":""}},[_vm._v(" fas fa-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c('v-list',{staticClass:"py-1"},[(!_vm.isContractor && _vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-btn',_vm._b({attrs:{"disabled":Boolean(!_vm.listingTask.extra_data.current_guest_conversation_id),"color":"info"},on:{"click":function($event){return _vm.openChat({
            id: _vm.listingTask.extra_data.current_guest_conversation_id,
          })}}},'v-btn',_vm.btnDefaultProps,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-comment")]),_vm._v(" "+_vm._s(_vm.$t('Chat Guest'))+" ")],1)],1):_vm._e(),(_vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('shopping-list-modal',{attrs:{"listing-task":_vm.listingTask}})],1):_vm._e(),(!_vm.isContractor && _vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-btn',_vm._b({attrs:{"href":`tel:+${_vm.phoneNumber}`,"disabled":!_vm.phoneNumber,"color":"info"}},'v-btn',_vm.btnDefaultProps,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-phone")]),_vm._v(" "+_vm._s(_vm.$t('Call Guest'))+" ")],1)],1):_vm._e(),(
        (_vm.isOperationsPersonal || _vm.isCommunicationPerson || _vm.isAdmin) &&
        _vm.listingTask
      )?_c('v-list-item',{staticClass:"pa-0"},[_c('LtContinuationModal',{attrs:{"allowed-dates":_vm.allowedDates,"button-text":"Continuation","listing-task-id":_vm.listingTask.id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1):_vm._e(),(
        (_vm.isOperationsPersonal ||
          _vm.isCommunicationManager ||
          _vm.isAdmin ||
          _vm.cleaningRoles) &&
        _vm.listingTask &&
        _vm.listingTask.reg_cleaning
      )?_c('v-list-item',{staticClass:"pa-0"},[_c('LtContinuationModal',{attrs:{"button-text":"Claim","entry-mode":true,"allowed-dates":_vm.allowedDates,"pre-fields":_vm.claimLt,"listing-task-id":_vm.listingTask.id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1):_vm._e(),(_vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('ConfirmationModal',{attrs:{"text":_vm.$t('Are you sure you want to call cleaning?')},on:{"action":_vm.callCleaning},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","disabled":Boolean(_vm.listingTask.cleaning_task_id)}},'v-btn',_vm.btnDefaultProps,false),slotData.on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-broom")]),_vm._v(" "+_vm._s(_vm.$t('Call Cleaning'))+" ")],1)]}}],null,false,2849599693)})],1):_vm._e(),(
        _vm.managementRoles &&
        !_vm.listingTask.blocked_listing_date &&
        _vm.listingTask.can_block &&
        _vm.isDesignedVr
      )?_c('v-list-item',{staticClass:"pa-0"},[_c('ConfirmationModal',{attrs:{"text":`This would BLOCK ${_vm.parseDate(
          _vm.listingTask.scheduled_at
        )}, are you sure?`},on:{"action":_vm.blockListing},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"orange darken-2"}},'v-btn',_vm.btnDefaultProps,false),slotData.on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("lock")]),_vm._v(" "+_vm._s(_vm.$t('Block'))+" ("+_vm._s(_vm.parseDate(_vm.listingTask.scheduled_at))+") ")],1)]}}],null,false,3931835733)})],1):_vm._e(),(_vm.managementRoles && _vm.listingTask.blocked_listing_date)?_c('v-list-item',{staticClass:"pa-0"},[_c('ConfirmationModal',{attrs:{"text":`This would UN-BLOCK ${_vm.parseDate(
          _vm.listingTask.scheduled_at
        )}, are you sure?`},on:{"action":_vm.unBlockListing},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green darken-2"}},'v-btn',_vm.btnDefaultProps,false),slotData.on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("lock_open")]),_vm._v(" "+_vm._s(_vm.$t('Free'))+" ("+_vm._s(_vm.parseDate(_vm.listingTask.scheduled_at))+") ")],1)]}}],null,false,1730270792)})],1):_vm._e(),(
        _vm.managementRoles &&
        !_vm.listingTask.deposit &&
        !_vm.listingTask.manual_approved &&
        _vm.isDesignedVr
      )?_c('v-list-item',{staticClass:"pa-0"},[_c('LtDepositModal',{attrs:{"task-id":_vm.listingTask.id}})],1):_vm._e(),(_vm.managementRoles && _vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('lt-followup-modal',{attrs:{"listing-task":_vm.listingTask}})],1):_vm._e(),(_vm.managementRoles && _vm.listingTask.listing_id)?_c('v-list-item',{staticClass:"pa-0"},[_c('owner-approval-modal',{attrs:{"listing-task":_vm.listingTask}})],1):_vm._e(),(_vm.managementRoles && _vm.listingTask.status != 'Done')?_c('v-list-item',{staticClass:"pa-0"},[_c('LtManageFeeModal',{attrs:{"amount":_vm.listingTask.amount,"task-id":_vm.listingTask.id}})],1):_vm._e(),_c('v-list-item',{staticClass:"px-0"},[_c('warranty-modal',{attrs:{"listing-task":_vm.listingTask}})],1),(_vm.hasAbility('reviewer') && _vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-btn',_vm._b({attrs:{"disabled":_vm.listingTask.manager_review},on:{"click":_vm.managerReview}},'v-btn',_vm.btnDefaultProps,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-certificate")]),_vm._v(" "+_vm._s(_vm.$t('Manager review'))+" ")],1)],1):_vm._e(),(_vm.canDoListingTasks && _vm.isDesignedVr)?_c('v-list-item',{staticClass:"pa-0"},[_c('requests',{attrs:{"only-take":true},scopedSlots:_vm._u([{key:"customBtn",fn:function({ on }){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',_vm.btnDefaultProps,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("inventory")]),_vm._v(" "+_vm._s(_vm.$t('Take from Inventory')))],1)]}}],null,false,427400446)})],1):_vm._e(),(
        _vm.listingTask.listing_task_template_id &&
        (_vm.managementRoles || _vm.isCleaningManager)
      )?_c('v-list-item',{staticClass:"pa-0"},[_c('ConfirmationModal',{attrs:{"text":"Are you sure you want to continue this task flow ?"},on:{"action":_vm.continueListingTaskFlow},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":false,"disabled":_vm.isTaskAlreadyDoneBefore}},'v-btn',_vm.btnDefaultProps,false),slotData.on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("account_tree")]),_vm._v(" "+_vm._s(_vm.$t('Continue Flow'))+" ")],1)]}}],null,false,797374182)})],1):_vm._e(),(_vm.managementRoles || _vm.isCleaningManager)?_c('v-list-item',{staticClass:"pa-0"},[_c('ConfirmationModal',{attrs:{"text":_vm.deleteTaskConfirmationText},on:{"action":_vm.deleteListingTask},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-btn',_vm.btnDefaultProps,false),slotData.on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-trash")]),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1)]}}],null,false,2765552049)})],1):_vm._e(),_c('v-list-item',{staticClass:"pa-0"},[_c('v-btn',_vm._b({attrs:{"color":"yellow darken-2"},on:{"click":function($event){return _vm.directionsTo(_vm.listingTask.listing.address)}}},'v-btn',_vm.btnDefaultProps,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-google-maps")]),_vm._v(" "+_vm._s(_vm.$t('Navigate'))+" ")],1)],1),_c('v-list-item',{staticClass:"pa-0"},[_c('ConfirmationModal',{attrs:{"text":`Are you sure you want to ${
          _vm.listingTask.is_outstanding ? 'unmark' : 'mark'
        } this task as Outstanding`},on:{"action":_vm.toggleOutstanding},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"amber darken-4"}},'v-btn',_vm.btnDefaultProps,false),slotData.on),[_c('v-icon',{attrs:{"color":"amber darken-3","small":"","left":""}},[_vm._v("mdi-alert-circle")]),_vm._v(" "+_vm._s(_vm.listingTask.is_outstanding ? _vm.$t('Unmark as outstanding') : _vm.$t('Mark as outstanding'))+" ")],1)]}}],null,false,1558022361)})],1),(_vm.managementRoles)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-btn',_vm._b({attrs:{"color":"primary"},on:{"click":_vm.exportPdf}},'v-btn',_vm.btnDefaultProps,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-file-pdf")]),_vm._v(" "+_vm._s(_vm.$t('Export PDF'))+" ")],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }