<template>
  <v-card
    :class="[{ 'ml-auto mr-4': !isGuest }, { 'ml-4': isGuest }, 'my-2']"
    :max-width="isMobile ? '85%' : '60%'"
    elevation="0"
  >
    <v-card-text class="pa-0">
      <div
        :class="[
          'd-flex',
          'align-center',
          { 'flex-row-reverse': !isGuest },
          'text-caption',
          'black--text',
          'mb-0',
        ]"
      >
        <ai-train-modal
          v-if="
            !isGuest &&
            !msg.auto_message_rule_id &&
            conversation.active_reservation
          "
          :train-messages="trainMessages"
          :conversation="conversation"
          :msg="msg"
        />
        <span v-if="msg.auto_message_rule_id" class="font-weight-medium"
          >Auto</span
        >
        <span v-else class="font-weight-medium">{{
          isGuest ? conversation.guest_full_name : msg.rep_name
        }}</span>
        <v-icon v-if="msg.msg_type">mdi-circle-small</v-icon>
        <span v-if="msg.msg_type"> via {{ msg.msg_type }} </span>
      </div>
      <div class="d-flex">
        <v-sheet
          :class="[
            { 'grey lighten-4': !isGuest && !isNote && !isFailed },
            { 'info lighten-5': isGuest && !isFailed },
            { 'notification lighten-5': isNote },
            { 'rounded-tl-0': isGuest, 'rounded-tr-0': !isGuest },
            { 'msg-failed': isFailed },
          ]"
          class="mx-2 pa-4 font-weight-medium flex rounded-xl overflow-y-auto"
          :elevation="isGuest ? 3 : 0"
          rounded
        >
          <div
            class="p-relative"
            :class="[{ trw: collapsable, 'height-trans': collapsed }]"
          >
            <div
              class="msg-box font-weight-medium text-caption text-md-body-2"
              v-html="switchNewLine(msg.body)"
            />
          </div>
          <div v-if="collapsable" class="d-flex justify-end flex align-end">
            <v-btn plain text @click="onCollapse">
              <v-icon>{{
                collapsed ? 'fas fa-caret-up' : 'fas fa-caret-down'
              }}</v-icon>
            </v-btn>
          </div>
          <div v-if="msg.recording_url" class="mt-2">
            <audio
              controls
              class="small-audio-player"
              :src="msg.recording_url"
            ></audio>
          </div>
        </v-sheet>
        <div style="display: flex; flex-direction: column">
          <span v-if="msg.ai_translation" class="cyan--text tooltip">
            <v-icon class="mr-2 cyan--text tooltipicon">mdi-translate</v-icon>
            <span class="tooltiptext">
              {{ msg.ai_translation }}
            </span>
          </span>
          <span v-if="transcodedAudios(msg)" class="cyan--text tooltip">
            <v-icon class="mr-2 cyan--text tooltipicon">mdi-transcribe</v-icon>
            <span class="tooltiptext">
              {{ transcodedAudios(msg) }}
            </span>
          </span>
          <span
            v-if="isSuperAdmin && msg.en_translation"
            class="cyan--text tooltip"
          >
            <v-icon class="mr-2 cyan--text tooltipicon">mdi-bug-check</v-icon>
            <span class="tooltiptext">
              {{ msg.en_translation }}
            </span>
          </span>
        </div>
      </div>

      <div
        :class="[
          'd-flex',
          { 'justify-end': !isGuest },
          'align-center',
          'text-caption',
          'grey--text',
          'mt-1',
        ]"
      >
        <div v-if="msg.is_pre_made">
          <v-chip color="grey lighten-4" x-small>
            <span class="secondary--text">Premade</span>
          </v-chip>
          <v-icon>mdi-circle-small</v-icon>
        </div>
        <span v-if="isToday(msg.sent_at)">
          {{ parseDateTZ(msg.sent_at, 'hh:mm A', timezone) }}
        </span>
        <span v-else>
          {{ parseDateTZ(msg.sent_at, 'D MMM YYYY, hh:mm A', timezone) }}
        </span>
        <span v-if="msg.status" class="ml-2">
          <v-icon
            v-if="['read', 'delivered'].includes(msg.status)"
            dark
            :color="msg.status === 'read' ? 'blue' : 'grey'"
            small
            >mdi-check-all</v-icon
          >
          <v-icon v-if="['queued'].includes(msg.status)" dark color="grey" small
            >mdi-progress-clock</v-icon
          >
          <v-icon v-if="msg.status === 'sent'" dark color="grey" small
            >mdi-check</v-icon
          >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-if="isFailed" color="error" v-on="msg.error_msg && on"
                >mdi-alert-circle
              </v-icon>
            </template>
            <div class="msg-error">
              {{ msg.error_msg }}
            </div>
          </v-tooltip>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import AiTrainModal from 'components/conversations/ai-train-modal.vue'
import BoomFeature from 'components/mixins/boom-feature'

export default {
  name: 'MsgBox',
  mixins: [CommonFunctions, PermissionsMixin, deviceMixin, BoomFeature],
  props: ['msg', 'conversation', 'collapsable', 'trainMessages', 'a'],
  components: { AiTrainModal },

  data() {
    return {
      collapsed: !this.collapsable,
    }
  },
  computed: {
    linkRegex() {
      return /((https?|ftps?):\/\/[^"'<(,)>\s]+)(?![^<>]*>|[^"]*?<\/a)/gim
    },
    timezone() {
      return this.$store.state.app.configuration.timezone
    },
    isNote() {
      return this.msg.msg_type === 'note'
    },
    isGuest() {
      return this.msg.by === 'guest' || this.msg.by === 'guesty'
    },
    isFailed() {
      return ['undelivered', 'failed'].includes(this.msg.status)
    },
  },
  methods: {
    firstLine(message) {
      return this.switchNewLine(message).split('<br>')[0]
    },
    switchNewLine(message) {
      if (message) {
        if (message.startsWith('<!')) {
          const div = document.createElement('div')
          div.innerHTML = message
          const elements = div.getElementsByTagName('base')
          while (elements[0]) elements[0].parentNode.removeChild(elements[0])
          message = div.innerHTML
        }
        return message
          .replace(/(?:\r\n|\r|\n)/g, '<br>')
          .replace(
            this.linkRegex,
            '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
          )
      }
    },
    onCollapse() {
      this.collapsed = !this.collapsed
    },
    transcodedAudios(message) {
      if (message.transcoded_audios && message.transcoded_audios.length) {
        return message.transcoded_audios.join('<br>')
      }
    },
  },
}
</script>
<style lang="scss">
.trw {
  max-height: 25px;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}
.height-trans {
  max-height: 1000px;
  transition: max-height 0.75s ease-in;
}
.guest-box {
  border-top-left-radius: 0 !important;
}
.host-box {
  border-top-right-radius: 0 !important;
}
.msg-box {
  word-break: break-word;
}

.msg-box img {
  max-width: 500px;
}
.msg-box >>> a {
  color: blue;
}
.msg-error {
  width: 350px;
}
.msg-failed {
  background-color: #fff7f8 !important;
  border-color: #fff7f8 !important;
}
.tooltip {
  margin-top: -8px;
}
.tooltip .tooltipicon {
  font-size: 20px;
}
.small-audio-player {
  height: 30px !important;
}
</style>
