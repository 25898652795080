<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="256.000000pt"
    height="256.000000pt"
    viewBox="0 0 256.000000 256.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill="#7B50DC"
      stroke="none"
    >
      <path
        d="M145 2544 c-22 -8 -57 -31 -77 -51 -72 -70 -68 -4 -68 -1211 0 -802 3 -1095 12 -1124 17 -58 76 -120 134 -141 46 -16 126 -17 1133 -17 802 0 1094 3 1123 12 22 6 59 29 83 50 80 70 75 -7 75 1219 0 1207 4 1139 -67 1211 -70 72 -4 68 -1217 67 -930 0 -1097 -3 -1131 -15z m1745 -631 c0 -147 -17 -258 -55 -358 -74 -197 -198 -299 -492 -404 -240 -86 -317 -152 -338 -291 l-7 -45 429 3 c235 2 438 0 451 -3 22 -5 23 -7 20 -148 l-3 -142 -624 -3 -624 -2 6 172 c4 127 10 191 25 243 71 254 207 386 502 485 159 53 251 101 296 154 43 52 62 95 63 144 l1 32 -432 0 c-238 0 -439 0 -445 0 -10 0 -13 36 -13 145 l0 145 620 0 620 0 0 -127z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Zoopla',
}
</script>

<style scoped></style>
