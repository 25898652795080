import axios from '@/axios/config'

export default {
  state: {
    templates: [],
    templatesPagination: {
      count: 0,
      page: 1,
      per_page: 10,
    },
    loadingTemplates: false,
  },
  mutations: {
    updateTemplates(state, templates) {
      state.templates = templates
    },
    updateTemplatesPagination(state, pagination) {
      state.templatesPagination = pagination
    },
    updateLoadingTemplates(state, loading) {
      state.loadingTemplates = loading
    },
  },
  actions: {
    async fetchTemplates({ commit }, params = {}) {
      commit('updateLoadingTemplates', true)
      try {
        const { data } = await axios.get('/api/templates', { params })
        commit('updateTemplates', data.templates || [])
        return data
      } catch (error) {
        console.error('Error fetching templates:', error)
        throw error
      } finally {
        commit('updateLoadingTemplates', false)
      }
    },

    async createTemplate({ dispatch }, payload) {
      try {
        const { data } = await axios.post('/api/templates', payload)
        dispatch('fetchTemplates')
        return data
      } catch (error) {
        console.error('Error creating template:', error)
        throw error
      }
    },

    async deleteTemplate({ dispatch }, id) {
      try {
        await axios.delete(`/api/templates/${id}`)
        dispatch('fetchTemplates')
        return true
      } catch (error) {
        console.error('Error deleting template:', error)
        throw error
      }
    },

    async requestTemplateApproval(
      { dispatch },
      { id, name, category = 'UTILITY' }
    ) {
      try {
        const { data } = await axios.post(`/api/templates/request_approval`, {
          id,
          name,
          category,
        })
        dispatch('fetchTemplates')
        return data
      } catch (error) {
        console.error('Error requesting template approval:', error)
        throw error
      }
    },

    async syncTemplates({ commit, dispatch }) {
      try {
        const { data } = await axios.post('/api/templates/sync')
        commit('updateTemplates', data.templates || [])
        return data
      } catch (error) {
        console.error('Error syncing templates:', error)
        throw error
      }
    },
  },
}
