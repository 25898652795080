<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-icon small :color="color || 'inherit'" v-bind="attrs" v-on="on">
        info
      </v-icon>
    </template>
    <span v-if="text">{{ text }}</span>
    <slot v-else />
  </v-tooltip>
</template>

<script>
export default {
  name: 'InfoTooltip',
  props: ['text', 'color'],
}
</script>

<style></style>
