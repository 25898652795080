import axios, { autoCancelToken } from '@/axios/config'

export default {
  namespaced: true,
  state: {
    calendarUpdates: 0,
    calendarLoading: false,
  },
  mutations: {
    calendarUpdate(state) {
      state.calendarUpdates++
    },
    toggleCalendarLoading(state) {
      state.calendarLoading = !state.calendarLoading
    },
  },
  actions: {
    async blockCalendarBatch({ commit }, { ...body }) {
      commit('toggleCalendarLoading')
      try {
        const { data } = await axios.put(`/api/listing-calendar/batch`, body)
        commit('toggleCalendarLoading')
        return data
      } catch (e) {
        commit('toggleCalendarLoading')
      }
    },
    async fetchMultiCalListings({ _commit }, params) {
      try {
        const response = await axios.get(
          '/api/listing-calendar/multi-cal-listings',
          { params }
        )
        return response.data
      } catch (error) {
        console.error('Error fetching multi-cal data:', error)
        throw error
      }
    },
    async fetchMultiCalDates({ _commit }, params) {
      try {
        const response = await axios.get(
          `/api/listing-calendar/multi-cal-dates`,
          { params }
        )
        return response.data
      } catch (error) {
        console.error('Error fetching multi-cal data:', error)
        throw error
      }
    },
  },
}
