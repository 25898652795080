import InfoIcon from '@/assets/icons/info'
import WifiIcon from '@/assets/icons/wifi'
import PhoneCallIcon from '@/assets/icons/phone-call'
import DoorKeyIcon from '@/assets/icons/door-key'
import VideoIcon from '@/assets/icons/video'
import LeftArrowIcon from '@/assets/icons/left-arrow'
import RightArrowIcon from '@/assets/icons/right-arrow'
import MapDirectionsIcon from '@/assets/icons/map-directions'
import ShoppingCartIcon from '@/assets/icons/shopping-cart'
import LockChecked from '@/assets/icons/lock-checked'
import RotateIcon from '@/assets/icons/rotate'
import TrashIcon from '@/assets/icons/trash'
import CommentIcon from '@/assets/icons/comment'
import PhotosBeforeIcon from '@/assets/icons/photos_before'
import PhotosAfterIcon from '@/assets/icons/photos_after'
import HurricaneIcon from '@/assets/icons/hurricane'
import MicIcon from '@/assets/icons/mic'
import DownloadIcon from '@/assets/icons/download'
import TilesIcon from '@/assets/icons/tiles'
import TableViewIcon from '@/assets/icons/table-view'
import CancelIcon from '@/assets/icons/cancel'
import HomeIcon from '@/assets/icons/home'
import BarsUpIcon from '@/assets/icons/bars-up'
import AccountingIcon from '@/assets/icons/accounting'
import OverallStatusIcon from '@/assets/icons/overall-status'
import TasksIcon from '@/assets/icons/tasks'
import ChatIcon from '@/assets/icons/chat'
import FinanceIcon from '@/assets/icons/finance'
import ActivityIcon from '@/assets/icons/activity'
import StoreIcon from '@/assets/icons/store'
import SettingsIcon from '@/assets/icons/settings'
import SalesIcon from '@/assets/icons/sales'
import CalendarIcon from '@/assets/icons/calendar'
import CleaningIcon from '@/assets/icons/cleaning'
import SetupsIcon from '@/assets/icons/setups'
import MaintenanceIcon from '@/assets/icons/maintenance'
import WifiAltIcon from '@/assets/icons/wifi-alt'
import InventoryIcon from '@/assets/icons/inventory'
import PromotionIcon from '@/assets/icons/promotion'
import LogoutIcon from '@/assets/icons/logout'
import DashboardTilesIcon from '@/assets/icons/dashboard-tiles'
import InfoCircleAltIcon from '@/assets/icons/info-circle-alt'
import IotIcon from '@/assets/icons/iot'
import CLockIcon from '@/assets/icons/clock'
import MoonIcon from '@/assets/icons/moon'
import WalletIcon from '@/assets/icons/wallet'
import MoneyIcon from '@/assets/icons/money'
import ProfitsIcon from '@/assets/icons/profits'
import EmailSent from '@/assets/icons/email-sent'
import AccountAlt from '@/assets/icons/account-alt'
import Equity from '@/assets/icons/equity'
import PurchasePrice from '@/assets/icons/purchase-price'
import Growth from '@/assets/icons/growth'
import MoneyBag from '@/assets/icons/money-bag'
import Investment from '@/assets/icons/investment'
import Profit from '@/assets/icons/profit'
import Expenses from '@/assets/icons/expenses'
import OpenNewTab from '@/assets/icons/open-new-tab'
import SearchIcon from '@/assets/icons/search'
import FiltersIcon from '@/assets/icons/filters'
import AudioIcon from '@/assets/icons/audio'
import BatteryIcon from '@/assets/icons/battery'
import HumidityIcon from '@/assets/icons/humidity'
import ThermometerIcon from '@/assets/icons/thermometer'
import ListIcon from '@/assets/icons/list'
import LayoutIcon from '@/assets/icons/layout'
import BedIcon from '@/assets/icons/bed-doubled'
import Bedroom from '@/assets/icons/bedroom.vue'
import BathIcon from '@/assets/icons/bath-tub.vue'
import ShowerIcon from '@/assets/icons/shower'
import RulesIcon from '@/assets/icons/rules'
import SecurityIcon from '@/assets/icons/security'
import CompleteIcon from '@/assets/icons/complete'
import GarbageIcon from '@/assets/icons/garbage'
import BarsGraphIcon from '@/assets/icons/bars-graph'
import FilesIcon from '@/assets/icons/files'
import TeamIcon from '@/assets/icons/team'
import ArrowChartIcon from '@/assets/icons/arrow-chart'
import CalendarCheckIcon from '@/assets/icons/calendar-check'
import ExpandMenuIcon from '@/assets/icons/expand-menu'
import PeopleIcon from '@/assets/icons/people-icon'
import CalculatorIcon from '@/assets/icons/calculator'
import BillIcon from '@/assets/icons/bill'
import CalendarFillIcon from '@/assets/icons/calendar-fill'
import InboxIcon from '@/assets/icons/inbox'
import PersonArrowRight from '@/assets/icons/person-arrow-right'
import PeoplePlusIcon from '@/assets/icons/people-plus'
import AlarmIcon from '@/assets/icons/alarm'
import FileIcon from '@/assets/icons/file'
import WarrantyIcon from '@/assets/icons/warranty'
import FilePasteIcon from '@/assets/icons/file-paste'
import Airbnb from '@/assets/icons/airbnb'
import Booking from '@/assets/icons/booking'
import Idealista from '@/assets/icons/idealista'
import TravelStaytion from '@/assets/icons/travel-staytion'
import Zoopla from '@/assets/icons/zoopla'

import Expedia from '@/assets/icons/expedia'
import VRBO from '@/assets/icons/vrbo'
import Google from '@/assets/icons/google'
import Marriot from '@/assets/icons/marriot'
import DVR from '@/assets/icons/dvr'
import TripAdvisor from '@/assets/icons/trip-advisor'
import Worthington from '@/assets/icons/worthington'
import Grand from '@/assets/icons/grand'
import Tools from '@/assets/icons/tools'
import Question from '@/assets/icons/question-mark'
import USD from '@/assets/icons/dollar'
import EUR from '@/assets/icons/euro'
import Flag from '@/assets/icons/flag'
import SoldStamp from '@/assets/icons/sold-stamp'
import DollarSack from '@/assets/icons/dollar-sack'
import Rating from '@/assets/icons/rating'
import Speedometer from '@/assets/icons/speedometer'
import TrendUp from '@/assets/icons/trend-up'
import TrendDown from '@/assets/icons/trend-down'
import CheckCircle from '@/assets/icons/check-circle'
import Pulse from '@/assets/icons/pulse'
import ExclamationTriangle from '@/assets/icons/exclamation-triangle'
import HotelReception from '@/assets/icons/hotel-reception'
import GuestExperience from '@/assets/icons/guest-experience'
import OfficeOps from '@/assets/icons/office-ops'
import GuestCrm from '@/assets/icons/guest-crm.vue'
import Guest from '@/assets/icons/guest.vue'
import DevTools from '@/assets/icons/dev-tools'
import Portfolio from '@/assets/icons/portfolio.vue'
import Analytics from '@/assets/icons/analytics.vue'
import SearchTask from '@/assets/icons/search-task.vue'
import OwnerIcon from '@/assets/icons/owner.vue'
import OperationsIcon from '@/assets/icons/operations.vue'
import AccountFillIcon from '@/assets/icons/accounting-fill.vue'
import AiIcon from '@/assets/icons/ai.vue'
import ConfigurationIcon from '@/assets/icons/configuration.vue'
import GuestFillIcon from '@/assets/icons/guest-fill.vue'
import MarketingIcon from '@/assets/icons/marketing.vue'
import SettingsFillIcon from '@/assets/icons/settings-fill.vue'

export default {
  values: {
    checkboxOff: 'far fa-square',
    owner: {
      component: OwnerIcon,
    },
    operations: {
      component: OperationsIcon,
    },
    accounting_fill: {
      component: AccountFillIcon,
    },
    ai: {
      component: AiIcon,
    },
    configuration: {
      component: ConfigurationIcon,
    },
    guest_fill: {
      component: GuestFillIcon,
    },
    marketing: {
      component: MarketingIcon,
    },
    settings_fill: {
      component: SettingsFillIcon,
    },
    info_circle: {
      component: InfoIcon,
    },
    door_key: {
      component: DoorKeyIcon,
    },
    phone_call: {
      component: PhoneCallIcon,
      props: {
        // pass props to your component if needed
        color: 'grey',
      },
    },
    wifi: {
      component: WifiIcon,
    },
    video: {
      component: VideoIcon,
    },
    left_arrow: {
      component: LeftArrowIcon,
    },
    right_arrow: {
      component: RightArrowIcon,
    },
    map_directions: {
      component: MapDirectionsIcon,
    },
    shopping_cart: {
      component: ShoppingCartIcon,
    },
    lock_checked: {
      component: LockChecked,
    },
    rotate: {
      component: RotateIcon,
    },
    trash: {
      component: TrashIcon,
    },
    comment: {
      component: CommentIcon,
    },
    photos_before: {
      component: PhotosBeforeIcon,
    },
    photos_after: {
      component: PhotosAfterIcon,
    },
    hurricane: {
      component: HurricaneIcon,
    },
    mic: {
      component: MicIcon,
    },
    download: {
      component: DownloadIcon,
    },
    tiles: {
      component: TilesIcon,
    },
    table_view: {
      component: TableViewIcon,
    },
    cancel: {
      component: CancelIcon,
    },
    home: {
      component: HomeIcon,
    },
    bars_up: {
      component: BarsUpIcon,
    },
    accounting: {
      component: AccountingIcon,
    },
    overall_status: {
      component: OverallStatusIcon,
    },
    tasks: {
      component: TasksIcon,
    },
    chat: {
      component: ChatIcon,
    },
    finance: {
      component: FinanceIcon,
    },
    activity: {
      component: ActivityIcon,
    },
    store: {
      component: StoreIcon,
    },
    settings: {
      component: SettingsIcon,
    },
    sales: {
      component: SalesIcon,
    },
    calendar: {
      component: CalendarIcon,
    },
    cleaning: {
      component: CleaningIcon,
    },
    setups: {
      component: SetupsIcon,
    },
    maintenance: {
      component: MaintenanceIcon,
    },
    wifi_alt: {
      component: WifiAltIcon,
    },
    inventory: {
      component: InventoryIcon,
    },
    promotion: {
      component: PromotionIcon,
    },
    logout: {
      component: LogoutIcon,
    },
    dashboard_tiles: {
      component: DashboardTilesIcon,
    },
    info_circle_alt: {
      component: InfoCircleAltIcon,
    },
    iot: {
      component: IotIcon,
    },
    clock: {
      component: CLockIcon,
    },
    moon: {
      component: MoonIcon,
    },
    wallet: {
      component: WalletIcon,
    },
    money: {
      component: MoneyIcon,
    },
    profits: {
      component: ProfitsIcon,
    },
    email_sent: {
      component: EmailSent,
    },
    account_alt: {
      component: AccountAlt,
    },
    purchase_price: {
      component: PurchasePrice,
    },
    equity: {
      component: Equity,
    },
    growth: {
      component: Growth,
    },
    money_bag: {
      component: MoneyBag,
    },
    investment: {
      component: Investment,
    },
    profit: {
      component: Profit,
    },
    expenses: {
      component: Expenses,
    },
    external_link: {
      component: OpenNewTab,
    },
    search: {
      component: SearchIcon,
    },
    filters: {
      component: FiltersIcon,
    },
    audio: {
      component: AudioIcon,
    },
    battery: {
      component: BatteryIcon,
    },
    humidity: {
      component: HumidityIcon,
    },
    thermometer: {
      component: ThermometerIcon,
    },
    list: {
      component: ListIcon,
    },
    layout: {
      component: LayoutIcon,
    },
    bed_doubled: {
      component: BedIcon,
    },
    bedroom: {
      component: Bedroom,
    },
    bath: {
      component: BathIcon,
    },
    shower: {
      component: ShowerIcon,
    },
    rules: {
      component: RulesIcon,
    },
    security: {
      component: SecurityIcon,
    },
    complete: {
      component: CompleteIcon,
    },
    garbage: {
      component: GarbageIcon,
    },
    bars_graph: {
      component: BarsGraphIcon,
    },
    files: {
      component: FilesIcon,
    },
    team: {
      component: TeamIcon,
    },
    arrow_chart: {
      component: ArrowChartIcon,
    },
    calendar_check: {
      component: CalendarCheckIcon,
    },
    expand_menu: {
      component: ExpandMenuIcon,
    },
    people: {
      component: PeopleIcon,
    },
    calculator: {
      component: CalculatorIcon,
    },
    bill: {
      component: BillIcon,
    },
    calendar_fill: {
      component: CalendarFillIcon,
    },
    inbox: {
      component: InboxIcon,
    },
    my_tickets: {
      component: PersonArrowRight,
    },
    people_plus: {
      component: PeoplePlusIcon,
    },
    alarm: {
      component: AlarmIcon,
    },
    file: {
      component: FileIcon,
    },
    warranty: {
      component: WarrantyIcon,
    },
    file_paste: {
      component: FilePasteIcon,
    },
    airbnb: {
      component: Airbnb,
    },
    bookingcom: {
      component: Booking,
    },
    booking: {
      component: Booking,
    },
    idealista: {
      component: Idealista,
    },
    travelstaytion: {
      component: TravelStaytion,
    },
    zoopla: {
      component: Zoopla,
    },
    homeaway: {
      component: VRBO,
    },
    expedia: {
      component: Expedia,
    },
    cheaptickets: {
      component: Expedia,
    },
    vrbo: {
      component: VRBO,
    },
    google: {
      component: Google,
    },
    marriott: {
      component: Marriot,
    },
    plumguide: {
      component: Marriot,
    },
    hometogo: {
      component: Marriot,
    },
    designedvrcom: {
      component: DVR,
    },
    designedvr: {
      component: DVR,
    },
    tripadvisor: {
      component: TripAdvisor,
    },
    theworthington: {
      component: Worthington,
    },
    grand: {
      component: Grand,
    },
    thegrand: {
      component: Grand,
    },
    reception: {
      component: Worthington,
    },
    innroad: {
      component: Worthington,
    },
    phonecall: {
      component: PhoneCallIcon,
    },
    covidcredits: {
      component: Question,
    },
    manual: {
      component: Tools,
    },
    USD: {
      component: USD,
    },
    EUR: {
      component: EUR,
    },
    flag: {
      component: Flag,
    },
    sold: {
      component: SoldStamp,
    },
    dollarSack: {
      component: DollarSack,
    },
    rating: {
      component: Rating,
    },
    speedometer: {
      component: Speedometer,
    },
    trend_up: {
      component: TrendUp,
    },
    trend_down: {
      component: TrendDown,
    },
    check_circle: {
      component: CheckCircle,
    },
    pulse: {
      component: Pulse,
    },
    exclamation_triangle: {
      component: ExclamationTriangle,
    },
    hotel_reception: {
      component: HotelReception,
    },
    guest_experience: {
      component: GuestExperience,
    },
    office_ops: {
      component: OfficeOps,
    },
    guest: {
      component: Guest,
    },
    guest_crm: {
      component: GuestCrm,
    },
    dev_tools: {
      component: DevTools,
    },
    analytics: {
      component: Analytics,
    },
    portfolio: {
      component: Portfolio,
    },
    search_task: {
      component: SearchTask,
    },
    vacation: 'mdi-crown-circle-outline',
  },
}
