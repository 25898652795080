<template>
  <v-container>
    <div class="text-h6">Notification Settings</div>
    <div class="text-caption font-weight-medium secondary--text">
      Select the kinds of notifications you would like to get about your
      activities
    </div>

    <v-divider class="my-4"></v-divider>

    <template v-if="user">
      <v-list dense max-width="500">
        <v-list-item
          v-for="(item, index) in filteredNotificationSettings"
          :key="index"
        >
          <v-list-item-content
            class="d-flex justify-space-between align-center"
          >
            <div class="d-flex justify-space-between align-center">
              <span class="text-subtitle-2 font-weight-medium"
                >{{ item.label }}
                <v-tooltip v-if="item.description" top>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on"> $info </v-icon>
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
              </span>

              <v-switch
                v-model="notifications[item.key]"
                color="info"
                @change="updateNotifications({ [item.key]: $event })"
              ></v-switch>
            </div>
            <!-- Region and Zone Notification Restrictions -->
            <v-expand-transition>
              <div
                v-if="
                  item.key == 'new_message' &&
                  notifications.new_message === true
                "
              >
                <template v-if="user && (user.region || user.zone_id)">
                  <v-alert
                    v-if="user.region"
                    text
                    color="info"
                    class="mb-2 text-caption"
                    dense
                  >
                    You will only receive notifications for listings in the
                    <span class="font-weight-semibold">{{ regionName }}</span>
                    region.
                  </v-alert>
                  <v-alert
                    v-if="user.zone_id"
                    text
                    color="info"
                    dense
                    class="mb-2 text-caption"
                  >
                    You will only receive notifications for listings in the
                    <span class="font-weight-semibold">{{ zoneName }}</span>
                    zone.
                  </v-alert>
                </template>
                <v-radio-group
                  v-model="notifications.new_message_scope"
                  dense
                  @change="updateNotifications({ new_message_scope: $event })"
                >
                  <v-radio value="all" color="info">
                    <template #label>
                      <div class="d-flex align-center">
                        <span>All Messages</span>
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-icon v-bind="attrs" small class="ml-1" v-on="on">
                              $info
                            </v-icon>
                          </template>
                          <span>
                            Get notifications for all guest messages (subject to
                            region/zone restrictions)
                          </span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="personnel" color="info">
                    <template #label>
                      <div class="d-flex align-center">
                        <span>Personnel Only</span>
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-icon class="ml-1" v-bind="attrs" small v-on="on">
                              $info
                            </v-icon>
                          </template>
                          <span>
                            Get notifications only for listings you are assigned
                            to (Cleaning Supervisor, Pool, etc.)
                          </span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="assigned_user" color="info">
                    <template #label>
                      <div class="d-flex align-center">
                        <span>Assigned Conversations Only</span>
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-icon class="ml-1" v-bind="attrs" small v-on="on">
                              $info
                            </v-icon>
                          </template>
                          <span>
                            Get notifications only for conversations where you
                            are the assigned agent
                          </span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-expand-transition>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-4"></v-divider>

        <template v-if="isAdmin && user.role !== 'investor'">
          <div class="text-subtitle-1 font-weight-medium mb-2">
            Feed Channel Notifications
          </div>

          <v-text-field
            v-model="feedChannelSearch"
            label="Search feed channels"
            prepend-inner-icon="$search"
            dense
            outlined
            clearable
            class="mb-4"
            hide-details
          ></v-text-field>

          <v-list dense max-width="500">
            <v-list-item
              v-for="channel in filteredFeedChannels"
              :key="'feed_' + channel.id"
            >
              <v-list-item-content>
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="notifications['feed_channel_' + channel.id]"
                    color="info"
                    class="mt-0 pt-0"
                    hide-details
                    @change="
                      updateNotifications({
                        ['feed_channel_' + channel.id]: $event,
                      })
                    "
                  ></v-checkbox>
                  <span class="text-subtitle-2 font-weight-medium ml-2">
                    #{{ channel.name }}
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" small v-on="on">$info</v-icon>
                      </template>
                      <span
                        >Receive notifications for {{ channel.name }} feed</span
                      >
                    </v-tooltip>
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-list>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'UserNotifications',
  props: {
    user: {
      type: Object,
      required: true,
    },
    immediateUpdate: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      notifications: {},
      feedChannels: [],
      feedChannelSearch: '',
      notificationSettings: [
        {
          key: 'new_message',
          label: 'New Guest Message',
          roles: ['non-investor'],
          description: 'Get notifications when guests send new messages',
        },
        {
          key: 'email_notification',
          label: 'Email Notifications',
          roles: [],
          description:
            "Get emails to find out what's going on when you're not online. You can turn this off",
        },
        {
          key: 'new_reservation',
          label: 'New Reservation',
          roles: ['investor'],
        },
        {
          key: 'canceled_reservation',
          label: 'Canceled Reservation',
          roles: ['investor'],
        },
        {
          key: 'cs_ai_escalation',
          label: 'Customer Support escalation',
          roles: ['non-investor'],
        },
        {
          key: 'sales_ai_escalation',
          label: 'Sales escalation',
          roles: ['non-investor'],
        },
        {
          key: 'new_payment',
          label: 'New Payment',
          roles: ['investor'],
        },
      ],
    }
  },
  created() {
    this.fetchFeedChannels()
  },
  watch: {
    user: {
      handler(newUserValue) {
        this.notifications = newUserValue
          ? newUserValue.notifications || {}
          : this.currentUser.notifications || {}

        if (
          this.notifications.new_message === true &&
          !this.notifications.new_message_scope
        ) {
          this.notifications.new_message_scope = 'all'
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState('regions', ['regions']),
    ...mapState('zones', ['zones']),
    filteredNotificationSettings() {
      return this.notificationSettings.filter(setting => {
        if (setting.roles.includes('all')) {
          return true
        }
        if (
          setting.roles.includes('non-investor') &&
          this.user.role !== 'investor'
        ) {
          return true
        }
        return setting.roles.includes(this.user.role)
      })
    },
    filteredFeedChannels() {
      if (!this.feedChannelSearch) return this.feedChannels

      const search = this.feedChannelSearch.toLowerCase()
      return this.feedChannels.filter(channel =>
        channel.name.toLowerCase().includes(search)
      )
    },
    regionName() {
      if (!this.user || !this.user.region) return ''
      const region = this.regions.find(r => r.id === +this.user.region)
      return region ? region.name : `Region ${this.user.region}`
    },
    zoneName() {
      if (!this.user || !this.user.zone_id) return ''
      const zone = this.zones.find(z => z.id === this.user.zone_id)
      return zone ? zone.name : `Zone ${this.user.zone_id}`
    },
  },
  methods: {
    updateNotifications(options) {
      this.notifications = { ...this.notifications, ...options }

      // If turning off new_message, no need to keep scope setting
      if ('new_message' in options && options.new_message === false) {
        this.notifications.new_message_scope = null
        options.new_message_scope = null
      }

      // If enabling new_message, set default scope
      if ('new_message' in options && options.new_message === true) {
        if (!this.notifications.new_message_scope) {
          this.notifications.new_message_scope = 'all'
          options.new_message_scope = 'all'
        }
      }

      if (this.immediateUpdate) {
        this.saveNotifications(options)
      } else {
        this.$emit('update:notifications', { ...this.notifications })
      }
    },
    saveNotifications(options = {}) {
      this.$store.dispatch('users/updateProfile', {
        id: this.user ? this.user.id : this.currentUser.id,
        notifications: { ...this.notifications, ...options },
      })
    },
    async fetchFeedChannels() {
      try {
        const response = await this.$store.dispatch('feeds/getFeedChannelNames')
        this.feedChannels = response.data.channels
      } catch (error) {
        console.error('Error fetching feed channels:', error)
      }
    },
  },
}
</script>

<style scoped></style>
