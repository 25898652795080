import round from 'lodash/round'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

const METER_PER_MILE = 0.000621371192

export default {
  data() {
    return {
      availabilityTextMap: {
        'Check-in and Checkout today': 'Check-in/out',
        'Check-in today': 'Check-in',
        'Checkout today': 'Check-out',
        'Free today': 'Free',
        'Blocked today': 'Blocked',
      },
    }
  },
  computed: {
    currencySign() {
      const locale = get('config.locale', window) || 'US'
      const currency = new Intl.NumberFormat(`en-${locale}`, {
        style: 'currency',
        currency: get('config.currency', window) || 'USD',
        maximumFractionDigits: 0,
      })
      return currency.formatToParts(0).find(c => c.type === 'currency')?.value
    },
  },
  methods: {
    toMoney(amount, config = {}) {
      if (!amount && amount !== 0) return null
      const fixedAmount = amount > -0.001 && amount < 0.001 ? 0 : amount
      return new Intl.NumberFormat(`en`, {
        style: 'currency',
        currency: config.currency || get('config.currency', window) || 'USD',
        maximumFractionDigits: Number.isInteger(config.decimals)
          ? config.decimals
          : 2,
      }).format(fixedAmount)
    },
    parsePlaceAttribute(attribute, place) {
      const addressComponent =
        place.address_components.find(
          component => component.types[0] === attribute
        ) || {}
      if (isEmpty(addressComponent) || isNil(addressComponent.long_name)) {
        return ''
      }

      return addressComponent.long_name
    },
    parsePlaceShortAttribute(attribute, place) {
      const addressComponent =
        place.address_components.find(
          component => component.types[0] === attribute
        ) || {}
      if (isEmpty(addressComponent) || isNil(addressComponent.long_name)) {
        return ''
      }

      return addressComponent.short_name
    },
    toAmount(amount, maximumFractionDigits = 2) {
      if (!amount && amount !== 0) return null
      const fixedAmount = amount > -1 && amount < 1 ? 0 : amount
      return new Intl.NumberFormat(`en`, {
        maximumFractionDigits,
      }).format(fixedAmount)
    },
    sourceName(name = '') {
      if (name?.toLowerCase() === 'homeaway') {
        return 'VRBO'
      }
      return name
    },
    toPercent(num, decimal = 0) {
      return num && `${num ? (+num).toFixed(decimal) : '0'}%`
    },
    parseDate: function (date, format = 'YYYY-MM-DD', config = {}) {
      if (date) {
        const parsedDate = config.local
          ? this.$moment(date)
          : this.$moment.utc(date)
        if (parsedDate) {
          return parsedDate.format(format)
        }
      } else {
        return ''
      }
    },
    parseTimestamp: function (date, format = 'YYYY-MM-DD', config = {}) {
      if (date) {
        const parsedDate = config.local
          ? this.$moment.unix(date)
          : this.$moment.unix(date).utc()
        if (parsedDate) {
          return parsedDate.format(format)
        }
      } else {
        return ''
      }
    },
    fromNow(time) {
      return this.$moment(time).fromNow()
    },
    dollarFormatter(amount, maximumFractionDigits = 2) {
      if (!amount && amount !== 0) return null
      const fixedAmount = amount > -1 && amount < 1 ? 0 : amount
      const locale = get('config.locale', window) || 'US'
      return new Intl.NumberFormat(`en-${locale}`, {
        style: 'currency',
        currency: get('config.currency', window) || 'USD',
        maximumFractionDigits,
      }).format(fixedAmount)
    },
    kFormatter(num, decimals = 2) {
      return Math.abs(num) > 999 && this.$vuetify.breakpoint.xs
        ? this.toMoney(Math.sign(num) * (Math.abs(num) / 1000), { decimals }) +
            'k'
        : this.toMoney(Math.sign(num) * Math.abs(num), { decimals })
    },
    metersToMiles(meters, decimals = 1) {
      return round(meters * METER_PER_MILE, decimals)
    },
    round(val, decimals = 0) {
      return val ? round(val, decimals) : val
    },
  },
}
