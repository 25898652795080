<template>
  <div class="listing-picker-new ma-0 ma-sm-2">
    <AssigneeSelect
      v-if="managementRoles || selectedPerson"
      v-model="selectedPerson"
      :disabled="managementRoles ? false : !currentUser.clocked_in"
      :users="assigneeItems"
      @change="assigneeChange"
    />
    <v-icon
      v-if="assignedToMe && !managementRoles"
      small
      @click="assigneeChange(null)"
      >$trash</v-icon
    >
    <v-icon
      v-if="!selectedPerson && currentUser"
      small
      :disabled="currentUser && !currentUser.clocked_in"
      @click="assignToSelf"
      >person_add</v-icon
    >
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import AssigneeSelect from 'components/common/assignee-select'
import { mapGetters } from 'vuex'

export default {
  components: { AssigneeSelect },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['preSelected', 'conversation', 'afterChange'],
  methods: {
    assignToSelf() {
      this.assigneeChange({ id: this.currentUserId })
    },
    async assigneeChange(user) {
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        assigned_user_id: user ? user.id : null,
      })
      this.afterChange()
    },
  },
  computed: {
    ...mapGetters(['currentUserId', 'currentUser']),
    ...mapGetters('users', ['activeUsers']),
    assignedToMe() {
      return (
        this.selectedPerson && this.currentUserId === this.selectedPerson.id
      )
    },
    assigneeItems() {
      if (this.activeUsers.map(user => user.id).includes(this.currentUserId)) {
        return this.activeUsers
      } else {
        return this.activeUsers.concat([this.currentUser])
      }
    },
  },
  watch: {
    preSelected() {
      this.selectedPerson = this.preSelected
    },
  },
  data() {
    return {
      selectedPerson: this.preSelected,
    }
  },
}
</script>
