<template>
  <div class="formula-builder mx-2">
    <!-- Main input section -->
    <div class="formula-input-container">
      <div class="d-flex align-center">
        <v-text-field
          ref="formulaInput"
          v-model="formulaValue"
          :error-messages="errorMessages"
          :label="label"
          :disabled="disabled || !editable"
          outlined
          :hint="hint"
          :rules="rules"
          :persistent-hint="!!hint"
          dense
          @input="handleInput"
          @keydown.tab.prevent="handleTabCompletion"
          @keydown.down.prevent="navigateSuggestions('down')"
          @keydown.up.prevent="navigateSuggestions('up')"
          @keydown.enter.prevent="handleEnterKey"
        >
          <template #append>
            <v-icon :color="isValid ? 'success' : 'error'" small>
              {{ isValid ? 'mdi-check-circle' : 'mdi-alert-circle' }}
            </v-icon>
          </template>
        </v-text-field>
      </div>

      <!-- Variables section with compact toggle - only shown in edit mode -->
      <div v-if="editable" class="variables-section">
        <!-- Header with operators and toggle -->
        <div class="d-flex align-center">
          <div class="operators-container flex-grow-1">
            <v-btn
              v-for="operator in operators"
              :key="operator.symbol"
              x-small
              text
              class="operator-btn"
              :title="operator.description"
              @click="insertOperator(operator.symbol)"
            >
              {{ operator.symbol }}
            </v-btn>
          </div>

          <v-btn
            x-small
            text
            class="toggle-variables-btn ml-2"
            :title="showVariables ? 'Hide Variables' : 'Show Variables'"
            @click="showVariables = !showVariables"
          >
            <v-icon x-small left>
              {{ showVariables ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
            <span class="text-caption mr-1">Variables</span>
          </v-btn>
        </div>

        <!-- Expandable variables content -->
        <v-expand-transition>
          <div v-show="showVariables" class="variables-container mt-2">
            <div
              v-for="(vars, category) in groupedVariables"
              :key="category"
              class="mb-2"
            >
              <div class="text-caption grey--text mb-1">{{ category }}</div>
              <div class="d-flex flex-wrap gap-1">
                <v-chip
                  v-for="variable in vars"
                  :key="variable.value"
                  x-small
                  class="mr-1 mb-1"
                  :title="variable.description"
                  @click="insertVariable(variable)"
                >
                  {{ variable.value }}
                </v-chip>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>

      <!-- Suggestions dropdown - only shown in edit mode -->
      <div
        v-if="editable && showSuggestions && suggestions.length"
        class="suggestions-dropdown"
      >
        <div
          v-for="(suggestion, index) in suggestions"
          :key="suggestion.value"
          class="suggestion-item"
          :class="{
            active: selectedSuggestionIndex === index,
          }"
          @click="insertSuggestion(suggestion)"
          @mouseover="selectedSuggestionIndex = index"
        >
          <div class="d-flex flex-column">
            <span class="suggestion-value">{{ suggestion.value }}</span>
            <span class="suggestion-description text-caption">
              {{ suggestion.description }}
            </span>
          </div>
          <span class="suggestion-category">{{ suggestion.category }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnhancedFormulaBuilder',
  props: {
    value: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Formula',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    variables: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formulaValue: this.value,
      cursorPosition: 0,
      showSuggestions: false,
      selectedSuggestionIndex: -1,
      errorMessages: [],
      showVariables: false,
      operators: [
        { symbol: '+', description: 'Addition' },
        { symbol: '-', description: 'Subtraction' },
        { symbol: '*', description: 'Multiplication' },
        { symbol: '/', description: 'Division' },
        { symbol: '(', description: 'Open Parenthesis' },
        { symbol: ')', description: 'Close Parenthesis' },
      ],
    }
  },

  computed: {
    suggestions() {
      if (!this.showSuggestions) return []

      const cursorText = this.getTextBeforeCursor()
      const searchTerm = cursorText.split(/[\s+\-*/()]+/).pop()

      if (!searchTerm) return []

      return this.variables
        .filter(variable =>
          variable.value.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(0, 5)
    },

    groupedVariables() {
      return this.variables.reduce((acc, variable) => {
        const category = variable.category || 'Other'
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(variable)
        return acc
      }, {})
    },

    totalVariables() {
      return this.variables.length
    },

    isValid() {
      return this.validateFormula()
    },
  },

  watch: {
    value(newValue) {
      if (newValue !== this.formulaValue) {
        this.formulaValue = newValue
      }
    },

    formulaValue(newValue) {
      this.$emit('input', newValue)
    },
  },

  methods: {
    handleInput(value) {
      this.cursorPosition =
        this.$refs.formulaInput.$el.querySelector('input').selectionStart
      this.showSuggestions = true
      this.selectedSuggestionIndex = 0
      this.$emit('change')
    },

    handleTabCompletion(event) {
      if (this.suggestions.length) {
        const suggestion =
          this.suggestions[
            this.selectedSuggestionIndex === -1
              ? 0
              : this.selectedSuggestionIndex
          ]
        this.insertSuggestion(suggestion)
        event.preventDefault()
      }
    },

    getTextBeforeCursor() {
      return this.formulaValue.substring(0, this.cursorPosition)
    },

    insertSuggestion(suggestion) {
      const beforeCursor = this.getTextBeforeCursor()
      const afterCursor = this.formulaValue.substring(this.cursorPosition)
      const lastWord = beforeCursor.split(/[\s+\-*/()]+/).pop()

      this.formulaValue =
        beforeCursor.substring(0, beforeCursor.length - lastWord.length) +
        suggestion.value +
        afterCursor

      this.showSuggestions = false
      this.$nextTick(() => {
        this.$refs.formulaInput.focus()
      })
    },

    insertOperator(operator) {
      const input = this.$refs.formulaInput.$el.querySelector('input')
      const position = input.selectionStart
      const needsSpaces = operator !== '(' && operator !== ')'

      this.formulaValue =
        (this.formulaValue || '').slice(0, position) +
        (needsSpaces ? ` ${operator} ` : operator) +
        (this.formulaValue || '').slice(position)

      this.$nextTick(() => {
        input.focus()
        input.setSelectionRange(
          position + (needsSpaces ? 3 : 1),
          position + (needsSpaces ? 3 : 1)
        )
      })
    },

    insertVariable(variable) {
      const input = this.$refs.formulaInput.$el.querySelector('input')
      const position = input.selectionStart

      this.formulaValue =
        (this.formulaValue || '').slice(0, position) +
        ` ${variable.value} ` +
        (this.formulaValue || '').slice(position)

      this.$nextTick(() => {
        input.focus()
        input.setSelectionRange(
          position + variable.value.length + 2,
          position + variable.value.length + 2
        )
      })
    },

    validateFormula() {
      this.errorMessages = []
      if (!this.formulaValue) return true

      // Check for consecutive operators
      if (/[+\-*/]{2,}/.test(this.formulaValue)) {
        this.errorMessages.push('Consecutive operators are not allowed')
      }

      // Check for balanced parentheses
      const openParens = (this.formulaValue.match(/\(/g) || []).length
      const closeParens = (this.formulaValue.match(/\)/g) || []).length
      if (openParens !== closeParens) {
        this.errorMessages.push('Unbalanced parentheses')
      }

      // Check for valid characters
      const validPattern = /^[a-zA-Z0-9_\s+\-*/()\\.]+$/
      if (!validPattern.test(this.formulaValue)) {
        this.errorMessages.push('Formula contains invalid characters')
      }

      // Check for valid operator usage
      if (/[+\-*/]\s*$/.test(this.formulaValue)) {
        this.errorMessages.push('Formula cannot end with an operator')
      }

      return this.errorMessages.length === 0
    },

    navigateSuggestions(direction) {
      if (!this.showSuggestions || !this.suggestions.length) return

      if (direction === 'down') {
        if (this.selectedSuggestionIndex < this.suggestions.length - 1) {
          this.selectedSuggestionIndex++
        } else {
          this.selectedSuggestionIndex = 0
        }
      } else if (direction === 'up') {
        if (this.selectedSuggestionIndex > 0) {
          this.selectedSuggestionIndex--
        } else {
          this.selectedSuggestionIndex = this.suggestions.length - 1
        }
      }

      this.$nextTick(() => {
        const activeElement = document.querySelector('.suggestion-item.active')
        if (activeElement) {
          activeElement.scrollIntoView({ block: 'nearest' })
        }
      })
    },

    handleEnterKey() {
      if (this.showSuggestions && this.suggestions.length) {
        const selectedSuggestion =
          this.suggestions[this.selectedSuggestionIndex]
        if (selectedSuggestion) {
          this.insertSuggestion(selectedSuggestion)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.formula-builder {
  .formula-input-container {
    position: relative;
  }

  .variables-section {
    .operators-container {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
    }

    .toggle-variables-btn {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      height: 24px;
      transition: all 0.2s ease;

      &:hover {
        background-color: var(--v-primary-lighten5) !important;
        color: var(--v-primary-base);
      }

      .v-chip {
        margin-top: -1px;
      }
    }
  }

  .operator-btn {
    min-width: 32px;
    height: 24px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &:hover {
      background: var(--v-primary-lighten5);
      color: var(--v-primary-base);
    }
  }

  .variables-container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px;
    background-color: #fafafa;

    .gap-1 {
      gap: 4px;
    }

    .v-chip {
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: var(--v-primary-lighten5) !important;
        color: var(--v-primary-base);
      }
    }
  }

  .suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .suggestion-item {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:hover,
    &.active {
      background: var(--v-primary-lighten5);
      color: var(--v-primary-base);
    }

    .suggestion-description {
      color: #666;
    }

    .suggestion-category {
      color: #666;
      font-size: 0.85em;
      margin-left: 16px;
    }
  }
}
</style>
