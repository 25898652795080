<template>
  <v-container
    class="sanjer-container"
    :class="{ 'sanjer-expanded': isExpanded }"
  >
    <transition name="fade">
      <v-card v-if="isExpanded" class="sanjer-chat-card" outlined elevation="5">
        <v-card-title
          class="d-flex justify-space-between align-center futuristic-header"
        >
          <div>Sanjer Assistant</div>
          <div>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="startNewChat"
                >
                  <v-icon color="white">mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Start New Chat</span>
            </v-tooltip>
            <v-btn icon small @click="toggleExpand">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="pa-0">
          <div ref="messagesContainer" class="sanjer-messages">
            <div
              v-for="(message, i) in messages"
              :key="i"
              class="message-item pa-2 ma-2"
              :class="
                message.by === 'contractor' ? 'message-user' : 'message-ai'
              "
            >
              <div class="message-content">{{ message.body }}</div>
              <div class="message-time text-caption">
                {{ formatTime(message.sent_at) }}
              </div>
            </div>

            <div
              v-if="isWaitingForResponse"
              class="message-item pa-2 ma-2 typing-indicator-container"
            >
              <div class="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <div v-if="!messages.length" class="pa-4">
              <p class="text-center text--primary font-weight-medium">
                Hi I'm Sanjer, how can I help you today?
              </p>
              <div class="suggestion-items mt-4">
                <span
                  v-for="(suggestion, i) in suggestions"
                  :key="i"
                  text
                  small
                  class="suggestion-btn mb-2"
                  @click="newMessage = suggestion"
                >
                  {{ suggestion }}
                </span>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pa-2 futuristic-actions">
          <v-textarea
            v-model="newMessage"
            placeholder="Type your message..."
            hide-details
            dense
            outlined
            append-icon="mdi-send"
            class="futuristic-input"
            rows="2"
            @click:append="sendMessage(newMessage)"
            @keyup.enter="sendMessage(newMessage)"
          ></v-textarea>
        </v-card-actions>
      </v-card>
    </transition>

    <v-btn
      v-if="!isExpanded"
      fab
      large
      color="primary"
      class="sanjer-bubble-btn"
      @click="toggleExpand"
    >
      <v-badge
        :content="unreadCount"
        :value="unreadCount > 0"
        color="error"
        offset-x="14"
        offset-y="14"
      >
        <v-icon>mdi-chat</v-icon>
      </v-badge>
    </v-btn>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SanjerBubble',

  data() {
    return {
      isExpanded: false,
      newMessage: '',
      messages: [],
      unreadCount: 0,
      isWaitingForResponse: false,
      suggestions: [
        `Block the calendar for a listing tomorrow`,
        `Unblock the calendar for a listing between ${this.getDateRange()}`,
        `Increase the price for ${this.getListingNickname()} by 5% next wed to fri`,
        `Decrease the price for ${this.getListingNickname()} by $10 between ${this.getDateRange()}`,
      ],
    }
  },

  mounted() {
    this.fetchMessages()
  },

  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        this.unreadCount = 0
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },

    scrollToBottom() {
      if (this.$refs.messagesContainer) {
        this.$refs.messagesContainer.scrollTop =
          this.$refs.messagesContainer.scrollHeight
      }
    },

    formatTime(timestamp) {
      if (!timestamp) return ''
      const date = new Date(timestamp)
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },

    async sendMessage(text) {
      if (!text || text.trim() === '') return

      // Add user message to the chat
      const userMessage = {
        body: text,
        by: 'contractor',
        sent_at: new Date().toISOString(),
      }
      this.messages.push(userMessage)

      this.newMessage = '' // Clear input
      this.isWaitingForResponse = true // Show loading indicator

      this.$nextTick(() => {
        this.scrollToBottom()
      })

      try {
        // Send message to the server
        const response = await axios.post('/api/assistant/message', {
          message: text,
        })

        if (response.data && response.data.success) {
          // Start polling for the AI response
          this.pollForAiResponse(response.data.message_id)
        }
      } catch (error) {
        console.error('Error sending message:', error)
        // Add error message to the chat
        this.messages.push({
          body: 'Sorry, there was an error sending your message. Please try again.',
          by: 'ai_boom_agent',
          sent_at: new Date().toISOString(),
        })
        this.isWaitingForResponse = false // Hide loading indicator
        this.scrollToBottom()
      }
    },

    // Poll for AI response
    pollForAiResponse(messageId, attempts = 0) {
      // Maximum 10 attempts (10 seconds)
      if (attempts > 10) {
        this.messages.push({
          body: 'The assistant is taking longer than expected to respond. Your request is still being processed.',
          by: 'ai_boom_agent',
          sent_at: new Date().toISOString(),
        })
        this.isWaitingForResponse = false // Hide loading indicator
        this.scrollToBottom()
        return
      }

      setTimeout(async () => {
        try {
          const response = await axios.get('/api/assistant/messages')

          if (response.data && response.data.messages) {
            // Check if there's an AI response after our message
            const userMessageIndex = response.data.messages.findIndex(
              m => m.id === messageId
            )
            const hasAiResponse = response.data.messages.some(
              (m, index) => index > userMessageIndex && m.by === 'ai_boom_agent'
            )

            if (hasAiResponse) {
              // AI has responded, update messages
              this.messages = response.data.messages
              this.isWaitingForResponse = false
              this.$nextTick(() => {
                this.scrollToBottom()
              })
            } else {
              // No AI response yet, continue polling
              this.pollForAiResponse(messageId, attempts + 1)
            }
          }
        } catch (error) {
          console.error('Error polling for messages:', error)
          this.isWaitingForResponse = false
          this.fetchMessages() // Fallback to regular fetch
        }
      }, 1000) // Poll every second
    },

    async fetchMessages() {
      try {
        const response = await axios.get('/api/assistant/messages')

        if (response.data && response.data.messages) {
          this.messages = response.data.messages
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        }
      } catch (error) {
        console.error('Error fetching messages:', error)
      }
    },

    async startNewChat() {
      this.isWaitingForResponse = true // Show loading indicator

      try {
        // Call the new-chat endpoint
        const response = await axios.post('/api/assistant/new-chat')

        if (response.data && response.data.success) {
          // Clear the current messages
          this.messages = []

          // Add the welcome message from the response
          if (response.data.welcome_message) {
            this.messages.push(response.data.welcome_message)
          }

          // Update the UI
          this.isWaitingForResponse = false
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        }
      } catch (error) {
        console.error('Error starting new chat:', error)
        this.messages.push({
          body: 'Sorry, there was an error starting a new chat. Please try again.',
          by: 'ai_boom_agent',
          sent_at: new Date().toISOString(),
        })
        this.isWaitingForResponse = false
        this.scrollToBottom()
      }
    },

    getListingNickname() {
      // This would be replaced with actual logic to get the latest listing
      return this.$store.getters.listings &&
        this.$store.getters.listings.length > 0
        ? this.$store.getters.listings[0].nickname
        : 'Your Property'
    },

    getDateRange() {
      const today = new Date()
      const dayAfterTomorrow = new Date()
      dayAfterTomorrow.setDate(today.getDate() + 2)

      const formatDate = date => {
        return date.toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
        })
      }

      return `${formatDate(today)} - ${formatDate(dayAfterTomorrow)}`
    },
  },
}
</script>

<style lang="scss" scoped>
.sanjer-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  max-width: 400px;
  padding: 0;
}

.futuristic-header {
  background-image: linear-gradient(to right, #5b6df8, #a494ff);
  color: white;
  padding: 10px 16px;
  border-bottom: 1px solid rgba(138, 156, 213, 0.2);
}

.futuristic-actions {
  background-color: #f0f1ff;
  border-top: 1px solid rgba(138, 156, 213, 0.15);
  padding: 12px !important;
}

.futuristic-input {
  ::v-deep .v-input__slot {
    background-color: #ffffff !important;
    border: 1px solid rgba(138, 124, 251, 0.3) !important;
    transition: all 0.2s ease;

    &:hover,
    &:focus-within {
      border-color: rgba(138, 124, 251, 0.6) !important;
      box-shadow: 0 0 0 1px rgba(138, 124, 251, 0.2);
    }
  }

  ::v-deep .v-label {
    color: rgba(41, 37, 92, 0.7) !important;
  }

  ::v-deep .v-icon {
    color: #8a7cfb !important;
  }
}

.sanjer-chat-card {
  width: 350px;
  height: 475px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(138, 156, 213, 0.2),
    0 0 10px rgba(138, 156, 213, 0.1);
  border: 1px solid rgba(138, 156, 213, 0.1);
}

.sanjer-messages {
  height: 330px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #f7f8ff;
  padding: 12px;
  scrollbar-width: thin;
  scrollbar-color: rgba(138, 156, 213, 0.5) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(138, 156, 213, 0.5);
    border-radius: 6px;
  }
}

.message-item {
  max-width: 80%;
  position: relative;
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 10px 14px;
  box-shadow: 0 2px 8px rgba(138, 156, 213, 0.15);
}

// User message (right side)
.message-user {
  align-self: flex-end;
  background-color: #d8e0ff;
  background-image: linear-gradient(135deg, #d8e0ff, #c7d1ff);
  border-radius: 12px;
  margin-right: 8px;
  border-bottom-right-radius: 2px;
  color: #292562;
  border-right: 2px solid #5b6df8;
}

// AI message (left side)
.message-ai {
  align-self: flex-start;
  background-color: #eaeaff;
  background-image: linear-gradient(135deg, #eaeaff, #e4e0ff);
  border-radius: 12px;
  margin-left: 8px;
  border-bottom-left-radius: 2px;
  border-left: 2px solid #a494ff;
  box-shadow: 0 2px 10px rgba(138, 124, 251, 0.1);
  color: #292562;
}

.message-content {
  word-break: break-word;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
}

.message-time {
  display: inline-block;
  margin-left: 4px;
  text-align: right;
  opacity: 0.7;
  font-size: 10px;
  float: right;
  margin-top: 2px;
  color: #6e6a9e;
}

.sanjer-bubble-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(135deg, #6366f1, #8b5cf6) !important;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.4) !important;
  border: none !important;
}

.suggestion-items {
  display: flex;
  flex-direction: column;
}

.suggestion-btn {
  text-align: left;
  text-transform: none;
  white-space: normal;
  height: auto;
  border-radius: 12px;
  padding: 12px 16px;
  cursor: pointer;
  font-weight: 400;
  background-color: #ffffff;
  color: #292562;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(138, 156, 213, 0.2);
  border-left: 3px solid #a494ff;

  &:hover {
    background-color: #f0f1ff;
    transform: translateY(-2px);
    box-shadow: 0 3px 8px rgba(138, 124, 251, 0.2),
      0 0 0 1px rgba(138, 124, 251, 0.1);
  }
}

/* Three dots typing animation */
.typing-indicator-container {
  padding: 15px 14px 5px !important;
  min-width: 70px;
  align-self: flex-start;
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    height: 8px;
    width: 8px;
    margin: 0 1px;
    background-color: #a494ff;
    display: block;
    border-radius: 50%;
    opacity: 0.4;

    &:nth-of-type(1) {
      animation: bounce 1s infinite 0.1s;
    }
    &:nth-of-type(2) {
      animation: bounce 1s infinite 0.2s;
    }
    &:nth-of-type(3) {
      animation: bounce 1s infinite 0.3s;
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0.4;
  }
  50% {
    transform: translateY(-4px);
    opacity: 1;
  }
}

/* Transition effects */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
