<template>
  <v-autocomplete
    id="property-picker"
    :label="$t(label || 'Current listing')"
    :value.sync="value"
    :item-disabled="item => item.isDivider"
    :items="listings"
    :disabled="disabled"
    :placeholder="placeHolder"
    autocomplete="off"
    :search-input.sync="search"
    no-filter
    item-text="nickname"
    item-value="pickerId"
    rtl
    :hide-details="hideDetails !== false"
    :outlined="outlined !== false"
    :dense="dense !== false"
    :clearable="clearable === true"
    :multiple="allowMultiple"
    return-object
    @change="onListingChange"
  >
    <template #selection="{ item }">
      <v-chip class="pl-2" small>
        <v-avatar v-if="!hideListingPics" left>
          <v-img :src="item.image || item.picture"></v-img>
        </v-avatar>
        {{ item.stage === 'setup' ? 'Setup - ' : ''
        }}{{ truncateBy(item.nickname, isMobile ? '15' : '30') }}
      </v-chip>
      <v-chip
        v-if="item.is_multi_unit"
        label
        class="mx-2"
        x-small
        color="cyan lighten-4"
        >{{ $t('Multi') }}
      </v-chip>
      <v-chip
        v-if="item.listing_type === 'hotel'"
        label
        class="mx-2"
        x-small
        color="purple lighten-4"
        >{{ $t('Complex') }}
      </v-chip>
    </template>
    <template #item="{ item }" class="listing-picker">
      <div
        v-if="item.isDivider"
        :class="`w-100 text-center font-weight-medium mb-4 py-1 text-body-2 white--text ${item.color} darken-1`"
      >
        <div class="">{{ item.label }}</div>
      </div>
      <div
        v-else
        :class="`d-flex align-center mb-3 px-3 w-100 ${
          item.lightText ? 'grey--text' : ''
        } `"
      >
        <v-avatar v-if="!hideListingPics" tile rounded>
          <img
            class="rounded"
            :src="item.image || item.picture || placeholderImg"
          />
        </v-avatar>
        <div class="pl-4">
          <div class="d-flex align-center">
            <div class="font-weight-medium">
              {{ item.nickname }}
            </div>
            <v-chip
              v-if="item.listed"
              label
              class="mx-2"
              x-small
              color="green lighten-3"
              >{{ $t('Listed') }}
            </v-chip>
            <v-chip
              v-if="!item.listed"
              label
              class="mx-2"
              x-small
              color="red lighten-3"
              >{{ $t('UnListed') }}
            </v-chip>
            <v-chip
              v-if="!item.active"
              label
              class="mx-2"
              x-small
              color="grey lighten-2"
              >{{ $t('Disabled') }}
            </v-chip>
            <v-chip
              v-if="item.second_guesty_id"
              label
              class="mx-2"
              x-small
              color="blue lighten-4"
              >{{ $t('Dup') }}
            </v-chip>
            <v-chip
              v-if="item.is_multi_unit"
              label
              class="mx-2"
              x-small
              color="cyan lighten-4"
              >{{ $t('Multi') }}
            </v-chip>
            <v-chip
              v-if="item.listing_type === 'hotel'"
              label
              class="mx-2"
              x-small
              color="purple lighten-4"
              >{{ $t('Complex') }}
            </v-chip>
            <v-chip
              v-if="item.stage === 'setup'"
              label
              class="mx-2"
              x-small
              color="orange lighten-4"
              >{{ $t('Setup') }}
            </v-chip>
          </div>
          <div class="text-caption">{{ item.title }}</div>
          <div class="text-caption">
            <span>{{ item.beds }}</span>
            <v-icon class="mx-1" small>$bedroom</v-icon>
            <span class="text-subtitle-1">·</span>
            <!--            <v-icon>mdi-circle-small</v-icon>-->
            <span class="mx-1">{{ item.baths }}</span>
            <v-icon small>$bath</v-icon>
          </div>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from './mixins/device-mixin'
import placeholderImg from '@/assets/images/home.png'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: [
    'afterChange',
    'value',
    'outlined',
    'hideDetails',
    'dense',
    'clearable',
    'label',
    'prependListings',
    'allowMultiple',
    'showDups',
    'disabled',
    'hideListingPics',
  ],
  data() {
    return {
      search: null,
      placeholderImg,
    }
  },
  computed: {
    placeHolder() {
      return this.$route.fullPath.includes('/property/') &&
        this.$store.state.currentListing
        ? this.$store.state.currentListing.nickname
        : 'Choose a listing'
    },
    listings() {
      let allListings = [
        ...(this.prependListings || []),
        ...this.$store.state.listingsIndex,
      ]
      allListings = allListings.filter(
        listing =>
          !this.search ||
          listing.nickname?.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1 ||
          listing.title?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      )

      const results = {
        main: [],
        multiUnits: [],
        units: [],
        dups: [],
        setup: [],
        disabled: [],
      }

      allListings.forEach(listing => {
        listing.pickerId = listing.id
        if (!listing.active)
          results.disabled.push({ ...listing, lightText: true })
        else if (listing.is_multi_unit) results.multiUnits.push(listing)
        else if (listing.container_multi_id) results.units.push(listing)
        else if (listing.second_guesty_id)
          results.dups.push({ ...listing, lightText: true })
        else if (listing.stage === 'setup')
          results.setup.push({ ...listing, lightText: true })
        else results.main.push(listing)
      })

      return [
        ...results.main,
        results.multiUnits.length
          ? { isDivider: true, label: 'Multi units', color: 'green' }
          : null,
        ...results.multiUnits,
        results.units.length
          ? { isDivider: true, label: 'Units', color: 'teal' }
          : null,
        ...results.units,
        results.dups.length
          ? { isDivider: true, label: 'Duplicates', color: 'amber' }
          : null,
        ...results.dups,
        results.setup.length
          ? { isDivider: true, label: 'Setups', color: 'indigo' }
          : null,
        ...results.setup,
        results.disabled.length
          ? { isDivider: true, label: 'Disabled', color: 'blue-grey' }
          : null,
        ...results.disabled,
      ].filter(Boolean)
    },
  },
  methods: {
    searchFilter(item) {
      return (
        item.nickname?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
        item.title?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      )
    },
    onListingChange(ids) {
      if (!Array.isArray(ids) && ids?.id && !this.afterChange) {
        this.$store.commit('updateLoading', true)
        if (this.isInvestor) {
          this.$router.push(`/dashboard/property/${ids.id}`)
        } else {
          this.$router.push(`/dashboard/edit/${ids.id}`)
        }
      } else if (this.afterChange) {
        if (Array.isArray(ids)) {
          this.afterChange(ids.map(id => (isNaN(id) ? id.id : id)))
        } else {
          this.afterChange(ids?.id)
        }
      }
    },
  },
}
</script>
<style scoped>
:deep() .v-list-item {
  padding: 0;
}
:deep() .v-list-item--disabled {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>

<style>
.v-menu__content--fixed {
  border-radius: 5px !important;
}
</style>
